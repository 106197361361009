import { tr } from 'react-hook-form-auto'

import { Icon } from '../bootstrap/bootstrap'

import {
  getInfoFromIngredient,
  getInfoFromMeal
} from '../../views/ingredients/InfoUtils'
// import { Ingredients } from '../../api/ingredients'

const ProductLine = ({
  label,
  displayText
}) =>
  <li>
    <span className="ingredient-label">{label}</span>
    <span className="ingredient-value">{displayText}</span>
  </li>

const renderNutrimentsData = (data) =>
  data.map(datum =>
    <ProductLine
      key={datum.label}
      {...datum}
    />
  )

// const renderWarnList = (ids) => {
//   const ingredients = Ingredients.find({
//     id: { $in: ids }
//   }).fetch()
//   const names = ingredients.map(ing => ing.name)
//   const list = names.join(', ')

//   return tr('nutrition.warns.ingredients', { ingredients: list })
// }

const renderWarns = (warns = {}) => {
  return null
  // const ids = Object.keys(warns)
  // if (ids.length > 0) {
  //   return (
  //     <div className="nutriment-warns">
  //       <div className="nutriment-warn">
  //         <div className="warn-sign">
  //           <Icon icon="exclamation-triangle" className="nutriment-warn-icon" />
  //         </div>
  //         <div className="warn-letters">
  //           {renderWarnList(ids)}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}

const renderList = (list) => {
  if (list && list.length > 0) {
    return (
      <ul className="ingredient-data">
        {renderNutrimentsData(list)}
      </ul>
    )
  }
}

const renderTitle = (list) => {
  if (list && list.length > 0) {
    return (
      <h2>{tr('models.nutriments._field')}</h2>
    )
  }
}

const renderGenericData = (data) =>
  <div className="ingredient-data-wrapper">
    {renderTitle(data.list)}
    {renderWarns(data.warns)}
    {renderList(data.list)}
  </div>

export const IngredientNutriments = ({ ingredient }) => {
  const info = getInfoFromIngredient(ingredient)
  return renderGenericData(info)
}

export const MealNutriments = ({ meal }) => {
  const data = getInfoFromMeal(meal)
  return renderGenericData(data)
}

