import { hydrateRoot } from 'react-dom/client'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { createApolloClient } from './client'

import App from './App'
import { setCookieKey } from './pintor'
import config from './clientConfig'

setCookieKey(config.cookieKey)

const { client } = createApolloClient({
  ssr: false,
  uri: config.graphQlUri,
  wsUri: config.graphQlWsUri,
})

hydrateRoot(
  document.getElementById('root'),
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
)
