import { useQuery } from '@apollo/client'
import { useMutation } from '../../graphql/notificatingMutation'

import { Diet } from '../../models/diet'
import { Buy } from '../../models/buy'

import { DietsView } from './DietsView'

export const DietsContainer = (props) => {
  const { data } = useQuery(Diet.fetch)
  const diets = data && data.diets
  Diet.useDietSub()
  const [ dietUpdate ] = useMutation(Diet.update)
  const [ dietBuyAdd ] = Buy.useBuyAdd()
  Buy.useBuyUpdate()

  const handleUpdate = doc => {
    return dietUpdate({
      variables: { input: doc }
    })
  }

  const handleDietBuyAdd = (diet) => {
    dietBuyAdd({
      variables: {
        input: {
          type: 'diet',
          id: diet.id,
          unit: 'u',
          amount: 1
        }
      }
    })
  }

  // render()
  return (
    <DietsView
      {...props}
      diets={diets}
      onUpdate={handleUpdate}
      onDietBuyAdd={handleDietBuyAdd}
    />
  )
}
