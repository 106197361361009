import React from 'react'
import classnames from 'classnames'
import base64 from 'base64-js'

import { getImageUrl, getImageUrlFromDoc, isImage } from '../../models/image'
import { url, px } from '../../utils/styleHelpers'
import styles from './ImageShow.sass'

const getUrl = (id, doc, version) => {
  if (id)
    return getImageUrl(id, version)
  else {
    const docUrl = getImageUrlFromDoc(doc, version)
    if (docUrl)
      return docUrl
    else if (doc.imagePreview)
      return 'data:image/png;base64,' + base64.fromByteArray(doc.imagePreview)
  }
}

export function mimetypeIsImage(mimetype) {
  if (mimetype) {
    const parts = mimetype.split('/')
    if (parts.length > 1)
      return isImage(parts[1])
    else
      return false
  } else
    return false
}

export function ImageShow({
  file,
  className,
  version = "normal",
  side,
  children,
  ...rest
}) {
  const imgUrl = getImageUrl(file, version)

  // FIXME
  let styleData
  if (imgUrl) {
    styleData = {
      'backgroundImage': url(imgUrl),
      'backgroundPosition': 'center',
      'backgroundSize': `${px(side)} ${px(side)}`
    }
  } else {
    styleData = {}
  }
  if (side) {
    styleData.width = px(side)
    styleData.height = px(side)
    styleData.maxWidth = px(side)
  }

  const classes = classnames(
    styles.container,
    className
  )

  return (
    <div
      className={classes}
      style={styleData}
      {...rest}
    >
      {children}
    </div>
  )
}
