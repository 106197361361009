import { useQuery, useSubscription } from '@apollo/client'
import { createUpdater } from '../../../pintor'

import { DocList } from './DocList'

export const createQuerier = model =>
  (function useCrudQuery(
    {
      search,
      page,
      perPage,
      seeEveryones
    }
  ) {
    const variables = {
      search,
      first: page * perPage,
      num: perPage,
      seeEveryones
    }

    const { data, loading, error } = useQuery(model.search, { variables })
    if (error) {
      console.error(
        'Error receiving initial data for',
        model.name,
        ':',
        error
      )
    }
    const onData = createUpdater({
      query: model.fetch,
      variables,
      subscriptionName: model.mainSubName,
      collectionKey: model.mainSubCollection,
      filter: model.filter
    })
    const { data: sub } = useSubscription(model.sub, {
      variables,
      onData
    })

    const {
      data: countData,
      loading: countLoading
    } = useQuery(model.count, {
      variables: {
        search,
        seeEveryones
      }
    })

    const collection = data && data.collection || []
    const count = countData && countData.count

    return {
      collection, 
      sub,
      count,
      loading: loading || countLoading
    }
  })

export const createComponentedQuerier = model => {
  const useCrudQuery = createQuerier(model)

  return function DocsContainer({
    search,
    page,
    perPage,
    seeEveryones,
    ...rest
  }) {
    const { collection, sub, count, loading } = useCrudQuery({
      search,
      page,
      perPage,
      seeEveryones
    })

    return (
      <DocList
        {...rest}
        model={model}
        collection={collection}
        sub={sub}
        count={count}
        loading={loading}
        search={search}
        page={page}
        perPage={perPage}
      />
    )
  }
}

