import classnames from 'classnames'

import { Icon } from '../../ui/bootstrap/bootstrap'
import { FlexItem } from '../../components/FlexList'
import { renderMatch } from '../../pintor'

import styles from './MealTag.sass'

const icons = {
  meal: 'utensils',
  add: 'plus'
}

export const MealTag = ({
  doc,
  isAdd,
  text,
  children,
  selected,
  onClick,
  search
}) => {
  const type = doc ? 'meal' : 'add'
  const icon = icons[type]
  const id = isAdd ? '__add__' : doc.id
  const displayText = text || doc.name
  const classes = classnames({
    [styles.meal]: !isAdd,
    [styles.add]: isAdd,
    [styles.selected]: selected
  })

  const handleMealClick = () => {
    if (onClick)
      onClick(doc)
  }

  return (
    <FlexItem
      key={id}
      className={classes}
      onClick={handleMealClick}
    >
      <span className={styles.name}>
        <Icon
          icon={icon}
          text={renderMatch(displayText, search, styles)}
          iconClass={styles.icon}
        />
      </span>
      {children}
      { /* this.renderNutrimentsOverlay(doc) */ }
    </FlexItem>
  )
}
