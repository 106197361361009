import { tr } from 'react-hook-form-auto'
import { IngredientSearch } from '../views/ingredients/search/IngredientSearch'
import { ImagesFieldContainer } from '../pintor'
import { MakeItPublicField } from './MakeItPublicField'
import { Select, Icon, DropdownMenu } from '../ui/bootstrap/bootstrap'

// Searcher for nested fields
let Searcher = ({ name, register, ...rest }) => {
  register(`${name}.id`)
  register(`${name}.type`)
  register(`${name}.search`)

  return (
    <IngredientSearch
      name={name}
      placeholder={tr('search')}
      inline
      {...rest}
    />
  )
}

export default {
  search: {
    render: {
      component: Searcher,
      inline: true,
      skipRegister: true
    }
  },
  belongsTo: {
    render: props => {
      const { fieldSchema } = props
      const data = props[fieldSchema.associatedData] || []

      return {
        ...props,
        component: Select,
        options: data.map(record => ({
          label: record[fieldSchema.labelField],
          value: record.id
        })),
        inputComponent: Select
      }
    }
  },
  images: {
    coerce: images => images || [],
    render: props => {
      const { fieldSchema, name } = props

      return {
        ...props,
        component: ImagesFieldContainer,
        onlyOne: fieldSchema.onlyOne,
        editText: tr('shop.files.edit'),
        removeText: tr('shop.files.remove'),
        Icon,
        DropdownMenu
      }
    }
  },
  makePublic: {
    coerce: value => Boolean(value),
    render: props => {
      return {
        ...props,
        component: MakeItPublicField,
        inline: true
      }
    }
  }
}
