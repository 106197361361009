import { useState } from 'react'
import classnames from 'classnames'
import Dropzone from 'react-dropzone'

import { inverseSlice } from '../../utils/utils'
import { useImages, DELETE_FILE } from '../../models/image'
import { IndividualImage } from './IndividualImage'

import { gql } from '@apollo/client'

import { getHooks } from '../../hooks'
import { ensureArray } from '../../utils/utils'

import styles from './ImagesField.sass'

const UPLOAD_FILES = gql`
  mutation Upload($files: [Upload!]!) {
    upload(files: $files) {
      id
      filename
      mimetype
      path
    }
  }
`

// Made a new component for when we actually have the images
export const ImagesFieldController = ({
  defaultValue,
  images,
  onlyOne,
  schemaTypeName,
  name,
  setValue,
  Icon,
  editText,
  removeText,
  onChange,
  onBlur,
  inputRef,
  ...rest
}) => {
  const { useMutation } = getHooks()
  const [ deleteFile ] = useMutation(DELETE_FILE)
  const [ upload ] = useMutation(UPLOAD_FILES)

  const [ uploading, setUploading ] = useState([])
  const [ progress, setProgress ] = useState({})
  const [ editing, setEditing ] = useState(null)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ ownValue, setOwnValue ] = useState(defaultValue)
  const [ curImages, setCurImages ] = useState(images)

  const handleError = err => {
    if (err) {
      setErrorMessage(err.reason)
    }
  }

  const handleUpload = (fileOrFiles, editing) => {
    let newFiles = ownValue

    if (editing) {
      const id = editing.id

      newFiles = handleRemove(id, { skipUpdateField: true })
    }

    const files = ensureArray(fileOrFiles)

    const names = files.map(file => file.name)
    const namesObj = names.reduce((obj, cur) => {
      obj[cur] = 0

      return obj
    }, {})

    const nowUploading = [...uploading, names]

    setUploading(nowUploading)
    setProgress({
      ...progress,
      ...namesObj
    })

    upload({
      variables: { files }
    }).then(({ data }, disaster) => {
      const upload = data && data.upload
      const uploadNullifier = upload.reduce((obj, cur) => {
        obj[cur.filename] = null

        return obj
      }, {})

      const finished = nowUploading.filter(item =>
        uploadNullifier[item] === null
      )

      setUploading(finished)
      setProgress({
        ...progress,
        ...uploadNullifier
      })

      const ids = upload.map(file => file.id)
      const current = newFiles || []
      const added = [ ...current, ...ids ]
      const newImages = [ ...curImages, ...upload ]

      console.log("FINAL field value", added)
      setValue(name, added, false, true)
      setOwnValue(added)
      setCurImages(newImages)
    }).catch((err) => {
      handleError(err)
    })

    // uploadInstance.on('start', (err, fileObj) => {
    //   this.setState({
    //     progress: {
    //       ...this.state.progress,
    //       [file.name]: 0
    //     },
    //     errorMessage: null
    //   })

    //   this.handleError(err)
    // })

    // uploadInstance.on('uploaded', (err, fileObj) => {
    //   this.setState({
    //     uploading: this.state.uploading.filter(item =>
    //       item.name != file.name
    //     ),
    //     progress: {
    //       ...this.state.progress,
    //       [file.name]: null
    //     },
    //   })

    //   // File is not processed yet. I didn't find
    //   // how to delay any signal so I will wait
    //   // for image version to be present. The moment
    //   // I send this, the subscription will refresh.
    //   if (fileObj)
    //     this.props.onChange([ ...this.props.value, fileObj.id ])

    //   this.handleError(err)
    // })

    // uploadInstance.on('error', (err, fileObj) => {
    //   this.handleError(err)
    // })

    // uploadInstance.on('progress', (progress, fileObj) => {
    //   this.setState({
    //     progress: {
    //       ...this.state.progress,
    //       [file.name]: progress
    //     }
    //   })
    // })

    // uploadInstance.start()
  }

  const handleDrops = (editing, accepted, rejected) => {
    const list = onlyOne ? [accepted[0]] : accepted
    handleUpload(list, editing)
  }

  const handleEdit = (file) => {
    setEditing(file)
  }

  const handleRemove = (id, { skipUpdateField } = {}) => {
    deleteFile({
      variables: {
        id
      }
    })

    const removeIdx = ownValue.indexOf(id)
    const removed = inverseSlice(ownValue, removeIdx)

    const imageIdx = curImages.reduce((found, cur, idx) =>
      cur.id == id ? idx : found
    , -1)
    const newImages = inverseSlice(curImages, imageIdx)
    setCurImages(newImages)

    setOwnValue(removed)
    if (!skipUpdateField) {
      setValue(removed)
    }

    return removed
  }

  const renderError = () => {
    if (errorMessage) {
      return (
        <div className={styles.errorMessage}>
          <p>{errorMessage}</p>
        </div>
      )
    }
  }

  const showUpload = (fileName) => {
    const fileProgress = progress[fileName]
    const progressText = `${fileProgress}%`
    const classes = classnames(
      'progress-bar',
      styles.fileProgress
    )

    return (
      <div
        key={fileName}
        className="progress progress-bar-default"
      >
        <div
          style={{ width: fileProgress + '%' }}
          aria-valuemax="100"
          aria-valuemin="0"
          aria-valuenow={fileProgress || 0}
          role="progressbar"
          className={classes}
        >
          <span key="_progress-sr" className="sr-only">
            {progressText}
          </span>
          <span key="_name" className={styles.filename}>{`${fileName} `}</span>
          <span key="_reprogress" className={styles.reprogress}>{progressText}</span>
        </div>
      </div>
    )
  }

  const renderDropzone = (handleDrops, className) => {
    return (
      <Dropzone onDrop={handleDrops}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          const classes = classnames(
            className,
            styles.dropzone,
            {
              [styles.active]: isDragActive
            }
          )

          const {
            ref,
            onChange: dropzoneOnChange,
            ...restInputDropzone
          } = getInputProps()
          const compoundChange = (e) => {
            onChange(e)
            dropzoneOnChange(e)
          }

          return (
            <div
              {...getRootProps()}
              className={classes}
            >
              <input
                {...restInputDropzone}
                name={name}
                ref={ref}
                onChange={compoundChange}
              />
              <Icon
                icon="plus"
                className={styles.icon}
              />
            </div>
          )
        }}
      </Dropzone>
    )
  }

  const renderDefaultDropzone = () => {
    if (!onlyOne || curImages.length == 0) {
      const boundDrop = handleDrops.bind(null, null)
      return renderDropzone(boundDrop, styles.brandNew)
    }
  }

  const showUploads = () => {
    const files = Object.keys(progress)
    const progressing = files.filter(file =>
      progress[file] !== null
    )

    if (progressing.length > 0) {
      return (
        <div>
          {progressing.map(showUpload)}
        </div>
      )
    }
  }

  const renderImageEdit = (file, editing) => {
    if (editing) {
      const boundHandler = handleDrops.bind(null, file)
      return renderDropzone(boundHandler, styles.changing)
    }
  }

  // Render
  const display = curImages.map((file, key) => {
    const isEditing = editing == file
    return (
      <IndividualImage
        key={key}
        file={file}
        version="normal"
        allowEdit
        onEdit={handleEdit}
        onRemove={handleRemove}
        editing={isEditing}
        Icon={Icon}
        editText={editText}
        removeText={removeText}
        {...rest}
      >
        {renderImageEdit(file, editing)}
      </IndividualImage>
    )
  })

  return (
    <div>
      {renderDefaultDropzone()}
      {renderError()}
      {showUploads()}

      <div className={styles.filesEdit}>
        {display}
      </div>
    </div>
  )
}

export function ImagesFieldContainer({
  defaultValue,
  Loading,
  ...rest
}) {
  const { images, loading } = useImages(defaultValue)

  if (loading) {
    if (Loading)
      return <Loading />
    else
      return null
  } else {
    return (
      <ImagesFieldController
        defaultValue={defaultValue}
        images={images}
        {...rest}
      />
    )
  }
}

