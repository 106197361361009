import { useState } from 'react'
import { Link } from 'raviger'
import classnames from 'classnames'

import { mapRoutes, matchRoute } from '../menusTable'
import config from '../clientConfig'
import { Icon } from '../ui/bootstrap/bootstrap'
import { tr } from 'react-hook-form-auto'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import styles from './MainMenu.sass'
import { HeaderLogin } from './HeaderLogin'

const ShopNavItem = props => {
  const {
    active,
    drop,
    textId,
    path,
    icon,
    theme
  } = props

  const dark = theme == 'dark'
  const classes = classnames(
    styles.link,
    'nav-link',
    {
      [styles.dark]: dark,
      [styles.selected]: active
    }
  )
  const $component = drop ? DropdownItem : NavItem
  const text = tr(textId)

  return (
    <$component>
      <Link href={path}>
        <span className={classes}>
          <Icon
            className={styles.innerLink}
            icon={icon}
            iconClass={styles.icon}
            text={text}
            textClass={styles.text}
            noMargin
          />
        </span>
      </Link>
    </$component>
  )
}

export const MainMenu = ({ page, equivalences }) => {
  const [ organizeShow, setOrganizeShow ] = useState(false)

  const handleButtonClick = () => {
    setOrganizeShow(!organizeShow)
  }

  function renderMenus({ theme, menuTable }) {
    return mapRoutes(({ textId, icon, path, submenu }) => {
      const effectivePage = equivalences ? equivalences[page] || page : page
      const active = path == effectivePage

      if (submenu) {
        const classes = classnames(styles.link, {
          [styles.dark]: theme == 'dark'
        })

        return (
          <Dropdown
            key="dropdown"
            nav
            isOpen={organizeShow}
            toggle={handleButtonClick}
            className={classes}
          >
            <DropdownToggle key="toggle" nav caret>
              {tr(textId)}
            </DropdownToggle>
            <DropdownMenu key="menu" className={classes}>
              {renderMenus({ theme, menuTable: submenu })}
            </DropdownMenu>
          </Dropdown>
        )
      } else {
        return (
          <ShopNavItem
            active={active}
            key={path}
            textId={textId}
            icon={icon}
            path={path}
            drop={menuTable}
            theme={theme}
          />
        )
      }
    }, menuTable)
  }

  const routeKey = matchRoute(page, '/shop')

  const theme = config.isProd ? 'light' : 'dark'
  const dark = !config.isProd
  const headerClass = classnames(
    styles.header,
    {
      [styles.dark]: dark
    }
  )

  return (
    <Navbar
      color={theme}
      light={theme == 'light'}
      dark={theme == 'dark'}
    >
      <div className={headerClass}>
        <NavbarBrand href="/shop" className={styles.brand}>
          {config.applicationName}
        </NavbarBrand>
        <Nav>
          {renderMenus({ theme })}
          <li key="login" className={styles.right}>
            <HeaderLogin />
          </li>
        </Nav>
      </div>
    </Navbar>
  )
}
