{
  "applicationName": "Shop p2",
  "crud": {
    "normal": {
      "perPage": 20,
      "side": 170
    },
    "small": {
      "perPage": 32,
      "side": 120
    },
    "list": { "perPage": 40 }
  },
  "shop": {
    "defaultServings": 4,
    "business": false
  }
}
