.root {
  margin-left: 40px;
}
.root .ingredient {
  display: inline;
  border-radius: 2px;
}
.root .ingredient .amount {
  margin-left: 8px;
  padding: 0 4px;
  background-color: #e0e0e0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvbWVhbHMiLCJzb3VyY2VzIjpbIlJlY2lwZUVkaXRvci5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7O0FBQ0E7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLnJvb3RcbiAgbWFyZ2luLWxlZnQ6IDQwcHhcbiAgLmluZ3JlZGllbnRcbiAgICBkaXNwbGF5OiBpbmxpbmVcbiAgICBib3JkZXItcmFkaXVzOiAycHhcbiAgICAuYW1vdW50XG4gICAgICBtYXJnaW4tbGVmdDogOHB4XG4gICAgICBwYWRkaW5nOiAwIDRweFxuICAgICAgYmFja2dyb3VuZC1jb2xvcjogI2UwZTBlMFxuIl19 */