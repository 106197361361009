import classnames from 'classnames'

import { IngredientTag } from './IngredientTag'
import { FlexItem } from '../../components/FlexList'

import styles from './IngredientTag.sass'

export const IngredientTagFlex = ({
  doc,
  isAdd,
  onClick,
  selected,
  ...rest
}) => {
  const id = isAdd ? '__add__' : doc.id

  const classes = classnames(styles.pill, {
    [styles.ingredient]: !isAdd,
    [styles.add]: isAdd,
    [styles.selected]: selected
  })

  return (
    <FlexItem
      key={id}
      className={classes}
      onClick={onClick}
    >
      <IngredientTag
        key={id}
        doc={doc}
        isAdd={isAdd}
        selected={selected}
        {...rest}
      />
    </FlexItem>
  )
}
