import { IngredientAmount } from './IngredientAmount.jsx'

export const renderAmounts = (buy, amountClassName, onChange) => {
  return buy.amounts.map(entry => {
    const { unit } = entry

    return (
      <IngredientAmount
        className={amountClassName}
        entry={entry}
        key={unit}
        onChange={onChange && onChange.bind(null, unit)}
      />
    )
  })
}

export const IngredientAmounts = ({ buy, className, amountClassName, onChange }) =>
  <span className={className}>
    {renderAmounts(buy, amountClassName, onChange)}
  </span>
