import React from 'react'

import { objectReduce } from '../../utils/utils'

export function CrudView({
  model,
  collection,
  loading,
  searching,
  onSearchChange,
  resultsComponent,
  editComponent,
  onDocChange,
  searchFormComponent,
  searchInputProps,
  onPageChange,
  resultsProps,
  children
}) {
  const SearchForm = searchFormComponent
  const Results = resultsComponent

  return (
    <>
      {children}
      <SearchForm
        value={searching}
        onChange={onSearchChange}
        {...searchInputProps}
      />
      <Results
        model={model}
        collection={collection}
        loading={loading}
        resultsProps={resultsProps}
        searching={searching}
      />
    </>
  )
}
