import { Component } from 'react';

import draftJs from 'draft-js'
const {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  convertFromRaw,
  convertToRaw
} = draftJs

import 'draft-js/dist/Draft.css'
import styles from './RecipeEditor.sass'
import './RecipeEditorGlobal.css'

import { IngredientTag } from '../ingredients/IngredientTag.jsx'
import {
  ingredientsByName
} from '../../models/utils'
import { IngredientAmount } from '../ingredients/IngredientAmount.jsx'
import { RecipeTime } from './RecipeTime.jsx'
import { RecipeTemperature } from './RecipeTemperature.jsx'

const timeRegExp = '(\\d+)\\s*(min|m|s|h|d)'
const tempRegExp = '(\\d+)\\s*º?\\s*([CcFf])'

const HandleIngredient = props => {
  const byName = props.ingredientsByName
  const ingredient = byName[props.decoratedText.toLowerCase()]

  const ingredientList = props.meal.ingredientList || []
  const ingredientEntry = ingredientList.reduce((found, entry) => {
      return entry.ingredient.id == ingredient.id ?
        entry : found
  }, null)
        
  return (
    <div
      className={styles.ingredient}
      contentEditable="false"
    >
      <IngredientTag
        doc={ingredient}
      >
        <IngredientAmount
          entry={ingredientEntry}
          className={styles.amount}
        />
      </IngredientTag>
      {" "}
    </div>
  )
}

const HandleTime = props => {
  const re = new RegExp(timeRegExp)
  const result = props.decoratedText.match(re)

  return (
    <RecipeTime time={result[1]} unit={result[2]} />
  )
}

const HandleTemp = props => {
  const result = props.decoratedText.match(tempRegExp)

  return (
    <RecipeTemperature temp={result[1]} unit={result[2]} />
  )
}

// This requires match ending with /\s/
function findWithRegex(search, block, callback) {
  const text = block.getText()

  const regWithSpace = new RegExp(search + '[^a-z]', 'gi')
  const resultWithSpace = text.match(regWithSpace)

  if (resultWithSpace) {
    const regex = new RegExp(search, 'gi')
    let matchArr, start
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      callback(start, start + matchArr[0].length)
    }
  }
}

export class RecipeEditor extends Component {
  constructor(props) {
    super(props)

    const { ingredientList } = props.meal
    const ingredients = ingredientList.map(entry => entry.ingredient)
    const keyed = ingredientsByName(ingredients)
    const names = Object.keys(keyed)
    this.ingredientsByName = names.reduce((hash, name) => {
      hash[name.toLowerCase()] = keyed[name]
      return hash
    }, {})

    const decorator = new CompositeDecorator([
      {
        strategy: this.checkIngredient,
        component: HandleIngredient,
        props: {
          ingredientsByName: this.ingredientsByName,
          meal: props.meal
        }
      },
      {
        strategy: this.checkTime,
        component: HandleTime
      },
      {
        strategy: this.checkTemp,
        component: HandleTemp
      }
    ])

    const initial = props.initial || {
      entityMap: {},
      blocks: [{
        key: '1nuug',
        text: '',
        type: 'ordered-list-item',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: []
      }]
    }

    const initialState = convertFromRaw(initial)

    this.state = {
      editorState: EditorState.createWithContent(initialState, decorator)
    }

    this.focus = () => this.refs.editor.focus()
  }

  checkIngredient = (block, callback, state) => {
    const names = Object.keys(this.ingredientsByName)
    names.forEach(name => {
      findWithRegex(name, block, callback)
    })
  }

  checkTime = (block, callback, state) => {
    findWithRegex(timeRegExp, block, callback)
  }

  checkTemp = (block, callback, state) => {
    findWithRegex(tempRegExp, block, callback)
  }

  // findIngredientEntities = (block, callback, state) => {
  //   block.findEntityRanges(
  //     (char) => {
  //       const key = char.getEntity()
  //       return key !== null && state.getEntity(key).getType() == 'ingredient'
  //     },
  //     callback
  //   )
  // }

  onChange = (editorState) => this.setState({ editorState })

  handleKeyCommand = (command) => {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    } else
      return 'not-handled'
  }

  // FIXME This is not working. Horrible things happen with
  // the selection, both here and with slatejs.
  //
  // handleBeforeInput = (chars, state) => {
  //   if (chars == ' ') {
  //     const selection = state.getSelection()
  //     const anchor = selection.getAnchorKey()
  //     let content = state.getCurrentContent()
  //     const block = content.getBlockForKey(anchor)
  //     const text = block.getText()

  //     let replacements = []
  //     let entityKeys = []

  //     this.checkIngredient(block, (start, end, ingredient) => {
  //       content = content.createEntity(
  //         'ingredient',
  //         'IMMUTABLE',
  //         { ref: ingredient.getRef() }
  //       )
  //       const entityKey = content.getLastCreatedEntityKey()
  //       entityKeys.push(entityKey)

  //       const key = block.getKey()
  //       const replaceSel = SelectionState.createEmpty(key)
  //         .merge({
  //           anchorOffset: start,
  //           focusOffset: end
  //         })

  //       replacements.push(replaceSel)
  //     }, state)

  //     let editorState = state
  //     replacements.forEach((selection, idx) => {
  //       content = Modifier.replaceText(
  //         content,
  //         selection,
  //         ' ',
  //         null,
  //         entityKeys[idx]
  //       )

  //       const endPos = selection.get('focusOffset')
  //       const endSel = selection.set('anchorOffset', endPos)
  //                               .set('hasFocus', true)

  //       content = Modifier.insertText(
  //         content,
  //         endSel,
  //         ' '
  //       )

  //       editorState = EditorState.push(
  //         editorState,
  //         content,
  //         editorState.getLastChangeType()
  //       )

  //       editorState = EditorState.forceSelection(
  //         editorState,
  //         endSel
  //       )
  //     })

  //     if (replacements.length > 0) {
  //       // Selection hack
  //       // debugger


  //       this.setState({ editorState })

  //       setTimeout(() => {
  //         const cont = editorState.getCurrentContent()
  //         const sel = cont.getSelectionAfter()
  //         editorState = EditorState.forceSelection(editorState, sel)

  //         this.setState({ editorState })
  //       }, 0)

  //       return 'handled'
  //     } else
  //       return 'not-handled'
  //   }

  //   return 'not-handled'
  // }

  toggleBlockType = (type) => {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        type
      )
    )
  }

  toggleInlineStyle = (style) => {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        style
      )
    )
  }

  submit = () => {
    const content = this.state.editorState.getCurrentContent()
    const raw = convertToRaw(content)
    this.props.onSubmit(raw)
  }

  blockRenderer = (block) => {
    const type = block.getType()
    if (type == 'ingredient') {
      return {
        component: HandleIngredient,
        editable: false,
        props: block.getData()
      }
    }
  }

  renderControls() {
    if (!this.props.readOnly) {
      const { editorState } = this.state

      return (
        <div>
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
        </div>
      )
    }
  }

  renderSubmit() {
    if (!this.props.readOnly) {
      return (
        <button onClick={this.submit}>Salvare</button>
      )
    }
  }

  render() { 
    const { editorState } = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div className={styles.root}>
        {this.renderControls(editorState)}
        <div className={className} onClick={this.focus}>
          <Editor
            blockRendererFn={this.blockRenderer}
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            readOnly={this.props.readOnly}
            ref="editor"
          />
        </div>
      </div>
    )
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  const type = block.getType()
  switch (type) {
  case 'ordered-list-item':
    return 'recipe-line'
  case 'blockquote':
    return 'RichEditor-blockquouo'
  default:
    return null
  }
}
class StyleButton extends Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
  {label: 'Code Block', style: 'code-block'},
];
const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};
var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
];
const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  )
}
