import { Icon } from '../../ui/bootstrap/bootstrap'

import styles from './RecipeTime.sass'

export const RecipeTime = ({
  time,
  unit
}) =>
  <span className={styles.time} contentEditable="false">
    <Icon icon="clock" />
    {" "} {time} {unit}
  </span>
