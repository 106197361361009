import { createSchema } from 'react-hook-form-auto'
import gql from 'graphql-tag'

import { fileFragment } from '../pintor'
import { Ingredient } from './ingredient'
import { valuesAsArray } from './units'
import { useMutation } from '../graphql/notificatingMutation'

export const IngredientListSchema = createSchema('ingredientEntry', {
  amount: {
    type: 'number',
    required: true,
    defaultValue: 1
  },
  unit: {
    type: 'select',
    options: valuesAsArray(),
    defaultValue: 'u'
  },
  ref: {
    type: 'search',
    associated: false,
    model: Ingredient
  }
})

export const mealSchema = createSchema('meal', {
  name: {
    type: 'string',
    required: true,
    max: 255
  },
  memo: {
    type: 'string',
    textarea: true
  },
  ingredientList: {
    type: [IngredientListSchema]
  },
  imageIds: {
    type: 'images',
    onlyOne: true
  },
  public: {
    type: 'makePublic',
    path: 'meal'
  }
})

export const mealFragment = gql`
  fragment MealFragment on Meal {
    id
    createdAt
    name
    memo
    recipe
    ingredientList {
      amount
      unit
      ingredient {
        ...IngredientFragment
      }
    }
    images {
      ...FileFragment
    }
    public
    user {
      id
      username
    }
  }

  ${Ingredient.fragment}
  ${fileFragment}
`

const MEALS_GET = gql`
  query MealsList(
    $search: String,
    $first: Int,
    $num: Int,
    $seeEveryones: Boolean
  ) {
    collection: meals(
      search: $search,
      first: $first,
      num: $num,
      seeEveryones: $seeEveryones
    ) {
      ...MealFragment
    }
  }
  ${mealFragment}
`

const MEALS_SUB = gql`
  subscription MealUpdate {
    mealChange {
      ...MealFragment
    }
  }
  ${mealFragment}
`

const MEALS_COUNT = gql`
  query MealCount($search: String, $seeEveryones: Boolean) {
    count: mealsCount(search: $search, seeEveryones: $seeEveryones)
  }
`

const MEALS_MULTI_GET = gql`
  query MealMultiGet($ids: [ID!]) {
    someMeals(ids: $ids) {
      ...MealFragment
    }
  }
  ${mealFragment}
` 

const MEAL_GET = gql`
  query MealGet($id: ID!) {
    meal(id: $id) {
      ...MealFragment
    }
  }
  ${mealFragment}
` 

const MEAL_UPDATE = gql`
  mutation MealUpdate($input: MealInput!) {
    mealUpdate(input: $input) {
      ...MealFragment
    }
  }
  ${mealFragment}
`

const MEAL_RECIPE_UPDATE = gql`
  mutation MealRecipeUpdate($id: Int!, $recipe: String!) {
    mealRecipeUpdate(id: $id, recipe: $recipe) {
      ...MealFragment
    }
  }
  ${mealFragment}
`

function formConvert(doc) {
  const { images, ingredientList } = doc

  return {
    ...doc,
    imageIds: images && images.map(image => image.id),
    ingredientList: ingredientList && ingredientList.map(entry => {
      const { ingredient } = entry

      return {
        ...entry,
        ref: {
          id: ingredient.id,
          type: 'ingredient',
          search: ingredient.name
        }
      }
    })
  }
}

function mutationConvert(doc) {
  let {
    id,
    images,
    imageIds,
    ingredientList,
    __typename,
    createdAt,
    recipe,
    user,
    ...rest
  } = doc

  const useIds = imageIds || images.map(image => image.id)
  ingredientList = ingredientList.map(entry => {
    const { amount, unit, ref } = entry

    return { amount, unit, ref }
  })

  return {
    variables: {
      input: {
        ...rest,
        id: parseInt(id),
        imageIds: useIds,
        ingredientList
      }
    }
  }
}

function useUpdate(options = {}) {
  return useMutation(MEAL_UPDATE, options)
}

export const Meal = {
  name: 'meal',
  schema: mealSchema,
  search: MEALS_GET,
  fetch: MEALS_GET,
  fetchIds: MEALS_MULTI_GET,
  sub: MEALS_SUB,
  mainSubName: 'mealChange',
  mainSubCollection: 'collection',
  count: MEALS_COUNT,
  get: MEAL_GET,
  update: MEAL_UPDATE,
  useUpdate,
  updateRecipe: MEAL_RECIPE_UPDATE,
  fragment: mealFragment,
  formConvert,
  mutationConvert
}
