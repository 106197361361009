export class ButtonConfig {
  constructor(creator) {
    this.creator = creator
  }

  createFromDoc(doc) {
    return this.creator(doc)
  }
}

    // this.icon = config.icon
    // this.text = config.text
    // this.onClick = config.onClick
    // this.type = config.type


