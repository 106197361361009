import { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { tr } from 'react-hook-form-auto'

import styles from './Pagination.module.sass'


const ItemContent = ({
  text,
  aria
}) => {
  if (aria) {
    return (
      <span aria-hidden="true">
        {text}
      </span>
    )
  } else
    return text
}

const Item = ({
  onClick,
  text,
  aria,
  active,
  disabled
}) =>
  <li onClick={onClick} className={classnames('page-item', { active, disabled })}>
    <a href="#" className="page-link" aria-label={aria}>
      <ItemContent text={text} aira={aria} />
    </a>
  </li>

export class Pagination extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    perPage: PropTypes.number,
    maxShow: PropTypes.number,
    onPageChange: PropTypes.func.isRequired
  }

  handlePrevious = () => {
    const newPage = this.props.page - 1

    this.props.onPageChange(newPage < 0 ? 0 : newPage)
  }

  handleFirst = () => {
    this.props.onPageChange(0)
  }

  handleLast = () => {
    const last = parseInt(this.props.total / this.perPage())
    this.props.onPageChange(last)
  }

  handleNext = () => {
    if (this.props.page < this.lastPage()) {
      const newPage = this.props.page + 1
      const { total } = this.props
      this.props.onPageChange(newPage >= total ?  total - 1 : newPage)
    }
  }

  gotoPage = (page) => {
    this.props.onPageChange(page)
  }

  renderPage = (page) => {
    const active = page == this.props.page

    return (
      <Item
        key={page}
        onClick={this.gotoPage.bind(this, page)}
        text={page + 1}
        active={active}
      >
        {page + 1}
      </Item>
    )
  }

  lastPage() {
    return parseInt(this.props.total / this.perPage())
  }

  perPage() {
    return this.props.perPage || 10
  }

  maxShow() {
    return this.props.maxShow || 10
  }

  displayPage() {
    return this.props.page + 1
  }

  displayTotalPages() {
    return this.lastPage() + 1
  }

  renderLiterature() {
    if (this.props.total > 0) {
      return tr('pagination.showing', {
        page: this.displayPage(),
        total: this.displayTotalPages(),
        documents: this.props.total
      })
    } else {
      return tr('pagination.noResults')
    }
  }

  renderPages(props) {
    const { total, page } = props
    const havePages = total / this.perPage()
    const maxNumbers = this.maxShow()

    const num = Math.min(havePages, maxNumbers)
    const side = parseInt(num / 3)
    const last = this.lastPage()

    const firstShow = Math.max(0, page - side)
    const lastShow = Math.min(last, page + side)

    let renderedPages = []
    if (firstShow > 0)
      renderedPages.push(this.renderPage(0))
    if (firstShow == 2)
      renderedPages.push(this.renderPage(1))
    if (firstShow > 2)
      renderedPages.push(<Item key="elip-first" text="…" disabled />)

    for (let i = firstShow; i <= lastShow; i++) {
      renderedPages.push(this.renderPage(i))
    }

    const lastDiff = last - lastShow
    if (lastDiff > 2)
      renderedPages.push(<Item key="elip-last" text="…" disabled />)
    if (lastDiff == 2)
      renderedPages.push(this.renderPage(last - 1))
    if (lastDiff > 0)
      renderedPages.push(this.renderPage(last))

    return renderedPages
  }

  renderPagination() {
    const { page } = this.props
    const last = this.lastPage()

    if (this.props.total > 0) {
      return (
        <nav aria-label={tr('pagination.title')}>
          <ul className="pagination">
            <Item
              key="first"
              onClick={this.handleFirst}
              text="«"
              aria={tr('pagination.first')}
            />
            <Item
              key="prev"
              onClick={this.handlePrevious}
              text="‹"
              aria={tr('pagination.previous')}
              disabled={page == 0}
            />
            {this.renderPages(this.props)}
            <Item
              key="next"
              onClick={this.handleNext}
              text="›"
              aria={tr('pagination.next')}
              disabled={page == last}
            />
            <Item
              key="last"
              onClick={this.handleLast}
              text="»"
              aria={tr('pagination.last')}
            />
          </ul>
        </nav>
      )
    } else {
      return null
    }
  }

  render() {
    const total = this.props.total
    const literatureClasses = classnames(styles.literature, {
      [styles.empty]: total == 0
    })

    return (
      <div>
        <div className={literatureClasses}>
          {this.renderLiterature()}
        </div>
        {this.lastPage() > 1 && this.renderPagination()}
      </div>
    )
  }
}
