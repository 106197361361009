.micro {
  color: #333;
  background-color: #f0f0f0;
  padding: 12px 8px;
  margin: 4px;
  border-radius: 8px;
}
.micro .title {
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 8px;
}
.micro .memo {
  font-size: 10px;
  font-style: italic;
}
.micro .ingredients {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 8px;
}
.micro .ingredients .ingredient {
  font-size: 10px;
  line-height: 19px;
  flex: 1 auto;
  height: 24px;
  padding: 2px 4px;
  background-color: #ddd;
  border-radius: 4px;
  text-align: center;
  margin: 2px;
}
.micro .controls {
  display: flex;
  padding: 4px;
}
.micro .controls button {
  flex: 1 auto;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvbWVhbHMiLCJzb3VyY2VzIjpbIk1lYWxJbmZvTWljcm8uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDRjtFQUNFO0VBQ0E7O0FBQ0Y7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0o7RUFDRTtFQUNBOztBQUNBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIubWljcm9cbiAgY29sb3I6ICMzMzNcbiAgYmFja2dyb3VuZC1jb2xvcjogI2YwZjBmMFxuICBwYWRkaW5nOiAxMnB4IDhweFxuICBtYXJnaW46IDRweFxuICBib3JkZXItcmFkaXVzOiA4cHhcbiAgLnRpdGxlXG4gICAgZm9udC13ZWlnaHQ6IDcwMFxuICAgIGZvbnQtc2l6ZTogMjJweFxuICAgIGxpbmUtaGVpZ2h0OiAyMnB4XG4gICAgbWFyZ2luLWJvdHRvbTogOHB4XG4gIC5tZW1vXG4gICAgZm9udC1zaXplOiAxMHB4XG4gICAgZm9udC1zdHlsZTogaXRhbGljXG4gIC5pbmdyZWRpZW50c1xuICAgIGRpc3BsYXk6IGZsZXhcbiAgICBmbGV4LWZsb3c6IHJvdyB3cmFwXG4gICAgbWFyZ2luLWJvdHRvbTogOHB4XG4gICAgLmluZ3JlZGllbnRcbiAgICAgIGZvbnQtc2l6ZTogMTBweFxuICAgICAgbGluZS1oZWlnaHQ6IDE5cHhcbiAgICAgIGZsZXg6IDEgYXV0b1xuICAgICAgaGVpZ2h0OiAyNHB4XG4gICAgICBwYWRkaW5nOiAycHggNHB4XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZGRkXG4gICAgICBib3JkZXItcmFkaXVzOiA0cHhcbiAgICAgIHRleHQtYWxpZ246IGNlbnRlclxuICAgICAgbWFyZ2luOiAycHhcbiAgLmNvbnRyb2xzXG4gICAgZGlzcGxheTogZmxleFxuICAgIHBhZGRpbmc6IDRweFxuICAgIGJ1dHRvblxuICAgICAgZmxleDogMSBhdXRvXG4iXX0= */