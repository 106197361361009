import { gql } from '@apollo/client'

import { getHooks } from '../hooks'

export function getVersion(image, version = 'normal') {
  if (image && image.versions) {
    return image.versions.reduce((found, cur) =>
      cur.version == version && cur || found
    )
  }
}

export function getImageUrl(image, version) {
  if (image) {
    const entry = getVersion(image, version)

    return '/' + (entry && entry.path || image.path)
  }
}

export function getImageUrlFromDoc(doc, versionName, field = 'images') {
  if (doc) {
    const value = doc[field]
    if (value && value.length >= 1) {
      const pic = value[0]
      return getImageUrl(pic, versionName)
    }
  }
}

export const isImage = extension => /png|jpg|jpeg/i.test(extension)
export const docIsImage = doc => doc && isImage(doc.extension)

export const fileFragment = gql`
  fragment FileFragment on File {
    id
    filename
    mimetype
    path
    versions {
      version
      extension
      path
    }
  }
`

export const getFileFragment = () => fileFragment

export const ATTACHED_FILES = gql`
  query Images($ids: [ID!]) {
    images(ids: $ids) {
      ...FileFragment
    }
  }
  ${fileFragment}
`

export const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteImage(id: $id)
  }
`

// Takes the ids from the prop whose name is propName
//
// const SuperComponent = withImages('imageIds')(MyVisualThing)
export const useImages = (ids = []) => {
  const { useQuery } = getHooks()
  const { data, loading } = useQuery(ATTACHED_FILES, {
    variables: { ids }
  })
  const images = data && data.images || []

  return { images, loading }
}
