import { Greetings } from '../Greetings'

import { Login } from './Login'
import { tr } from 'react-hook-form-auto'
import { Jumbotron } from '../../components/Jumbotron'

export const LoginScreen = () => {
  return (
    <Jumbotron title={tr('auth.login')}>
      <Login register={false} />
    </Jumbotron>
  )
}
