import React from 'react'

import defStyles from './Utils.module.sass'

function accentsTidy(s) {
  var r = s.toLowerCase()
  r = r.replace(new RegExp("[àáâãäå]", 'g'), "a")
  r = r.replace(new RegExp("æ", 'g'), "ae")
  r = r.replace(new RegExp("ç", 'g'), "c")
  r = r.replace(new RegExp("[èéêë]", 'g'),"e")
  r = r.replace(new RegExp("[ìíîï]", 'g'),"i")
  r = r.replace(new RegExp("ñ", 'g'), "n");                            
  r = r.replace(new RegExp("[òóôõö]", 'g'),"o")
  r = r.replace(new RegExp("œ", 'g'), "oe")
  r = r.replace(new RegExp("[ùúûü]", 'g'),"u")
  r = r.replace(new RegExp("[ýÿ]", 'g'),"y")
  return r
}

/**
 * Renders a text decorating any matching part with
 * <span class="match">(match)</span>
 */
export function renderMatch(value = '', search, styles = defStyles) {
  if (search) {
    const tidied = accentsTidy(search)
    const searchParts = tidied.split(' ')
    const tidiedAndRegexed = searchParts.filter(v => v).join('|')
    const regEx = new RegExp(`(${tidiedAndRegexed})`, 'gi')

    let result
    let arr = []
    let lastIndex = 0

    const tidiedValue = accentsTidy(value)
    const len = tidied.length
    while ((result = regEx.exec(tidiedValue)) !== null) {
      const hi = value.substring(result.index, result.index + len)
      if (result.index > lastIndex)
        arr.push({ text: value.substring(lastIndex, result.index) })
      arr.push({ hi })
      lastIndex = result.index + len
    }

    arr.push({ text: value.substring(lastIndex, value.length) })

    return (
      <span>
        {arr.map((elem, idx) => {
          if (elem.text)
            return elem.text
          else
            return <span key={idx} className={styles.match}>{elem.hi}</span>
        })}
      </span>
    )
  } else
    return value
}

export const nlToTag = (text, $element, props) => {
  if (text) {
    const partition = text.split('\n')

    return partition.map((line, idx) =>
      <$element key={idx} {...props}>
        {line}
      </$element>
    )
  }
}
