import { tr } from 'react-hook-form-auto'

import { FlexList } from '../../components/FlexList'

const renderResults = ({
  col,
  onSelect,
  search,
  RenderComponent,
  selectedIdx,

  ...rest
}) => {
  if (col.length > 0) {
    return col.map((doc, idx) => {
      const props = {
        doc,
        onClick: onSelect.bind(null, doc),
        inline: true,
        selected: idx == selectedIdx,
        search,

        ...rest
      }

      return (
        <RenderComponent key={doc.id} {...props} />
      )
    })
  }
}

const renderBuyableList = (params) => {
  const { col } = params

  if (col && col.length > 0)
    return renderResults(params)
  else
    return null
}

export const BuyablesList = ({
  loading,
  collection = [],
  search,
  searching,
  onSelect,
  onAddSelected,
  onResults,
  listWrapper,
  buyableRenderer,
  selectedIdx,

  ...rest
}) => {
  const Wrapper = listWrapper || FlexList
  const RenderComponent = buyableRenderer || BuyableTag

  const ingElements = collection ? renderBuyableList({
    col: collection,
    onSelect,
    RenderComponent,
    selectedIdx,
    search,

    ...rest
  }) : []

  if (onResults)
    onResults(collection)

  return (
    <Wrapper>
      {ingElements}
      <RenderComponent
        text={tr('add')}
        onClick={onAddSelected}
        isAdd
        inline
      />
    </Wrapper>
  )
}
