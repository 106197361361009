import { useQuery } from '@apollo/client'

import { History } from '../../models/history'

import { HistoryView } from './HistoryView'

export const HistoryContainer = (props) => {
  const { data } = useQuery(History.fetch)
  History.useHistoryUpdate()

  const history = data && data.history

  return (
    <HistoryView
      {...props}
      history={history}
    />
  )
}
