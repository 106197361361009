import { tr } from 'react-hook-form-auto'
import { convertUnit } from '../../models/units'

const KCAL_PER_JUL = 0.239006

const cutreConv = (strVal, unit = 'g') => {
  return {
    value: parseFloat(strVal),
    unit
  }
}

export const emptyInfo = () => ({
  warns: {},
  list: {}
})

const nutrimentsTable = [{
  key: 'carbohydrates',
  conversion: cutreConv
}, {
  key: 'energy',
  conversion: (strVal, unit = 'kJ') => {
    const value = parseFloat(strVal)
    switch (unit) {
    case 'kJ':
      return {
        value,
        unit,
        others: [{
          value: value * KCAL_PER_JUL,
          unit: 'kcal'
        }]
      }
    case 'kcal':
      return {
        value: value / KCAL_PER_JUL,
        unit: 'kcal',
        others: [{ value, unit }]
      }
    }
  }
}, {
  key: 'fat',
  conversion: cutreConv
}, {
  key: 'fiber',
  conversion: cutreConv
}, {
  key: 'proteins',
  conversion: cutreConv
}, {
  key: 'salt',
  conversion: cutreConv
}, {
  key: 'saturated-fat',
  conversion: cutreConv
}, {
  key: 'sodium',
  conversion: cutreConv
}, {
  key: 'sugars',
  conversion: cutreConv
}]

const getLabel = key => tr('nutrition.' + key)

const convertToHash = (nutriments) =>
  nutriments.reduce((hash, info) => {
    const nutriment = info.type
    hash[nutriment] = info

    return hash
  }, {})
  
const processNutriments = (nutriments, ingredientId) => {
  const nutrimentsHash = convertToHash(nutriments)

  const initialData = {
    list: [],
    warns: {}
  }
  if (nutriments.length == 0)
    initialData.warns[ingredientId] = true

  return nutrimentsTable.reduce((data, info, idx) => {
    const { key, conversion } = info
    const { list } = data

    const nutData = nutrimentsHash[key]
    if (nutData) {
      const { amount, unit } = nutData
      const converted = conversion(amount, unit)

      list.push({
        conversion,
        label: getLabel(key),
        rawValue: converted.value,
        unit: converted.unit
      })
    }

    return data
  }, initialData)
}

const displayUnit = (infoUnit) =>
  ` ${infoUnit.value.toFixed(2)} ${infoUnit.unit}`

const displayNutrimentValue = (nutrimentValue) => {
  const converted = nutrimentValue.conversion(
    nutrimentValue.rawValue
  )

  let val = displayUnit(converted)

  const { others } = converted
  if (others) {
    const displays = others.map(other =>
      displayUnit(other)
    )

    val += ` (${displays.join(', ')})`
  } 

  return val
}

const addDisplayValuesToSums = (sum) => {
  return {
    warns: sum.warns,
    list: sum.list.map(value => ({
      ...value,
      displayText: displayNutrimentValue(value)
    }))
  }
}

export const getInfoFromIngredient = (ingredient) => {
  if (ingredient) {
    const { nutriments } = ingredient

    if (nutriments) {
      const sum = processNutriments(nutriments, ingredient.id)
      return addDisplayValuesToSums(sum)
    } else
      return emptyInfo()
  } else
    return emptyInfo()
}

export const getInfoFromMeal = (meal) => {
  const list = meal.ingredientList || []

  const initial = {
    warns: {},
    list: nutrimentsTable.map((nutriment) => ({
      ...nutriment,
      label: getLabel(nutriment.key),
      rawValue: 0
    }))
  }

  const sums = list.reduce((sums, mealingredient) => {
    const { ingredient } = mealingredient
    if (ingredient && ingredient.nutriments) {
      const { nutriments } = ingredient

      const sum = processNutriments(nutriments, ingredient.id)
      const piece = sum.list

      // Multiply by ingredient amount in meal
      sums.list = piece.reduce((subtotals, cur, idx) => {
        let value = cur.rawValue
        const { amount, unit } = mealingredient
        if (unit) {
          const unitData = convertUnit(amount, unit, 'g')
          if (unitData)
            value = unitData.value * cur.rawValue / 100
        }
        subtotals[idx].rawValue += value

        return subtotals
      }, sums.list)
    } else {
      sums.warns[ingredient.id] = true
    }

    return sums
  }, initial)

  const prunedSums = sums.list.filter(sum => {
    const val = sum.rawValue
    return val != 0 && !isNaN(val)
  })

  return addDisplayValuesToSums({
    warns: sums.warns,
    list: prunedSums
  })
}
