import { createSchema } from 'react-hook-form-auto'
import { useSubscription } from '@apollo/client'
import gql from 'graphql-tag'

import { createUpdater } from '../pintor'
import { Meal } from './meal'

const dietSchema = createSchema('diet', {
  name: {
    type: 'string'
  },
  memo: {
    type: 'string'
  }
})

const dietFragment = gql`
  fragment DietFragment on Diet {
    id
    name
    memo
    meals {
      ...MealFragment
    }
    mealIds
    createdAt
    updatedAt
  }
  ${Meal.fragment}
`

const DIETS_GET = gql`
  query DietsList {
    diets {
      ...DietFragment
    }
  }
  ${dietFragment}
`

const DIETS_SUB = gql`
  subscription DietUpdate {
    dietChange {
      ...DietFragment
    }
  }
  ${dietFragment}
`

const DIETS_COUNT = gql`
  query DietCount($search: String) {
    count: dietsCount(search: $search)
  }
`

const DIET_GET = gql`
  query DietGet($id: ID!) {
    diet(id: $id) {
      ...DietFragment
    }
  }
  ${dietFragment}
`

const DIETS_MULTI_GET = gql`
  query DietMultiGet($ids: [ID!]) {
    someDiets(ids: $ids) {
      ...DietFragment
    }
  }
  ${dietFragment}
`

const DIET_UPDATE = gql`
  mutation DietUpdate($input: DietInput!) {
    dietUpdate(input: $input) {
      ...DietFragment
    }
  }
  ${dietFragment}
`

const DIET_COOK = gql`
  mutation DietPurchase($id: ID!) {
    cook(id: $id)
  }
`


function formConvert(doc) {
  const images = doc.images || []
  const ingredientList = doc.ingredientList || []

  return {
    ...doc,
    imageIds: images.map(image => image.id),
    ingredientList: ingredientList.map(entry => {
      const { ingredient } = entry

      return {
        ...entry,
        ref: {
          id: ingredient.id,
          type: 'ingredient',
          search: ingredient.name
        }
      }
    })
  }
}

const findDiet = (diets, newDiet) =>
  diets.reduce((found, cur, idx) => {
    if (found == -1)
      return cur.id == newDiet.id ? idx : found
    else
      return found
  }, -1)

const dataUpdater = createUpdater({
  query: DIETS_GET,
  subscriptionName: 'dietChange',
  collectionKey: 'diets'
})

const useDietSub = () => {
  const { data, loading } = useSubscription(Diet.sub, {
    onData: dataUpdater
  })
  const buyChange = data && data.buyChange

  return { buyChange }
}


export const Diet = {
  name: 'diet',
  schema: dietSchema,
  fetch: DIETS_GET,
  fetchIds: DIETS_MULTI_GET,
  sub: DIETS_SUB,
  count: DIETS_COUNT,
  get: DIET_GET,
  update: DIET_UPDATE,
  fragment: dietFragment,
  formConvert,
  useDietSub
}
