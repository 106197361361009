import { useState } from 'react';
import classnames from 'classnames'
import { tr } from 'react-hook-form-auto'

import { Icon, ModalAutoform } from '../../ui/bootstrap/bootstrap'
import GroupIngredientsContainer from './GroupIngredientsContainer'
import { IngredientGroup } from '../../models/ingredientGroup'

import styles from './GroupsView.sass'

const renderGroups = ({
  collection = [],
  showMore = {},
  onShowMore,
  onEditDoc
}) =>
  collection.map(({ id, name }) => {
    const handleEdit = () => onEditDoc({ id, name })

    return (
      <div key={id} className={styles.group}>
        <div className={classnames(styles.title, styles.bar)}>
          {name}
          <div className={styles.edit} onClick={handleEdit}>
            <Icon icon="bars" />
          </div>
        </div>
        <GroupIngredientsContainer
          groupId={id}
          long={showMore[id]}
          onShowMore={onShowMore}
        />
      </div>
    )
  })

const renderForm = ({ editing, doc, onGroupUpdate, onClose }) => {
  if (editing) {
    return (
      <ModalAutoform
        onSubmit={onGroupUpdate}
        initialValues={doc}
        schema={IngredientGroup.schema}
        form="ingredientGroup"
        onClose={onClose}
        modalTitle={tr('models.groups')}
        visible={editing}
      />
    )
  } else
    return null
}

export const GroupsView = ({
  groupUpdate,
  collection,
  showMore,
  onShowMore,
  onGroupUpdate,
  ...rest
}) => {
  const [ editing, setEditing ] = useState(false)
  const [ editDoc, setEditDoc ] = useState({})

  const handleEditDoc = (doc = {}) => {
    setEditing(true)
    setEditDoc(doc)
  }

  const handleModalClose = () => {
    setEditing(false)
  }

  const formProps = {
    editing,
    doc: editDoc,
    onGroupUpdate,
    onClose: handleModalClose
  }

  const renderGroupsProps = {
    collection,
    showMore,
    onShowMore,
    onGroupUpdate,
    onEditDoc: handleEditDoc
  }

  return (
    <div>
      <div className={styles.groups}>
        <div key="add" className={styles.group}>
          <a
            className={classnames(styles.add, styles.bar)}
            onClick={handleEditDoc.bind(null, {})}
          >
            <Icon icon="plus" text={tr('add')} />
          </a>
        </div>
        {renderGroups(renderGroupsProps)}
      </div>
      {renderForm(formProps)}
    </div>
  )
}
