import { tr } from 'react-hook-form-auto'
import { Card } from '../../components/crud/Card'

const labelText = label => label && tr(`models.meal.info.${label}`) || ''

const provideData = (doc, classes, render) => {
  const renderTr = (icon, label, data, inv) =>
    render({ icon, label: labelText(label), data, classes, inv })

  const numIngredients = doc ? (doc.ingredientList || []).length : 0
  // FIXME generalize plurals
  const numIngLabel = numIngredients > 1 ? 'ingredients' : 'ingredient'

  const servingsLabel = doc.servings > 1 ? 'servings' : 'serving'
  const servings = doc.servings ?
    renderTr('user', servingsLabel, doc.servings) : []

  const userDisplay = doc.user ?
    renderTr('user-tie', '', doc.user.username) : []

  return [
    userDisplay,
    renderTr('tag', numIngLabel, numIngredients),
    servings
  ]
}

export const MealCard = ({ docKey, ...props }) =>
  <Card
    key={docKey}
    {...props}
    modelIcon="utensils"
    infoProvider={provideData}
    baseColor="meal"
  />
