import { Autoform } from '../../autoform/Autoform'
import { tr, createSchema } from 'react-hook-form-auto'

const user = createSchema('user', {
  username: {
    type: 'string'
  },
  email: {
    type: 'string'
  },
  imageIds: {
    type: 'images',
    onlyOne: true
  }
})

export function ProfileView({
  currentUser,
  onBasicSubmit,
  onChangePasswordSubmit
}) {
  return (
    <div key="profile" className="hola-hola">
      <h3>{currentUser && currentUser.username}</h3>
      <h4>{tr('user.basicConfig')}</h4>
      <Autoform
        schema={user}
        onSubmit={onBasicSubmit}
        submitButton
        submitButtonText={tr('update')}
        initialValues={currentUser}
      />
    </div>
  )
}
