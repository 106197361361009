export const IngredientAmount = ({
  entry,
  amount,
  unit,
  className
}) => {
  const useAmount = entry ? entry.amount : amount
  const useUnit = entry ? entry.unit : unit

  return (
    <span className={className} key={useUnit}>
      {` ${useAmount} ${useUnit}`}
    </span>
  )
}
