import { Icon } from '../../ui/bootstrap/bootstrap'
import { FlexList, FlexItem } from '../../components/FlexList'
import { IngredientTagFlex } from '../ingredients/IngredientTagFlex'

import styles from './GroupIngredientsView.sass'

const ShowMore = ({ groupId, showMore, onShowMore }) =>
  showMore &&
    <FlexItem
      className={styles.dots}
      onClick={onShowMore.bind(null, groupId)}
    >
      <Icon icon="ellipsis-h" />
    </FlexItem>

const renderIngredients = (ingredients = [], long, onShowMore) =>
  ingredients.map((ingredient, long) =>
    <IngredientTagFlex key={ingredient.id} doc={ingredient} />
  )

export const GroupIngredientsView = ({
  groupId,
  collection,
  long,
  onShowMore
}) =>
  <FlexList>
    {renderIngredients(collection, long, onShowMore)}
    {
      collection && collection.length > 0 &&
        <ShowMore
          groupId={groupId}
          showMore={!long}
          onShowMore={onShowMore}
        />
    }
  </FlexList>
