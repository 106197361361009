import { tr } from 'react-hook-form-auto'
import { ImageShow, nlToTag } from '../../pintor'
import { RecipeEditor } from './RecipeEditor'
import { CommentSection } from '../threads/CommentSection'

import styles from './MealInfo.sass'

const renderAmount = (entry) => `${entry.amount} ${entry.unit}`

const renderIngredientEntry = (idx, entry) => {
  const { ingredient } = entry

  if (ingredient) {
    return (
      <tr key={idx}>
        <td>
          {ingredient.name}
        </td>
        <td className="amount">
          {renderAmount(entry)}
        </td>
      </tr>
    )
  } else
    return null
}

const getIngredientList = (meal) => meal.ingredientList || []

const renderIngredientEntries = (meal) => {
  const ingredientList = getIngredientList(meal)

  return ingredientList.map((entry, idx) => {
    return renderIngredientEntry(idx, entry)
  })
}

const renderRecipe = doc => {
  const { recipe } = doc

  if (recipe) {
    return (
      <div className="recipe-show">
        <h2>{tr('shop.recipe')}</h2>
        <RecipeEditor
          meal={doc}
          initial={JSON.parse(recipe)}
          readOnly
        />
      </div>
    )
  }
}

export const MealInfo = ({
  doc
}) =>
  <div className={styles.mealIngredients}>
    <h2>{doc.name}</h2>
    <div className={styles.mealNotes}>
      {nlToTag(doc.memo, 'div', { className: styles.memoLine })}
    </div>
    <div className={styles.mealPicIngredients}>
      {
        doc && doc.images && doc.images.length > 0 &&
          <div className={styles.mealShow}>
            <ImageShow
              file={doc.images[0]}
              side={240}
            />
          </div>
      }
      <div className={styles.mealShow}>
        <table className="table table-condensed">
          <tbody>
            {renderIngredientEntries(doc)}
          </tbody>
        </table>
      </div>
    </div>
    {renderRecipe(doc)}
    { /* <MealNutriments meal={doc} /> */ }
    <CommentSection parentType="meals" parentId={doc.id} />
  </div>
