import { Loading } from '../../components/Loading'

import { Thread } from '../../models/thread'
import { Comments } from './Comments'
import { CreateThread } from './CreateThread'
import { useUser } from '../../pintor'

// Parent: Any main view controller associable to a record
export const CommentSection = ({
  parentType,
  parentId
}) => {
  const {
    threads,
    loading,
    threadUpdate,
    postUpdate,
    upvote,
    downvote
  } = Thread.useComments({
    parentType, parentId
  })
  const { currentUser, loading: userLoading } = useUser()

  const handleCreate = ({ title, post }) => {
    const threadId = threads && threads.length > 0 && threads[0].id
    const update = threadUpdate({
      threadId,
      parentType,
      parentId,
      title,
      post
    })
  }

  const handleReply = ({ text, parentPostId, threadId }) => {
    const update = postUpdate({
      threadId,
      parentPostId,
      post: text
    })
  }

  const handleUpvote = (id) => {
    upvote(id)
  }

  const handleDownvote = (id) => {
    downvote(id)
  }

  if (loading || userLoading) {
    return <Loading />
  } else {
    return (
      <div>
        <Comments
          threads={threads}
          onPost={handleReply}
          onUpvote={handleUpvote}
          onDownvote={handleDownvote}
        />
        {
          currentUser &&
            <CreateThread onCreate={handleCreate} />
          ||
            <div>
              {tr('models.threads.registerToComment')}
            </div>
        }
      </div>
    )
  }
}
