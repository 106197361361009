.list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.list .title {
  font-size: 16px;
  font-weight: 600;
}
.list .sepTitle {
  margin-top: 8px;
}
.list .section .entry {
  margin: none;
  display: block;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcC9vcmlnaW5zIiwic291cmNlcyI6WyJPcmlnaW5zSW5mby5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTs7QUFDRjtFQUNFOztBQUVBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5saXN0XG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuICBmbGV4LXdyYXA6IG5vd3JhcFxuICAudGl0bGVcbiAgICBmb250LXNpemU6IDE2cHhcbiAgICBmb250LXdlaWdodDogNjAwXG4gIC5zZXBUaXRsZVxuICAgIG1hcmdpbi10b3A6IDhweFxuICAuc2VjdGlvblxuICAgIC5lbnRyeVxuICAgICAgbWFyZ2luOiBub25lXG4gICAgICBkaXNwbGF5OiBibG9ja1xuXG4iXX0= */