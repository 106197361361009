import { useState } from 'react'

import { tr } from 'react-hook-form-auto'

import { Votes } from './Votes'
import { Button } from '../../ui/bootstrap/bootstrap'
import { EditPostText } from './EditPostText'

import { postsNested } from './comment_utils'

import styles from './Comments.sass'

function Tree({
  node,
  level,
  onPost,
  threadId,
  onDownvote,
  onUpvote
}) {
  const [ childrenReplies, setChildrenReplies ] = useState({})

  const childReply = (id, text = '') => {
    setChildrenReplies({
      ...childrenReplies,
      [id]: {
        editing: true,
        text
      }
    })
  }

  const childLevel = level + 1
  // FIXME Lots of magic
  const childDesp = 600 / ((childLevel + 1) * 4)

  return node.map(post => {
    const created = new Date(post.createdAt)
    const updated = new Date(post.updatedAt)
    const createdLoc = created.toLocaleDateString()
    const updatedLoc = created != updated
      && updated.toLocaleDateString() || ''
    const postState = childrenReplies[post.id] || {}

    const handleWriteReply = (e) => {
      childReply(post.id, e.target.value)
    }

    const handleStopReplying = (id) => {
      setChildrenReplies({
        ...childrenReplies,
        [id]: {
          editing: false
        }
      })
    }

    const handlePost = () => {
      onPost({
        text: postState.text,
        parentPostId: post.id,
        threadId
      })
      handleStopReplying(post.id)
    }

    const handleUpvote = () => {
      onUpvote(post.id)
    }
    const handleDownvote = () => {
      onDownvote(post.id)
    }

    return (
      <div key={post.id}>
        <div className={styles.postLayout}>
          <div className={styles.postVoting}>
            <Votes
              post={post}
              onUpvote={handleUpvote}
              onDownvote={handleDownvote}
            />
          </div>
          <div className={styles.postContent}>
            <div className={styles.postInfo}>
              <div className={styles.infoUser}>
                {post.username}
              </div>
              <div className={styles.dates}>
                <div className={styles.date}>
                  {tr('threads.created', { date: createdLoc })}
                </div>
                {
                  updatedLoc &&
                    <div className={styles.date}>
                      {tr('threads.updated', { date: updatedLoc })}
                    </div>
                }
              </div>
              <div className={styles.buttons}>
                <Button
                  iconClass="comment"
                  text={tr('threads.reply')}
                  type="primary"
                  small
                  onClick={() => childReply(post.id, '')}
                />
                <Button
                  iconClass="quote-right"
                  className={styles.buttonSep}
                  text={tr('threads.quote')}
                  type="primary"
                  small
                  onClick={() => childReply(post.id, post.post)}
                />
              </div>
            </div>
            <div key={post.id} className={styles.post}>{post.post}</div>
          </div>
        </div>
        { postState.editing &&
            <div className={styles.postReply}>
              <EditPostText
                value={postState.text}
                onChange={handleWriteReply}
                postText={tr('threads.reply')}
                onPost={handlePost}
              />
            </div>
        }
        <div style={{ marginLeft: childDesp }}>
          <Tree
            node={post.children}
            level={childLevel}
            onPost={onPost}
            threadId={threadId}
            onUpvote={onUpvote}
            onDownvote={onDownvote}
          />
        </div>
      </div>
    )
  })
}

// Usual parent: CommentSection
export const Comments = ({
  threads,
  onPost,
  onUpvote,
  onDownvote,
}) => {
  if (threads && threads.length > 0) {
    const thread = threads[0]

    const nested = postsNested(thread)

    return (
      <div className={styles.comments}>
        <Tree
          node={nested}
          level={0}
          threadId={thread.id}
          onPost={onPost}
          onUpvote={onUpvote}
          onDownvote={onDownvote}
        />
      </div>
    )
  } else {
    return (
      <div className={styles.noComments}>
        {tr('threads.noComments')}
      </div>
    )
  }
}
