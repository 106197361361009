import {
  Modal as RSModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import styles from './Modal.sass'

export const Modal = ({
  title,
  visible,
  onClose,
  buttons,
  children
}) =>
  <RSModal
    className={styles.modal}
    isOpen={visible}
    toggle={onClose}
  >
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      {buttons}
    </ModalFooter>
  </RSModal>
