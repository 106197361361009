import { useQuery } from '@apollo/client'

import { Diet } from '../../../models/diet'
import { Meal } from '../../../models/meal'

export function useOrigins(buy) {
  const {
    data,
    loading: dietsLoading
  } = useQuery(Diet.fetchIds, {
    variables: {
      ids: buy.origins.map(origin => origin.dietId).filter(id => id)
    }
  })
  const someDiets = data && data.someDiets

  const {
    data: mealsData,
    loading: mealsLoading
  } = useQuery(Meal.fetchIds, {
    variables: {
      ids: buy.origins.reduce((ids, cur) => {
        return ids.concat(cur.mealIds)
      }, []).filter(id => id)
    }
  })
  const someMeals = mealsData && mealsData.someMeals

  return {
    someDiets,
    someMeals,
    loading: dietsLoading || mealsLoading
  }
}
