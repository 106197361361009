const units = {
  g: {
    display: 'g',
    factors: {
      kg: 0.01
    }
  },
  kg: {
    display: 'kg',
    factors: {
      g: 1000
    }
  },
  l: {
    display: 'l'
  },
  u: {
    display: 'ud.'
  }
}

export function asSelectOptions() {
  let options = []
  for (let k in units) {
    let unit = units[k]
    options.push({
      value: k,
      label: unit.display
    })
  }

  return options
}

export function valuesAsArray() {
  return Object.keys(units)
}

export function convertData(data, toUnit) {
  const { value, unit } = data

  return convertUnit(value, unit, toUnit)
}

export function convertUnit(strVal, unit, toUnit) {
  const value = parseFloat(strVal)

  if (unit == toUnit) {
    return {
      value,
      unit
    }
  } else {
    const unitInfo = units[unit]
    const { factors } = unitInfo

    if (factors && factors[toUnit]) {
      return {
        value: value * factors[toUnit],
        unit: toUnit
      }
    }
  }
}
