// Let's be able to change strategy. This is just a wrapper.

import Cookie from 'js-cookie'
let cookieKey

export function tokenRemove() {
  // localStorage.removeItem(cookieKey)
  Cookie.remove(cookieKey)
}

export function tokenSet(value) {
  // localStorage.setItem(cookieKey, value)
  Cookie.set(cookieKey, value, { expires: 1000, path: '' })
}

// tokenGet: () => localStorage.getItem(cookieKey)
export function tokenGet() {
  return Cookie.get(cookieKey)
}

export function setCookieKey(key) {
  cookieKey = key
}
