import { Component } from 'react'
import classnames from 'classnames'

import { DropdownMenu, Icon } from '../../ui/bootstrap/bootstrap'
import { renderMatch, ButtonConfig } from '../../pintor'

import styles from './IngredientTag.sass'

const icons = {
  ingredient: 'tag',
  add: 'plus'
}

export const IngredientTag = ({
  doc,
  isAdd,
  onClick,
  text,
  children,
  selected,
  className,
  search
}) => {
  const handleShowNutriments = (e) => {
    e.preventDefault()
  }

  const renderNutrimentsOverlay = doc => {
    if (doc && doc.nutriments) {
      const buttoner = [
        new ButtonConfig(doc => ({
          type: 'default',
          icon: 'apple',
          onClick: handleShowNutriments
        }))
      ] 

      return (
        <DropdownMenu
          icon="info"
          buttons={buttoner}
        />
      )
    } else
      return null
  }

  const handleIngredientClick = (e) => {
    if (!e.defaultPrevented && onClick)
      onClick(doc)
  }

  const type = doc ? 'ingredient' : 'add'
  const icon = icons[type]
  const displayText = text || doc.name
  const classes = classnames(className, {
    [styles.ingredient]: !isAdd,
    [styles.add]: isAdd,
    [styles.selected]: selected
  })

  return (
    <span className={classes} onClick={handleIngredientClick} >
      <span className={styles.name}>
        <Icon
          icon={icon}
          text={renderMatch(displayText, search, styles)}
          iconClass={styles.icon}
        />
      </span>
      {children}
      { /* this.renderNutrimentsOverlay(doc) */ }
    </span>
  )
}
