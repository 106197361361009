import { Icon } from '../../ui/bootstrap/bootstrap'

import styles from './RecipeTemperature.sass'

export const RecipeTemperature = ({
  temp,
  unit
}) =>
  <span className={styles.temp} contentEditable="false">
    <Icon icon="fire" />
    {" "} {temp}º {unit}
  </span>
