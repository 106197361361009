import { useQuery } from '@apollo/client'

import { Ingredient } from '../../models/ingredient'
import { IngredientGroup } from '../../models/ingredientGroup'
import { IngredientInfo } from './IngredientInfo'
import { IngredientCard } from './IngredientCard'

import { CrudView } from '../../components/crud/CrudView'
import { CardListWrapper } from '../../components/crud/CardListWrapper'

export const IngredientsView = () => {
  const createIngredient = Ingredient.useUpdate()
  const { data, loading } = useQuery(IngredientGroup.fetch)
  const collection = data && data.collection

  const handleDeleteIngredient = (doc) => {
    const id = doc.id
  }

  const handleSubmit = (ingredient) => {
    createIngredient(ingredient)
  }

  // render()
  return (
    <CrudView
      model={Ingredient}
      searchForm="ingredient-search"
      name="ingredients"
      onSubmit={handleSubmit}
      onDelete={handleDeleteIngredient}
      listWrapper={CardListWrapper}
      infoComponent={IngredientInfo}
      tableComponent={IngredientCard}
      searchFormProps={{ alwaysResults: true }}
      initialViewMode="small"
      autoformExtra={{ groups: collection }}
    />
  )
}
