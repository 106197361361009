.table {
  width: 100%;
}
.table .tr {
  border-radius: 2px;
}
.table .added {
  background-color: #cfc;
  color: #006600;
}
.table .removed {
  background-color: #fcc;
  color: #660000;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvaGlzdG9yeSIsInNvdXJjZXMiOlsiSGlzdG9yeVZpZXcuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFNQTtFQUNFOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRSxrQkFYYTtFQVliLE9BVGE7O0FBVWY7RUFDRSxrQkFiZTtFQWNmLE9BWGUiLCJzb3VyY2VzQ29udGVudCI6WyIkYWRkZWQtY29sb3ItYmc6ICNjZmNcbiRyZW1vdmVkLWNvbG9yLWJnOiAjZmNjXG5cbiRhZGRlZC1jb2xvci1mZzogZGFya2VuKCRhZGRlZC1jb2xvci1iZywgNzAlKVxuJHJlbW92ZWQtY29sb3ItZmc6IGRhcmtlbigkcmVtb3ZlZC1jb2xvci1iZywgNzAlKVxuXG4udGFibGVcbiAgd2lkdGg6IDEwMCVcbiAgLnRyXG4gICAgYm9yZGVyLXJhZGl1czogMnB4XG4gIC5hZGRlZFxuICAgIGJhY2tncm91bmQtY29sb3I6ICRhZGRlZC1jb2xvci1iZ1xuICAgIGNvbG9yOiAkYWRkZWQtY29sb3ItZmdcbiAgLnJlbW92ZWRcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAkcmVtb3ZlZC1jb2xvci1iZ1xuICAgIGNvbG9yOiAkcmVtb3ZlZC1jb2xvci1mZ1xuIl19 */