import { forwardRef, useRef, useImperativeHandle } from 'react';

import { tr } from 'react-hook-form-auto'

import { SearchForm } from '../../ui/utils/SearchForm'

import { BuyableResults } from './BuyableResults'
import { IngredientForm } from '../ingredients/IngredientForm'

export let BuyableSearchForm = ({
  alwaysResults,
  resultsComponent = BuyableResults,
  ...rest
}, ref) => {
  const inputRef = useRef()

  // FIXME I don't know exactly why I have to do this
  const inputReffer = el => {
    inputRef.current = el
  }

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (inputRef && inputRef.current) {
        inputRef.current.value = ''
        inputRef.current.focus()
      }
    }
  }))

  return (
    <SearchForm
      {...rest}
      resultsComponent={resultsComponent}
      createComponent={IngredientForm}
      placeholder={tr('models.meals.ingredientId')}
      alwaysResults={alwaysResults}
      ref={inputReffer}
    />
  )
}

BuyableSearchForm = forwardRef(BuyableSearchForm)
