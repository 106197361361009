import { useState, useRef } from 'react'
import classnames from 'classnames'

import {
  Modal,
  Button,
  ToggleButton,
  Table
} from '../../ui/bootstrap/bootstrap'
import { SearchForm } from '../../ui/utils/SearchForm'

import { tr } from 'react-hook-form-auto'

import { ensureArray, ButtonConfig, ImageShow } from '../../pintor'

import { CrudForm } from './CrudForm'
import { createComponentedQuerier } from './search/CrudResults'

import { ViewChooser } from './ViewChooser'
import { CrudInfoTable } from './CrudInfoTable'

import config from '../../clientConfig'

import styles from './CrudView.sass'

const ListWrapper = ({ children }) =>
  <Table className="doc-view-list">
    {children}
  </Table>

export function CrudView(props) {
  // FIXME Reducer, I know
  const [ viewMode, setViewMode ] = useState(props.initialViewMode || 'normal')
  const [ seeEveryones, setSeeEveryones ] = useState(false)
  const [ createActive, setCreateActive ] = useState(false)
  const [ editActive, setEditActive ] = useState(false)
  const [ editingDoc, setEditingDoc ] = useState({})
  const [ showingDoc, setShowingDoc ] = useState(null)
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  const [ addedFiles, setAddedFiles ] = useState([])
  const [ losingPics, setLosingPics ] = useState([])
  const [ page, setPage ] = useState(0)

  const searchForm = useRef(null)

  const handleOpenCreate = () => {
    editCrud({})
  }

  // Doc onClick. If it's add we open the modal directly
  const handleClick = props => {
    if (props.isAdd)
      handleOpenCreate()
    else
      toggleInfo(props.doc)
  }

  const listEntry = (listProps) => {
    const small = isSmall()

    const $entryComponent = isList() ?
      CrudInfoTable : props.tableComponent
    const buttons = getButtonsInfo()

    return (
      <$entryComponent
        {...listProps}
        buttons={buttons}
        small={small}
        onClick={handleClick.bind(null, listProps)}
      />
    )
  }

  const isSmall = () => {
    return viewMode == 'small'
  }

  const isList = () => {
    return viewMode == 'list'
  }

  const getButtonsInfo = () => {
    const { extraButtonsCreator } = props
    const extras = ensureArray(extraButtonsCreator)
    return extras.concat(defaultButtons())
  }

  const editCrud = (rawDoc) => {
    const doc = getDoc(rawDoc)
    const formName = docFormName(doc)

    setEditingDoc(doc)
    if (doc && doc.id)
      editToggle()
    else
      createToggle()
  }

  const toggleInfo = (doc) => {
    const newDoc = showingDoc ? null : doc
    setShowingDoc(newDoc)
  }

  const handleAddFile = (file) => {
    setAddedFiles([...addedFiles, file])
  }

  const addExtraButtons = (doc) => {
    if (props.extraButtons)
      return props.defaultButtons(doc)
    else
      return []
  }

  const buttonShowInfo = () => {
    return new ButtonConfig(doc => ({
      type: "default",
      icon: "info",
      onClick: toggleInfo.bind(null, doc),
      text: tr('crud.info')
    }))
  }

  const buttonDocEdit = () => {
      return new ButtonConfig(doc => {
        if (canEdit(doc)) {
          return {
            type: "default",
            icon: "edit",
            onClick: editCrud.bind(null, doc),
            text: tr('models.edit')
          }
        } else
          return null
      })
  }

  // Checking if there are new images.
  const handleEditClose = (submitting) => {
    // if (!submitting) {
    //   // FIXME I have to use meals form but
    //   // shouldn't it be meal-edit/meal-create
    //   const form = props.forms.meals
    //   if (form) {
    //     const { initial, values } = form
    //     const initialPics = initial.images || []
    //     const curPics = values.images || []

    //     const rest = arrayMinus(curPics, initialPics)
    //     if (rest.length > 0) {
    //      
    //       setConfirmDelete(true)
    //       setLosingPics(rest)
    //     }
    //   }
    // } else
      closeModal()
  }

  const buttonDocRemove = (doc) => {
      return new ButtonConfig(doc => {
        if (canEdit(doc)) {
          return {
            type: "danger",
            icon: "trash",
            onClick: props.onDelete.bind(null, doc),
            text: tr('models.delete')
          }
        } else
          return null
      })
  }

  const canSee = (doc) => {
    return !props.canSee || props.canSee(doc)
  }

  const canEdit = (doc) => {
    return !props.canEdit || props.canEdit(doc)
  }

  const defaultButtons = () => {
    return [
      buttonShowInfo(),
      buttonDocEdit(),
      buttonDocRemove(),
    ]
  }

  const createToggle = () => {
    setCreateActive(!createActive)
  }

  const editToggle = () => {
    setEditActive(!editActive)
  }

  const closeModal = () => {
    setCreateActive(false)
    setEditActive(false)
    if (props.onCloseModal)
      props.onCloseModal()
  }

  const enableButton = () => {
    setCanSubmit(true)
  }

  const disableButton = () => {
    setCanSubmit(false)
  }

  const handleEditFromInfo = () => {
    setEditActive(true)
  }

  const docFormName = (doc = {}) => {
    if (doc.id)
      return `${props.name}-update`
    else
      return `${props.name}-create`
  }

  const showModalButtons = () => {
    return (
      <>
        <Button
          type="primary"
          text={tr('models.edit')}
          iconClass="edit"
          onClick={handleEditFromInfo}
          outline
        />
        <Button
          text={tr('modal.close')}
          iconClass="trash"
          onClick={toggleInfo}
          outline
        />
      </>
    )
  }

  const handleCloseConfirmation = () => {
    setConfirmDelete(false)
  }

  const handleCloseDeleting = () => {
    handleCloseConfirmation()
    closeModal()

    // meteorCall({
    //   method: 'deleteLeftovers',
    //   arg1: losingPics
    // })
  }

  const confirmDeleteButtons = () => {
    return (
      <div>
        <Button
          text={tr('shop.files.remove')}
          iconClass="trash"
          onClick={handleCloseDeleting}
          type="danger"
        />
        <Button
          text={tr('cancel')}
          iconClass="trash"
          onClick={handleCloseConfirmation}
        />
      </div>
    )
  }

  const getConfig = () => {
    return config.crud[viewMode]
  }

  const getPerPage = () => {
    const data = getConfig()
    if (data)
      return data.perPage
  }

  const getSide = () => {
    const data = getConfig()
    if (data)
      return data.side
  }

  const handleUnconfirm = () => {
    handleCloseForReal()
  }

  const renderLeftoverImages = () => {
    const leftovers = losingPics || []

    return leftovers.map(picId =>
      <ImageShow
        className={styles.pleaseDontDeleteMe}
        key={picId}
        id={picId}
        side={120}
      />
    )
  }

  const getDoc = (rawDoc) => {
    let doc = rawDoc || editingDoc || {}
    const { formConvert } = props.model
    if (formConvert)
      return formConvert(doc)
    else
      return doc
  }

  // render()
  const doc = getDoc()
  const form = docFormName(doc)

  const resultsComp = props.resultsComponent ||
    createComponentedQuerier(props.model)
  const $formComp = props.formComponent || CrudForm
  const listWrapper = isList() ?
    ListWrapper : (props.listWrapper || ListWrapper)
  const $info = props.infoComponent

  const perPage = getPerPage()

  return (
    <div>
      <SearchForm
        resultsComponent={resultsComp}
        createComponent={$formComp}
        model={props.model}
        onCreate={props.onSubmit}
        docComponent={listEntry}
        listWrapper={listWrapper}
        ref={searchForm}
        paginate
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        autoFocus
        onAddSelected={handleOpenCreate}
        seeEveryones={seeEveryones}
        {...props.searchFormProps}
      >
        <div className={styles.panel}>
          <div className={styles.panelItem}>
            <ViewChooser
              value={viewMode}
              onChange={setViewMode}
            />
          </div>
          <div className={classnames(styles.panelItem, styles.seeEveryones)}>
            <ToggleButton
              type="secondary"
              icon="users"
              value={seeEveryones}
              onChange={setSeeEveryones}
              text={tr('crud.seeEveryones')}
              outline
              small
            />
          </div>
        </div>
      </SearchForm>
      <$formComp
        onSubmit={props.onSubmit}
        onClose={handleEditClose}
        visible={createActive}
        form={`${props.name}-create`}
        name={props.name}
        model={props.model}
        doc={doc}
        {...props.autoformExtra}
      />
      <$formComp
        onSubmit={props.onSubmit}
        onClose={handleEditClose}
        visible={editActive}
        form={`${props.name}-update`}
        model={props.model}
        name={props.name}
        doc={doc}
        {...props.autoformExtra}
      />
      <Modal
        title={showingDoc && showingDoc.name}
        doc={showingDoc}
        visible={Boolean(showingDoc)}
        onClose={toggleInfo}
        buttons={showModalButtons()}
      >
        {showingDoc && <$info doc={showingDoc} />}
      </Modal>
      <Modal
        title={tr('warning')}
        visible={confirmDelete}
        onClose={handleUnconfirm}
        buttons={confirmDeleteButtons()}
      >
        {tr('shop.files.warnDelete')}
        {renderLeftoverImages()}
      </Modal>
    </div>
  )
}
