import PropTypes from 'prop-types'
import classnames from 'classnames'

export const Nav = ({
  className,
  children
}) =>
  <ul className={classnames('nav', className)}>
    {children}
  </ul>

Nav.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string
}
