import { forwardRef } from 'react';
import { useForm } from 'react-hook-form'

import {
  InputSearch
} from '../../components/searcher/InputSearch'

// This one is for general search not tied to a model
// Extra parameters will be passed to InputSearch
export let SearchForm = ({
  className,
  onChange,
  ...rest
}, ref) => {
  const { register, setValue } = useForm()

  return (
    <form
      className={className}
    >
      <InputSearch
        {...rest}
        name="ref"
        elementOnly
        setValue={setValue}
        register={register}
        inputRef={ref}
        cleanAfterSelect
      />
    </form>
  )
}

SearchForm = forwardRef(SearchForm)
