import { useRef } from 'react'
import { toast } from 'react-toastify'
import { useMutation as useApolloMutation } from '@apollo/client'
import { tr } from 'react-hook-form-auto'

export const useMutation = (query, {
  onCompleted,
  onError,
  successText,
  ...rest 
} = {}) => {
  const isAdd = useRef(false)
  const [ mutate, mutateState ] = useApolloMutation(query, {
    ...rest,
    onCompleted: (data) => {
      const operations = Object.keys(data)
      const toastOptions = {
        type: 'success',
        hideProgressBar: true
      }

      if (successText) {
        toast(successText, toastOptions)
      } else if (operations && operations.length > 0) {
        const op = operations[0]
        const add = isAdd.current ? 'add' : 'update'
        const text = tr(`mutations.${op}.${add}`, {
          name: data[op]?.name || ''
        })

        toast(text, toastOptions)
      }

      if (onCompleted)
        onCompleted(data)
    },
    onError: (error) => {
      console.log("Error while mutating", error)

      const { graphQLErrors } = error
      if (graphQLErrors) {
        graphQLErrors.forEach(err => {
          toast(tr(err.message), {
            type: 'error'
          })
        })
      }
    }
  })

  const mutateSpy = (options = {}) => {
    const { variables } = options
    isAdd.current = variables && variables.input && !variables.input.id
    return mutate(options)
  }

  return [ mutateSpy, mutateState ]
}
