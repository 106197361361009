.nav {
  background-color: #ff7f50;
}
.nav.selected {
  background-color: #ffa483;
  font-weight: 600;
}

.add {
  background-color: #686;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvZGlldHMiLCJzb3VyY2VzIjpbIkRpZXRzTmF2LnNhc3MiLCIuLi8uLi91aS9zaG9wLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBS0E7RUFDRSxrQkFKTTs7QUFLTjtFQUNFLGtCQUxPO0VBTVA7OztBQUNKO0VBQ0Usa0JDVFciLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi91aS9zaG9wJ1xuXG4kY29sb3I6ICNmZjdmNTBcbiRjb2xvci1oaTogbGlnaHRlbigkY29sb3IsIDEwJSlcblxuLm5hdlxuICBiYWNrZ3JvdW5kLWNvbG9yOiAkY29sb3JcbiAgJi5zZWxlY3RlZFxuICAgIGJhY2tncm91bmQtY29sb3I6ICRjb2xvci1oaVxuICAgIGZvbnQtd2VpZ2h0OiA2MDBcbi5hZGRcbiAgYmFja2dyb3VuZC1jb2xvcjogJHBsdXMtY29sb3JcbiIsIiRtZWFsLWNvbG9yOiAjODY2XG4kaW5ncmVkaWVudC1jb2xvcjogIzk5NFxuJHBsdXMtY29sb3I6ICM2ODZcbiRncm91cC1jb2xvcjogIzY4OFxuXG4kaGlnaGxpZ2h0LWJhY2tncm91bmQ6ICM0Y2FjY2NcbiRoaWdobGlnaHQtY29sb3I6ICNmOGZmZmZcblxuJG1lYWwtY29sb3ItZGFyazogZGFya2VuKCRtZWFsLWNvbG9yLCAzMCUpXG4kaW5ncmVkaWVudC1jb2xvci1kYXJrOiBkYXJrZW4oJGluZ3JlZGllbnQtY29sb3IsIDE1JSlcbiRwbHVzLWNvbG9yLWRhcms6IGRhcmtlbigkcGx1cy1jb2xvciwgMTUlKVxuJGdyb3VwLWNvbG9yLWRhcms6IGRhcmtlbigkZ3JvdXAtY29sb3IsIDMwJSlcblxuJG1lYWwtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJG1lYWwtY29sb3IsIDMwJSlcbiRpbmdyZWRpZW50LWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRpbmdyZWRpZW50LWNvbG9yLCAzMCUpXG4kcGx1cy1jb2xvci1saWdodDogbGlnaHRlbigkcGx1cy1jb2xvciwgNDAlKVxuJGdyb3VwLWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRncm91cC1jb2xvciwgMzAlKVxuXG4kdGFnLWNvbG9yLW1hdGNoOiAjMTAxMDYwXG5cbiRjbGlja2FibGUtaGVpZ2h0OiA0OHB4XG5cbj1uYXZcbiAgZGlzcGxheTogYmxvY2tcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lXG4iXX0= */