import React, { useReducer } from 'react'
import { useCrudQuery } from './crudQuerier'
import { CrudView } from './CrudView'

const DEFAULT_PER_PAGE = 20

const initialState = {
  search: '',
  page: 0
}

function reducer(state, action) {
  switch (action.type) {
  case 'search':
    return {
      ...state,
      search: action.search,
      page: 0
    }
  case 'setPage':
    return {
      ...state,
      page: action.page
    }
  default:
    return state
  }
}

// ...rest will be passed to useCrudQuery
export const CrudContainer = ({
  model,
  resultsComponent,
  editComponent,
  searchFormComponent,
  searchFormProps,
  resultsProps,
  children,
  perPage = DEFAULT_PER_PAGE,
  ...rest
}) => {
  const [ state, dispatch ] = useReducer(reducer, initialState)
  const {
    collection,
    sub,
    change,
    count,
    loading
  } = useCrudQuery({
    search: state.search,
    page: state.page,
    perPage,
    model,
    ...rest
  })

  function handleSearching(e) {
    dispatch({
      type: 'search',
      search: e.target.value
    })
  }

  function handlePageChange(page) {
    dispatch({
      type: 'setPage',
      page
    })
  }

  function handleDocChange(doc) {
    change({
      variables: {
        input: doc
      }
    })
  }

  return (
    <CrudView
      model={model}
      collection={collection}
      loading={loading}
      sub={sub}
      count={count}
      searching={state.search}
      onSearchChange={handleSearching}
      onPageChange={handlePageChange}
      resultsComponent={resultsComponent}
      searchFormComponent={searchFormComponent}
      editComponent={editComponent}
      onDocChange={handleDocChange}
      children={children}
      searchFormProps={searchFormProps}
      resultsProps={resultsProps}
    />
  )
}
