import { createIfMissing } from '../../pintor'

export function postsNested(thread) {
  const { posts } = thread

  let childrenById = {}
  return posts.reduce((result, post) => {
    let childrenSlot = createIfMissing(childrenById, post.id, [])
    // Some shallow cloning
    let newElement = {
      ...post,
      children: childrenSlot
    }

    if (post.parentPostId) {
      const parentChildren = createIfMissing(
        childrenById,
        post.parentPostId,
        []
      )

      const insertIdx = parentChildren.reduce((insertIdx, child, idx) => {
        return child.points < post.points ? idx : insertIdx
      }, parentChildren.length)

      parentChildren.splice(insertIdx, 0, newElement)
    } else
      result.push(newElement)

    return result
  }, [])
}
