import { cloneElement } from 'react';
import classnames from 'classnames'

import { buttonFromConfig } from './Button'

export const ButtonGroup = ({ children }) =>
  <div className="btn-group" role="group">
    {children}
  </div>

const buttonMap = (doc, buttons, options) =>
  buttons.map((button, idx) => {
    const cfg = button.createFromDoc(doc)

    const component = buttonFromConfig(cfg, idx)
    const compClasses = component.props.className
    const newClasses = classnames(
      compClasses,
      { active: options.selectedIdx == idx },
      options.buttonClass
    )

    return cloneElement(component, { className: newClasses })
  })

export const buttonGroupFromList = (doc, buttons, options = {}) => {
  return (
    <ButtonGroup className={options.className}>
      {buttonMap(doc, buttons, options)}
    </ButtonGroup>
  )
}
