import { useSubscription } from '@apollo/client'
import gql from 'graphql-tag'
import { createDeleteUpdater, createUpdater } from '../pintor'

const orderFragment = gql`
  fragment OrderFragment on Order {
    id
    meal {
      id
      name
    }
    createdAt
    updatedAt
  }
`

const ORDERS_GET = gql`
  query OrdersList {
    orders {
      ...OrderFragment
    }
  }
  ${orderFragment}
`

const ORDERS_SUB = gql`
  subscription OrderUpdate {
    orderChange {
      ...OrderFragment
    }
  }
  ${orderFragment}
`

const ORDERS_COOK_SUB = gql`
  subscription OrderCookUpdate {
    orderCookChange
  }
`

const ORDERS_COUNT = gql`
  query OrderCount($search: String) {
    count: ordersCount(search: $search)
  }
`

const ORDER_GET = gql`
  query OrderGet($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
  ${orderFragment}
`

const ORDER_COOK = gql`
  mutation OrderCook($id: ID!) {
    cook(id: $id)
  }
`


function formConvert(doc) {
  const images = doc.images || []
  const ingredientList = doc.ingredientList || []

  return {
    ...doc,
    imageIds: images.map(image => image.id),
    ingredientList: ingredientList.map(entry => {
      const { ingredient } = entry

      return {
        ...entry,
        ref: {
          id: ingredient.id,
          type: 'ingredient',
          search: ingredient.name
        }
      }
    })
  }
}

const dataUpdater = createUpdater({
  query: ORDERS_GET,
  subscriptionName: 'orderChange',
  collectionKey: 'orders'
})

const useOrderUpdate = () => {
  const { data } = useSubscription(Order.sub, {
    onData: dataUpdater
  })

  const orderChange = data && data.orderChange

  return { orderChange }
}

const cookUpdater = createDeleteUpdater({
  query: ORDERS_GET,
  subscriptionName: 'orderCookChange',
  collectionKey: 'orders',
  typename: 'Order'
})

const useOrderCookUpdate = () => {
  const { data } = useSubscription(ORDERS_COOK_SUB, {
    onData: cookUpdater
  })

  const orderCookChange = data && data.orderCookChange

  return { orderCookChange }
}

export const Order = {
  name: 'order',
  fetch: ORDERS_GET,
  sub: ORDERS_SUB,
  count: ORDERS_COUNT,
  get: ORDER_GET,
  methods: {
    cook: ORDER_COOK
  },
  fragment: orderFragment,
  formConvert,
  useOrderUpdate,
  useOrderCookUpdate
}
