import { PureComponent } from 'react';
import classnames from 'classnames'

import {
  FlexList,
  FlexItem,
  FlexContent
} from '../../components/FlexList'
import { objectMap } from '../../pintor'
import { Badge } from '../../ui/bootstrap/bootstrap'

import styles from './GroupChooser.sass'

export class GroupChooser extends PureComponent {
  constructor(props) {
    super(props)
  }

  renderList() {
    const { groupData, selected, onSelect } = this.props

    if (groupData) {
      return objectMap(groupData, (buys, name) => {
        if (buys && buys.length > 0) {
          const itemClasses = classnames(
            styles.element,
            { [styles.selected]: name == selected }
          )

          return (
            <FlexItem
              key={name}
              className={itemClasses}
              onClick={onSelect.bind(null, name)}
            >
              <FlexContent>
                <div key="name" className={styles.name}>
                  {name}
                </div>
                <div key="notes" className={styles.notes}>
                  <Badge type="light" className={styles.badge}>
                    {buys.length}
                  </Badge>
                </div>
              </FlexContent>
            </FlexItem>
          )
        }
      })
    } else
      return null
  }

  render() {
    return (
      <FlexList>
        {this.renderList()}
      </FlexList>
    )
  }
}
