.element {
  background-color: #bbcccc;
}
.element .name {
  text-align: left;
}
.element .notes {
  text-align: right;
  padding-right: 5px;
}

.selected {
  background-color: #d8e2e2;
}

.badge {
  background-color: #8fabab;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiR3JvdXBDaG9vc2VyLnNhc3MiLCIuLi8uLi91aS9zaG9wLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUE7RUFDRSxrQkNXa0I7O0FEVmxCO0VBQ0U7O0FBQ0Y7RUFDRTtFQUNBOzs7QUFFSjtFQUNFOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnLi4vLi4vdWkvc2hvcCdcblxuJHRleHQtcGFkZGluZzogNHB4XG5cbi5lbGVtZW50XG4gIGJhY2tncm91bmQtY29sb3I6ICRncm91cC1jb2xvci1saWdodFxuICAubmFtZVxuICAgIHRleHQtYWxpZ246IGxlZnRcbiAgLm5vdGVzXG4gICAgdGV4dC1hbGlnbjogcmlnaHRcbiAgICBwYWRkaW5nLXJpZ2h0OiAkdGV4dC1wYWRkaW5nICsgMVxuXG4uc2VsZWN0ZWRcbiAgYmFja2dyb3VuZC1jb2xvcjogbGlnaHRlbigkZ3JvdXAtY29sb3ItbGlnaHQsIDEwJSlcblxuLmJhZGdlXG4gIGJhY2tncm91bmQtY29sb3I6IGRhcmtlbigkZ3JvdXAtY29sb3ItbGlnaHQsIDE1JSlcbiIsIiRtZWFsLWNvbG9yOiAjODY2XG4kaW5ncmVkaWVudC1jb2xvcjogIzk5NFxuJHBsdXMtY29sb3I6ICM2ODZcbiRncm91cC1jb2xvcjogIzY4OFxuXG4kaGlnaGxpZ2h0LWJhY2tncm91bmQ6ICM0Y2FjY2NcbiRoaWdobGlnaHQtY29sb3I6ICNmOGZmZmZcblxuJG1lYWwtY29sb3ItZGFyazogZGFya2VuKCRtZWFsLWNvbG9yLCAzMCUpXG4kaW5ncmVkaWVudC1jb2xvci1kYXJrOiBkYXJrZW4oJGluZ3JlZGllbnQtY29sb3IsIDE1JSlcbiRwbHVzLWNvbG9yLWRhcms6IGRhcmtlbigkcGx1cy1jb2xvciwgMTUlKVxuJGdyb3VwLWNvbG9yLWRhcms6IGRhcmtlbigkZ3JvdXAtY29sb3IsIDMwJSlcblxuJG1lYWwtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJG1lYWwtY29sb3IsIDMwJSlcbiRpbmdyZWRpZW50LWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRpbmdyZWRpZW50LWNvbG9yLCAzMCUpXG4kcGx1cy1jb2xvci1saWdodDogbGlnaHRlbigkcGx1cy1jb2xvciwgNDAlKVxuJGdyb3VwLWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRncm91cC1jb2xvciwgMzAlKVxuXG4kdGFnLWNvbG9yLW1hdGNoOiAjMTAxMDYwXG5cbiRjbGlja2FibGUtaGVpZ2h0OiA0OHB4XG5cbj1uYXZcbiAgZGlzcGxheTogYmxvY2tcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lXG4iXX0= */