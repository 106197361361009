import { IngredientTagFlex } from '../ingredients/IngredientTagFlex.jsx'
import { IngredientAmounts } from '../ingredients/IngredientAmounts.jsx'
import { Icon } from '../../ui/bootstrap/bootstrap.js'
import { MealTag } from '../meals/MealTag'
import classnames from 'classnames'

import styles from './BuyableSearchResult.sass'

const Checkout = ({ className, buy }) => {
  if (buy) {
    const classes = classnames(styles.resultIngredient, className)

    return (
      <div className={classes}>
        <Icon className={styles.icon} icon="check-circle" />
        <IngredientAmounts
          buy={buy}
          amountClassname={styles.amount}
        />
      </div>
    )
  } else
    return null
}

export const BuyableSearchResult = ({
  buysByIngredientId,
  doc = {},
  ...rest
}) => {
  const id = doc ? doc.id : null
  const type = doc.__typename
  const isIngredient = type == 'Ingredient'
  const buy = id && isIngredient ? buysByIngredientId[id] : null

  switch (type) {
  case 'Meal':
    return <MealTag doc={doc} {...rest} />
  case 'Ingredient':
  default:
    return (
      <IngredientTagFlex
        {...rest}
        doc={doc}
      >
        <Checkout buy={buy} />
      </IngredientTagFlex>
    )
  }
}
