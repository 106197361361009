import { usePath, usePathParams, useRoutes } from 'raviger'

import { MainMenu } from './components/MainMenu'
import { useUser } from './pintor'

import { LoginScreen } from './views/login/LoginScreen'
import { ViewTransition } from './ui/transitions/ViewTransition'

import GWShopView from './GWShopView'
import GWIngredientsView from './GWIngredientsView'
import GWMealView from './GWMealView'
import GWMealsView from './GWMealsView'
import GWOrdersContainer from './GWOrdersContainer'
import GWDietsContainer from './GWDietsContainer'
import GWGroupsContainer from './GWGroupsContainer'
import GWHistoryContainer from './GWHistoryContainer'
import GWProfileContainer from './GWProfileContainer'
import { ToastContainer } from 'react-toastify'

// const routes = {
//   '/': 'GWShopView',
//   '/login': 'views/login/LoginScreen',
//   '/ingredients': 'GWIngredientsView',
//   '/meal/:id': 'GWMealView',
//   '/meals': 'GWMealsView',
//   '/shop': 'GWShopView',
//   '/orders': 'GWOrdersContainer',
//   '/diets/:id': 'GWDietsContainer',
//   '/diets': 'GWDietsContainer',
//   '/groups': 'GWGroupsContainer',
//   '/history': 'GWHistoryContainer',
//   '/profile': 'GWProfileContainer'
// }
const routes = {
  '/': GWShopView,
  '/login': LoginScreen,
  '/ingredients': GWIngredientsView,
  '/meal/:id': GWMealView,
  '/meals': GWMealsView,
  '/shop': GWShopView,
  '/orders': GWOrdersContainer,
  '/diets/:id': GWDietsContainer,
  '/diets': GWDietsContainer,
  '/groups': GWGroupsContainer,
  '/history': GWHistoryContainer,
  '/profile': GWProfileContainer
}

const publicRoutes = [ '/meal/' ]

const equivalences = {
  '/': '/shop'
}

export function routesToExpress(app, render) {
  const paths = Object.keys(routes)

  for (const path of paths) {
    console.log("CATHY, la reina del saloon", path)
    // const gateway = routes[path]
    app.get(path, (req, res) => render(req, res, async () => {
      // const Page = await import(`./${gateway}.jsx`)
      const Page = routes[path]
      console.log('PAGEEEEEEEEEEE', path, Page)

      return (
        <Routes path={path}>
          <Page {...req.params} />
        </Routes>
      )
    }))
  }
}

// My own fucking routing shit
export function useFuckingRoutes(routes, path) {
  const inputPartition = path.split('/').filter(e => e)
  const entries = Object.keys(routes)
  return entries.reduce((acc, cur) => {
    const candidatePartition = cur.split('/').filter(e => e)

    const sameLength = candidatePartition.length == inputPartition.length
    if (!acc.component && sameLength) {
      let match = true
      let params = {}
      candidatePartition.forEach((piece, index) => {
        const inputPiece = inputPartition[index]
        if (piece[0] == ':') {
          const param = piece.slice(1)
          params[param] = inputPiece
        } else {
          if (piece != inputPiece)
            match = false
        }
      })

      if (match && !acc.component) {
        const Component = routes[cur]
        acc.component = <Component {...params} />
        acc.path = cur
      }
    }

    return acc
  }, {})
}

export const Routes = ({ forcePath }) => {
  const { currentUser } = useUser()

  const routerPath = usePath()
  const path = forcePath || routerPath
  const { component } = useFuckingRoutes(routes, path)

  const isPublic = publicRoutes.reduce((isPublic, cur) =>
    path.indexOf(cur) == 0 ? true : isPublic
  , false)

  if (isPublic) {
    return (
      <>{component}</>
    )
  } else if (currentUser) {
    return (
      <>
        <MainMenu
          page={path}
          equivalences={equivalences}
        />
        <ViewTransition viewKey={path}>
          {component}
        </ViewTransition>
        <ToastContainer />
      </>
    )
  } else
    return <LoginScreen />
}
