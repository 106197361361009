.icon {
  display: inline-block;
  text-align: center;
  min-width: 20px;
  padding-right: 2px;
}

.hasText {
  margin-right: 6px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdWkvYm9vdHN0cmFwIiwic291cmNlcyI6WyJJY29uLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBQ0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5pY29uXG4gIGRpc3BsYXk6IGlubGluZS1ibG9ja1xuICB0ZXh0LWFsaWduOiBjZW50ZXJcbiAgbWluLXdpZHRoOiAyMHB4XG4gIHBhZGRpbmctcmlnaHQ6IDJweFxuLmhhc1RleHRcbiAgbWFyZ2luLXJpZ2h0OiA2cHggXG4iXX0= */