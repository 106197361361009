import PropTypes from 'prop-types'

import { Nav, NavItem } from './bootstrap'

const renderMenuTabs = ({ menu }) =>
  Object.keys(menu).map((key) => {
    const entry = menu[key]

    return (
      <NavItem key={key} index={key} dontWrap>
        { JSON.stringify(entry) }
      </NavItem>
    )
  })

const getClasses = ({ className }) =>
  className || "navbar-nav"

export const NavMenu = ({
  selected,
  menu,
  onChange,
  className
}) =>
  <Nav
    className={getClasses({ className })}
    selected={selected}
    onChange={onChange}
  >
    {renderMenuTabs({ menu })}
  </Nav>

NavMenu.propTypes = {
  selected: PropTypes.string.isRequired,
  menu: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string
}
