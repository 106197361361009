import { tr } from 'react-hook-form-auto'
import { Table, Button } from '../../ui/bootstrap/bootstrap'
import { renderDate } from '../../pintor'

const renderActions = ({ onCook, order }) =>
  <Button
    type="primary"
    iconClass="fire"
    text={tr('shop.cooked')}
    onClick={onCook.bind(null, order.id)}
  />

const renderOrders = ({ onCook, orders }) =>
  orders.map(order =>
    <tr key={order.id}>
      <td key="name">{order.meal && order.meal.name}</td>
      <td key="date">{renderDate(order.createdAt)}</td>
      <td key="buy">{renderActions({ onCook, order })}</td>
    </tr>
  )

export const OrdersView = ({
  orders,
  onCook
}) => {
  if (orders && orders.length > 0) {
    return (
      <Table>
        {renderOrders({ onCook, orders })}
      </Table>
    )
  } else
    return null
}
