.meal {
  background-color: #ccbbbb;
}
.meal .icon {
  color: #312424;
}
.meal.selected {
  background-color: #e2d8d8;
}

.add {
  background-color: #ada;
}
.add .icon {
  color: #484;
}
.add.selected {
  background-color: #beb;
}
.add .name {
  margin-right: 8px;
}

.match {
  color: #f8f5f5;
  background-color: #a08181;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvbWVhbHMiLCJzb3VyY2VzIjpbIk1lYWxUYWcuc2FzcyIsIi4uLy4uL3VpL3Nob3Auc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGtCQ1VpQjs7QURUakI7RUFDRSxPQ0djOztBREZoQjtFQUNFOzs7QUFDSjtFQUNFOztBQUNBO0VBQ0U7O0FBQ0Y7RUFDRTs7QUFDRjtFQUNFOzs7QUFDSjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi91aS9zaG9wJ1xuXG4ubWVhbFxuICBiYWNrZ3JvdW5kLWNvbG9yOiAkbWVhbC1jb2xvci1saWdodFxuICAuaWNvblxuICAgIGNvbG9yOiAkbWVhbC1jb2xvci1kYXJrXG4gICYuc2VsZWN0ZWRcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiBsaWdodGVuKCRtZWFsLWNvbG9yLWxpZ2h0LCAxMCUpXG4uYWRkXG4gIGJhY2tncm91bmQtY29sb3I6ICNhZGFcbiAgLmljb25cbiAgICBjb2xvcjogIzQ4NFxuICAmLnNlbGVjdGVkXG4gICAgYmFja2dyb3VuZC1jb2xvcjogI2JlYlxuICAubmFtZVxuICAgIG1hcmdpbi1yaWdodDogOHB4XG4ubWF0Y2hcbiAgY29sb3I6IGxpZ2h0ZW4oJG1lYWwtY29sb3ItbGlnaHQsIDIwJSlcbiAgYmFja2dyb3VuZC1jb2xvcjogbGlnaHRlbigkbWVhbC1jb2xvciwgMTAlKVxuIiwiJG1lYWwtY29sb3I6ICM4NjZcbiRpbmdyZWRpZW50LWNvbG9yOiAjOTk0XG4kcGx1cy1jb2xvcjogIzY4NlxuJGdyb3VwLWNvbG9yOiAjNjg4XG5cbiRoaWdobGlnaHQtYmFja2dyb3VuZDogIzRjYWNjY1xuJGhpZ2hsaWdodC1jb2xvcjogI2Y4ZmZmZlxuXG4kbWVhbC1jb2xvci1kYXJrOiBkYXJrZW4oJG1lYWwtY29sb3IsIDMwJSlcbiRpbmdyZWRpZW50LWNvbG9yLWRhcms6IGRhcmtlbigkaW5ncmVkaWVudC1jb2xvciwgMTUlKVxuJHBsdXMtY29sb3ItZGFyazogZGFya2VuKCRwbHVzLWNvbG9yLCAxNSUpXG4kZ3JvdXAtY29sb3ItZGFyazogZGFya2VuKCRncm91cC1jb2xvciwgMzAlKVxuXG4kbWVhbC1jb2xvci1saWdodDogbGlnaHRlbigkbWVhbC1jb2xvciwgMzAlKVxuJGluZ3JlZGllbnQtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJGluZ3JlZGllbnQtY29sb3IsIDMwJSlcbiRwbHVzLWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRwbHVzLWNvbG9yLCA0MCUpXG4kZ3JvdXAtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJGdyb3VwLWNvbG9yLCAzMCUpXG5cbiR0YWctY29sb3ItbWF0Y2g6ICMxMDEwNjBcblxuJGNsaWNrYWJsZS1oZWlnaHQ6IDQ4cHhcblxuPW5hdlxuICBkaXNwbGF5OiBibG9ja1xuICB0ZXh0LWRlY29yYXRpb246IG5vbmVcbiJdfQ== */