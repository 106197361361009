import classnames from 'classnames'

import styles from './HistoryView.sass'

const renderRows = (history = []) =>
  history.map(({ id, createdAt, ingredient: { name }, added }) => {
    const trClasses = classnames(styles.tr, {
      [styles.added]: added,
      [styles.removed]: !added
    })
    const date = new Date(createdAt)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    const fDate = `${day}/${month}/${year}`

    return (
      <tr key={id} className={trClasses}>
        <td key="date">
          {fDate}
        </td>
        <td key="ingredient">
          {name}
        </td>
      </tr>
    )
  })

export const HistoryView = ({ history }) =>
  <table className={styles.table}>
    <tbody>
      {renderRows(history)}
    </tbody>
  </table>
