import { cloneElement } from 'react';

/**
 * Children are selectable. They should have an
 * index and this will call nav's handleChange
 * prop on click.
 */
export const onChildClick = (parent, index) => {
  if (parent.handleChange)
    parent.handleChange(index)
  if (parent.props.onClick)
    parent.props.onClick()
}

/**
 * Makes children selectable.
 *
 * \param parent {Component} Container
 * \param children {array} (optional) children override.
 */
export const makeChildrenSelectable = (parent, children) => {
  const list = children || parent.props.children
  return list.map((child) => {
    return cloneElement(child, {
      onClick: onChildClick.bind(this, parent, child.props.index),
      selected: parent.props.selected == child.props.index
    })
  })
}
