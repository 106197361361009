.base {
  perspective: 5000px;
}

.appear, .enter {
  opacity: 0;
  transform: translate(0, 10px) rotateX(10deg);
}

.appearActive, .enterActive {
  opacity: 1;
  transform: translate(0, 0) rotateX(0);
  transition: all 0.3s ease-in-out;
}

.exit {
  opacity: 1;
  transform: translate(0, 0) rotateX(0);
}

.exitActive {
  opacity: 0;
  transform: translate(0, -10) rotateX(-10deg);
  transition: 0.15s ease-in-out;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdWkvdHJhbnNpdGlvbnMiLCJzb3VyY2VzIjpbInRyYW5zaXRpb25zLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7O0FBQ0Y7RUFDRTtFQUNBOzs7QUFDRjtFQUNFO0VBQ0E7RUFDQTs7O0FBQ0Y7RUFDRTtFQUNBOzs7QUFDRjtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5iYXNlXG4gIHBlcnNwZWN0aXZlOiA1MDAwcHhcbi5hcHBlYXIsIC5lbnRlclxuICBvcGFjaXR5OiAwXG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIDEwcHgpIHJvdGF0ZVgoMTBkZWcpXG4uYXBwZWFyQWN0aXZlLCAuZW50ZXJBY3RpdmVcbiAgb3BhY2l0eTogMVxuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgwLCAwKSByb3RhdGVYKDApXG4gIHRyYW5zaXRpb246IGFsbCAwLjNzIGVhc2UtaW4tb3V0XG4uZXhpdFxuICBvcGFjaXR5OiAxXG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIDApIHJvdGF0ZVgoMClcbi5leGl0QWN0aXZlXG4gIG9wYWNpdHk6IDBcbiAgdHJhbnNmb3JtOiB0cmFuc2xhdGUoMCwgLTEwKSByb3RhdGVYKC0xMGRlZylcbiAgdHJhbnNpdGlvbjogMC4xNXMgZWFzZS1pbi1vdXRcbiJdfQ== */