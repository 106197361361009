import { useState } from 'react'

import { tr } from 'react-hook-form-auto'

import { Meal } from '../../models/meal'
import { Buy } from '../../models/buy'
import { MealForm } from './MealForm'

import { useUser, ButtonConfig } from '../../pintor'

import { MealInfo } from './MealInfo'
import { MealCard } from './MealCard'

import { CrudView } from '../../components/crud/CrudView'
import { RecipeEditorModal } from '../meals/RecipeEditorModal'

import styles from './MealsView.sass'

const MealsListWrapper = ({ children }) =>
  <div className={styles.list}>
    {children}
  </div>

export const MealsView = () => {
  const [ editingRecipe, setEditingRecipe ] = useState(null)
  const { currentUser } = useUser()
  const [ mutate ] = Meal.useUpdate()
  const [ mealBuyAdd ] = Buy.useBuyAdd()
  Buy.useBuyUpdate()

  const buyMeal = (meal) => {
    mealBuyAdd({
      variables: {
        input: {
          type: 'meal',
          id: meal.id,
          unit: 'u',
          amount: 1
        }
      }
    })
  }

  const handleDeleteMeal = (doc) => {
    const id = doc.id

    mealRemove(id)
  }

  const handleSubmit = (meal) => {
    mutate(Meal.mutationConvert(meal))
  }

  const mealIsNotMine = (meal) => {
    return meal && meal.author != currentUser.id
  }

  const handleEditRecipe = (meal) => {
    setEditingRecipe(meal)
  }

  const handleRecipeClose = () => {
    setEditingRecipe(false)
  }

  const extraButtonsConfig = () => {
    return [
      new ButtonConfig(doc => ({
        type: "primary",
        icon: "shopping-cart",
        onClick: buyMeal.bind(null, doc),
        text: tr('shop.buy')
      })),
      new ButtonConfig(doc => ({
        icon: "edit",
        onClick: handleEditRecipe.bind(null, doc),
        text: tr('shop.recipes.edit')
      }))
    ]
  }

  // canEdit = doc => canEditPersonal(doc, currentUser, 'author')
  const canEdit = doc => true

  // render()
  return (
    <div>
      <CrudView
        model={Meal}
        searchForm="meal-search"
        name="meal"
        onSubmit={handleSubmit}
        onDelete={handleDeleteMeal}
        infoComponent={MealInfo}
        listWrapper={MealsListWrapper}
        tableComponent={MealCard}
        createComponent={MealForm}
        formComponent={MealForm}
        extraButtonsCreator={extraButtonsConfig()}
        searchFormProps={{ alwaysResults: true }}
        initialViewMode="small"
        canEdit={canEdit}
      />
      <RecipeEditorModal
        meal={editingRecipe}
        visible={editingRecipe}
        onClose={handleRecipeClose}
      />
    </div>
  )
}
