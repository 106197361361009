import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  concat,
  split
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from '@apollo/client/link/error'
import apolloLogger from 'apollo-link-logger'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { createClient as createWsClient } from 'graphql-ws'
import config from './clientConfig'
import { setCookieKey, tokenGet } from './pintor'

const createAuthLink = token =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token || ''
    }
  }))

const getSSRLinks = ({ token, link }) => {
  const authLink = createAuthLink(token)

  return concat(authLink, link)
}

export const createClient = ({
  ssr,
  ssrLink,
  req,
  token,
  uri,
  cache,
  WS,
  wsUri,
  addClientContext,
  defaultOptions,
  skipLogging
} = {}) => {
  let useLink

  let httpOptions = {
    uri: uri,
    credentials: "same-origin",
    fetch,
  }
  if (ssr && req) {
    httpOptions.headers = {
      cookie: req.header('Cookie')
    }
  }

  if (ssr) {
    let link

    if (ssrLink)
      link = ssrLink
    else
      link = createHttpLink(httpOptions)

    useLink = getSSRLinks({ token, link })
    if (addClientContext) {
      const extraContext = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          ...addClientContext(headers)
        }
      }))

      useLink = concat(useLink, extraContext)
    }
  } else {
    let connectionParams = { authToken: token }
    if (addClientContext) {
      connectionParams = {
        ...connectionParams,
        ...addClientContext()
      }
    }

    const wsClient = new createWsClient({
      url: wsUri,
      connectionParams
    }, WS)

    const wsLink = new GraphQLWsLink(wsClient)
    const uploadLink = concat(
      createAuthLink(token),
      createUploadLink({
        ...httpOptions,
        headers: {'Apollo-Require-Preflight': true}
      })
    )

    useLink = split(({ query }) => {
      const { name: { value } } = getMainDefinition(query)

      return value == 'Upload'
    }, uploadLink, wsLink)
  }

  let link = skipLogging ? [] : [ apolloLogger ]
  link.push(
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((entry) => {
          const message = JSON.stringify(entry, null, '\t')

          console.error(
            `[GraphQL client error] ${message}`
          )
        })
      }
      if (networkError) {
        const structured = JSON.stringify(networkError)
        console.error(`[Network error in graphql client]: ${structured}`)
      }
    })
  )
  link.push(useLink)

  return new ApolloClient({
    ssrMode: ssr,
    link: ApolloLink.from(link),
    cache: cache || new InMemoryCache(),
    defaultOptions
  })
}

export function createApolloClient({
  ssr,
  ssrLink,
  uri,
  wsUri,
  addClientContext
}) {
  let cache = new InMemoryCache()
  if (SSR != 'true')
    cache = cache.restore(window.__APOLLO_STATE__)

  setCookieKey(config.cookieKey)
  const token = tokenGet()

  const client = createClient({
    ssr,
    ssrLink,
    uri,
    wsUri,
    cache,
    token,
    addClientContext
  })

  return { client }
}
