import classnames from 'classnames'

import styles from './Comments.sass'
import autoformStyles from '../../autoform/Autoform.module.sass'
import { Button } from '../../ui/bootstrap/bootstrap'

export const EditPostText = ({
  value,
  postText,
  onChange,
  onPost,
}) => {
  const postStyles = classnames(styles.inputPost, autoformStyles.textArea)

  return (
    <>
      <textarea
        value={value}
        onChange={onChange}
        className={postStyles}
        rows="4"
        autoFocus
      />
      <div className={styles.buttonRighter}>
        <Button
          className={styles.createButton}
          type="success"
          onClick={onPost}
          text={postText}
        />
      </div>
    </>
  )
}
