import { useState } from 'react'
import classnames from 'classnames'

import { tr } from 'react-hook-form-auto'

import { EditPostText } from './EditPostText'

import styles from './Comments.sass'
import autoformStyles from '../../autoform/Autoform.module.sass'

// Usual parent: CommentSection
export const CreateThread = ({
  onCreate
}) => {
  const [ post, setPost ] = useState('')
  const handleCreate = () => {
    onCreate({ post })
    setPost('')
  }
  const handleWritePost = (e) => {
    setPost(e.target.value)
  }

  const threadStyles = classnames(styles.inputTitle, autoformStyles.input)

  return (
    <div className={styles.comments}>
      <div className={styles.title}>
        {tr('threads.comments')}
      </div>
      <EditPostText
        value={post}
        onChange={handleWritePost}
        postText={tr('threads.new')}
        onPost={handleCreate}
      />
    </div>
  )
}

