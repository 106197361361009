.panel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.panel .panelItem {
  flex: 0 none;
}
.panel .seeEveryones {
  margin-left: 16px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvY29tcG9uZW50cy9jcnVkIiwic291cmNlcyI6WyJDcnVkVmlldy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTs7QUFDRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLnBhbmVsXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1mbG93OiByb3cgbm93cmFwXG4gIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydFxuICBhbGlnbi1pdGVtczogY2VudGVyXG4gIC5wYW5lbEl0ZW1cbiAgICBmbGV4OiAwIG5vbmVcbiAgLnNlZUV2ZXJ5b25lc1xuICAgIG1hcmdpbi1sZWZ0OiAxNnB4XG4iXX0= */