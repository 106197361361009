import { useState } from 'react';
import { tr } from 'react-hook-form-auto'

import { Icon } from '../../ui/bootstrap/bootstrap'

import { useJustBought } from './useJustBought'
import styles from './JustBoughtView.sass'

export const JustBoughtView = () => {
  const { justBought } = useJustBought()
  const [ visible, setVisible ] = useState(false)

  const renderRecents = list =>
    list.map(buy => {
      // const units = buy.history[0].amounts[0]
      // const handleClick = () => buyAdd({
      //   variables: {
      //     input: {
      //       type: 'ingredient',
      //       id: buy.ingredient.id,
      //       unit: units.unit,
      //       amount: units.amount
      //     }
      //   }
      // })
      //
      // ...and then
      //  <div className={styles.item} onClick={handleClick}>

      return (
        <div key={buy.id} className={styles.item}>
          {buy.ingredient.name}
        </div>
      )
    })

  return (
    <div className={styles.group}>
      <div
        className={styles.header}
        onClick={() => setVisible(!visible)}
      >
        <Icon
          icon="first-aid"
        />
        <span className={styles.text}>
          {tr('shop.justBought')}
        </span>
      </div>
      {visible &&
        <div className={styles.recent}>
          {renderRecents(justBought)}
        </div>
       || null
      }
    </div>
  )
}
