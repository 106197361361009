import PropTypes from 'prop-types'

import { tr } from 'react-hook-form-auto'
import { ModalAutoform } from '../../ui/bootstrap/bootstrap'

export const CrudForm = ({
  onClose,
  visible,
  model,
  doc,
  name,
  onSubmit,
  ...rest
}) => {
  if (model) {
    return (
      <ModalAutoform
        schema={model.schema}
        modalTitle={tr(`models.${name}.modal`)}
        initialValues={doc}
        onSubmit={onSubmit}
        onClose={onClose}
        visible={visible}
        {...rest}
      />
    )
  }
}

CrudForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  model: PropTypes.object.isRequired,
  doc: PropTypes.object,
  onSubmit: PropTypes.func
}

