import classnames from 'classnames'

const renderLabel = props => {
  const labelClasses = classnames(
    'control-label',
    {
      ['col-sm-3']: props.horizontal
    }
  )
  return (
    <label className={labelClasses} data-required={props.required}>
      {props.label}
    </label>
  )
}

const renderError = (message) => {
  if (message) {
    return (
      <span className="help-block">
        {message}
      </span>
    )
  } else
    return null
}

const renderElement = (props, wrapperClasses) =>
  <div className={wrapperClasses}>
    {props.children}
    {renderError(props.errorMessage)}
  </div>

export const ControlWrapper = props => {
  const label = props.label ? renderLabel(props) : null
  const wrapperClasses = props.wrapperClassName || classnames({
    'col-sm-8': props.horizontal
  })
  const groupClasses = classnames(
    {
      'form-group': !props.inline,
      'has-error': props.errorMessage
    }
  )

  const element = renderElement(props, wrapperClasses)

  if (props.elementOnly) {
    return element
  } else {
    return (
      <div className={groupClasses}>
        {label}
        {element}
      </div>
    )
  }
}
