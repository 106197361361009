import React from 'react'

import { docIsImage } from '../../models/image'
import { ImageShow } from './ImageShow'
import { ButtonConfig } from '../../utils/ButtonConfig'

import styles from './ImagesField.sass'

export const IndividualImage = ({
  file,
  files,
  side,
  forEdit,
  children,
  onRemove,
  onEdit,
  editText,
  removeText,
  Icon,
  DropdownMenu
}) => {
  const handleRemove = () => {
    if (onRemove)
      onRemove(file.id)
  }

  const handleEdit = () => {
    if (onEdit)
      onEdit(file)
  }

  const renderPlaceholder = (file) => {
    if (file && !docIsImage(file)) {
      const { name, path } = file

      return (
        <a href={path} className={styles.filePlaceholder} download>
          <Icon icon="save" />
          <div className={styles.fileName}>
            <span>{file.filename}</span>
          </div>
        </a>
      )
    }
  }

  const renderMenu = () => {
    const buttoner = [
      new ButtonConfig(() => ({
        icon: 'edit',
        text: editText,
        onClick: handleEdit
      })),
      new ButtonConfig(() => ({
        icon: 'trash',
        text: removeText,
        onClick: handleRemove,
        type: 'danger'
      })),
    ]

    return (
      <div className={styles.controls}>
        <DropdownMenu
          icon="cog"
          buttons={buttoner}
        />
      </div>
    )
  }

  // Render
  const someFile = files && files[0] || file

  return (
    <ImageShow
      file={someFile}
      className={styles.file}
      side={side || 180}
    >
      {renderPlaceholder(someFile)}
      {renderMenu()}
      {children}
    </ImageShow>
  )
}

