import { useQuery } from '@apollo/client'

import { MealsList } from './MealsList'
import { Meal } from '../../../models/meal'

const MealListConnector = ({
  search,
  ...rest
}) => {
  const { data, loading } = useQuery(Meal.search, {
    variables: { search }
  })
  const collection = data && data.collection

  if (collection && collection.length) {
    return (
      <MealsList
        search={search}
        meals={collection}
        {...rest}
      />
    )
  } else
    return <p>No tenemos ingredients</p>
}

export default MealListConnector
