import { useQuery } from '@apollo/client'

import { Meal } from '../../models/meal'
import { MealInfo } from './MealInfo'
import NotFound from '../../components/NotFound'

export const MealView = ({ id }) => {
  const { data, loading } = useQuery(Meal.get, {
    variables: { id: parseInt(id) }
  })

  const meal = data && data.meal

  if (meal) {
    return (
      <MealInfo doc={meal} />
    )
  } else {
    return (
      <NotFound />
    )
  }
}
