import { useState } from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu as BSDropdownMenu,
  DropdownItem
} from 'reactstrap'
import { Icon } from '../../ui/bootstrap/bootstrap'

import styles from './DropdownMenu.sass'

const renderItems = (doc, buttons) =>
  buttons.reduce((acc, button, idx) => {
    const buttonCfg = button.createFromDoc(doc)

    if (buttonCfg) {
      acc.push(
        <DropdownItem
          key={`${idx}`}
          onClick={buttonCfg.onClick}
        >
          <Icon
            className={styles[buttonCfg.type]}
            icon={buttonCfg.icon}
            text={buttonCfg.text}
          />
        </DropdownItem>
      )
    }

    return acc
  }, [])

export const DropdownMenu = ({
  className,
  icon = 'cog',
  caret = true,
  doc,
  buttons,
  size,
  direction
}) => {
  const [ visible, setVisible ] = useState(false)

  const handleToggle = () => {
    setVisible(!visible)
  }

  const title = <Icon icon={icon} />

  return (
    <ButtonDropdown
      isOpen={visible}
      toggle={handleToggle}
      className={className}
      size={size}
      direction={direction}
    >
      <DropdownToggle caret={caret}>
        {title}
      </DropdownToggle>
      <BSDropdownMenu>
        {renderItems(doc, buttons)}
      </BSDropdownMenu>
    </ButtonDropdown>
  )
}
