import { useReducer } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { IngredientGroup } from '../../models/ingredientGroup'

import { GroupsView } from './GroupsView'

function showMore(state, action) {
  switch (action.type) {
  case 'show_more':
    return {
      mores: {
        ...state.mores,
        [action.id]: true
      }
    }
  }
}

export const GroupsContainer = (props) => {
  const [ state, dispatch ] = useReducer(showMore, { mores: {} })
  const { mores } = state

  const { data, loading } = useQuery(IngredientGroup.fetch)
  const collection = data && data.collection

  IngredientGroup.useIngredientGroupUpdate()
  const [ groupUpdate ] = useMutation(IngredientGroup.update)

  const handleShowMore = id => {
    dispatch({
      type: 'show_more',
      id
    })
  }

  const handleGroupUpdate = doc => {
    groupUpdate({
      variables: {
        input: doc
      }
    })
  }

  if (!loading) {
    return (
      <GroupsView
        {...props}
        collection={collection}
        groupUpdate={handleGroupUpdate}
        onShowMore={handleShowMore}
        onGroupUpdate={handleGroupUpdate}
        showMore={mores}
      />
    )
  } else
    return null
}
