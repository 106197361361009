import uiConfig from './uiConfig.json'

export default {
  ...uiConfig,
  isProd: IS_PRODUCTION,
  isProdBundle: PRODUCTION_BUNDLE,
  graphQlUri: GRAPHQL_URI,
  graphQlWsUri: GRAPHQL_WS_URI,
  cookieKey: COOKIE_KEY,
  ssr: SSR == 'true',
}
