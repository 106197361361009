import React from 'react'
import classnames from 'classnames'

const NavItem = ({
  active,
  text,
  menuKey,
  onChange,
  itemClassName,
  selectedClassName
}) => {
  const classes = classnames(itemClassName, {
    [selectedClassName]: active
  })

  const onVeryChange = (e) => {
    e.preventDefault()
    onChange(menuKey)
  }

  return (
    <li className={classes} onClick={onVeryChange}>
      <a href="#">
        {text}
      </a>
    </li>
  )
}

/**
 * @param {array} menu Array with objects like this:
 *
 *    {
 *      key: 'main',
 *      label: 'main'
 *    }
 *
 *    The label will be passed through translator.
 */
export const Menu = ({
  options,
  value,
  onChange,
  className,
  itemClassName,
  selectedClassName,
  children
}) =>
  <ul className={className}>
    {
      options.map(({ key, label }) =>
        <NavItem
          key={key}
          menuKey={key}
          onChange={onChange}
          active={value == key}
          text={label}
          itemClassName={itemClassName}
          selectedClassName={selectedClassName}
        />
      )
    }
    {children}
  </ul>

