import { findObjectInCollection } from '../utils/utils'

// Returns new arr. Uses findObject
const findAndDelete = (data, collectionKey, id) => {
  const arr = data[collectionKey]
  const idx = findObjectInCollection(arr, id)
  return {
    ...data,
    [collectionKey]: [ ...arr.slice(0, idx), ...arr.slice(idx + 1) ]
  }
}

// Modifies arr. Uses findObject
const findAndUpdate = (arr, doc) => {
  const { id } = doc

  const idx = findObjectInCollection(arr, id)
  arr[idx] = doc
}

export const createDeleteUpdater = ({
  subscriptionName,
  collectionKey,
  typename
}) =>
  ({ client, data }) => {
    const { cache } = client

    const id = data.data[subscriptionName]

    if (id) {
      cache.modify({
        fields: {
          [collectionKey]: (collection, { toReference }) => {
            const ref = toReference({
              __typename: typename,
              id
            })
            if (ref)
              return collection.filter(c => c.__ref !== ref.__ref)
            else
              return collection
          }
        }
      })
    }
  }

// filter = (object) => true to allow, false to discard
export const createUpdater = ({
  query,
  variables,
  subscriptionName,
  findDoc,
  collectionKey,
  collectionGet,
  collectionSet,
  writeQuery,
  filter
}) =>
  ({ client, data }) => {
    const { cache } = client
    const newDoc = data.data[subscriptionName]

    if (newDoc) {
      const data = cache.readQuery({
        query,
        variables
      })
      const objectData = data && data[collectionKey]
      let modify
      if (findDoc)
        modify = findDoc(objectData)
      else
        modify = objectData && objectData.find(doc => doc.id == newDoc.id)

      if (modify) {
        let collection
        if (collectionGet)
          collection = collectionGet(data)
        else
          collection = objectData || []

        let newCollection
        const { id } = newDoc
        if (!filter || filter(newDoc)) {
          let found = false
          newCollection = collection.map((doc, idx) => {
            if (doc.id == id) {
              found = true
              return newDoc
            } else
              return doc
          })

          const newData = collectionSet ?
            collectionSet(data, newCollection, newDoc)
            : { ...data, [collectionKey]: newCollection }

          cache.writeQuery({
            query,
            variables,
            data: newData
          })
        }
      } else {
        // add
        if (writeQuery) {
          cache.writeQuery({
            query,
            variables,
            data: {
              [collectionKey]: [ newDoc ]
            }
          })
        } else {
          cache.modify({
            fields: {
              [collectionKey]: (collection, { toReference }) => {
                const { __typename } = newDoc

                const ref = toReference({
                  __typename,
                  id: newDoc.id
                })

                return [...collection, ref]
              }
            }
          })
        }
      }
    }
  }

