import { buttonGroupFromList } from '../../ui/bootstrap/ButtonGroup'
import { ButtonConfig } from '../../pintor'
import { tr } from 'react-hook-form-auto'

import styles from './ViewChooser.sass'

export const ViewChooser = ({
  value,
  onChange
}) => {
  const buttons = [
    new ButtonConfig(() => ({
      type: 'secondary',
      icon: 'th-large',
      text: tr('crud.normal'),
      onClick: onChange.bind(null, 'normal')
    })),
    new ButtonConfig(() => ({
      type: 'secondary',
      icon: 'th',
      text: tr('crud.small'),
      onClick: onChange.bind(null, 'small')
    })),
    new ButtonConfig(() => ({
      type: 'secondary',
      icon: 'list',
      text: tr('crud.list'),
      onClick: onChange.bind(null, 'list')
    }))
  ]

  let selIdx
  switch (value) {
  case 'small':
    selIdx = 1
    break
  case 'list':
    selIdx = 2
    break
  default:
    selIdx = 0
    break
  }

  // FIXME
  const buttonOpts = {
    selectedIdx: selIdx,
  }

  return (
    <div className={styles.buttons}>
      {buttonGroupFromList(null, buttons, buttonOpts)}
    </div>
  )
}
