/**
 * Makes sure it's an array. If not arrayizes it. If object
 * was falsy, returns empty array.
 */
export const ensureArray = obj => {
  if (obj)
    return Array.isArray(obj) ? obj : [obj]
  else
    return []
}

/**
 * Array.map for objects
 *
 * cb(value, key, index)
 */
export const objectMap = (object, cb) => {
  const keys = Object.keys(object)
  return keys.map((key, idx) => cb(object[key], key, idx))
}

/**
 * Array.reduce for object
 *
 * cb(acc, value, key, index)
 */
export const objectReduce = (obj, cb, initial) => {
  const keys = Object.keys(obj)
  return keys.reduce((acc, cur, idx) =>
    cb(acc, obj[cur], cur, idx)
  , initial)
}

/**
 * Attribute in object and returns
 * reference
 */
export const createIfMissing = (obj, attr, def) => {
  if (!(attr in obj))
    obj[attr] = def

  return obj[attr]
}

export const errorObj = obj => {
  return new Error(obj)
}

/**
 * Removes all subtrahend elements from minuend
 */
export function arrayMinus(minuend, subtrahend) {
  return minuend.reduce((leftovers, cur) => {
    if (subtrahend.indexOf(cur) == -1)
      leftovers.push(cur)

    return leftovers
  }, [])
}

/**
 * Search for an object in an array of objects
 * with attr id. Will return the index in the
 * array.
 */
export const findObjectInCollection = (arr, id) =>
  arr.reduce((found, cur, idx) => {
    if (found == -1)
      return cur.id == id ? idx : found
    else
      return found
  }, -1)

export const getObjectFromCollection = (arr, id) => {
  const idx = findObjectInCollection(arr, id)
  if (idx != -1)
    return arr[idx]
}

export function inverseSlice(arr, at) {
  return [ ...arr.slice(0, at), ...arr.slice(at + 1) ]
}

export function renderDate(rawDate) {
  const date = new Date(rawDate)

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

