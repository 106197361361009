import PropTypes from 'prop-types'
import { useMutation } from '../../graphql/notificatingMutation'

import { ModalAutoform } from '../../ui/bootstrap/bootstrap'

import { Buy } from '../../models/buy'
import { Ingredient } from '../../models/ingredient'
import {
  useGroups,
} from '../../models/ingredientGroup'

export const BuyForm = ({
  doc,
  onClose,
  visible,
  didSubmit,
  ingredient
}) => {
  const [ groups, loading ] = useGroups()
  const [ buyUpdate ] = useMutation(Buy.update)
  const [ ingredientUpdate ] = useMutation(Ingredient.update)

  const submit = (newDoc) => {
    const { groupId, ingredient, ingredientName } = newDoc

    if (groupId && groupId != doc.groupId) {
      ingredientUpdate({
        variables: {
          input: {
            id: ingredient.id,
            groupId
          }
        }
      })
    }

    const buyData = Buy.mutationConvert(newDoc)

    buyUpdate({
      variables: {
        input: buyData
      },
      optimisticResponse: {
        __typename: 'Mutation',
        buyUpdate: {
          ...buyData,
          __typename: 'Buy',
        }
      }
    })

    // Ingredient rename?
    if (ingredient) {
      const ingChanged = newDoc.ingredientName != doc.ingredient.name
      if (ingChanged) {
        ingredientUpdate({
          variables: {
            input: {
              id: ingredient.id,
              name: newDoc.ingredientName
            }
          }
        })

        // successText: tr('models.buys.renamed', {
        //   from: ingredient.name,
        //   to: newDoc.ingredientName
        // })
      }

      if (didSubmit)
        didSubmit(doc)
    }
  }

  const getGroups = () => {
    if (groups) {
      return groups.map((elem) => {
        return {
          value: elem.id,
          label: elem.name
        }
      })
    } else
      return []
  }

  const getTitle = () => {
    if (ingredient)
      return ingredient.name
    else
      return ""
  }

  // render()
  if (doc && groups) {
    const { ingredient } = doc
    const group = ingredient && ingredient.group
    const adaptedDoc = {
      ...doc,
      ingredientName: ingredient && ingredient.name,
      groupId: group && group.id
    }

    const schema = Buy.createBuySchema(groups)

    return (
      <ModalAutoform
        schema={schema}
        onSubmit={submit}
        modalTitle={getTitle()}
        onClose={onClose}
        visible={visible}
        initialValues={adaptedDoc}
      />
    )
  } else
    return null
}

BuyForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  doc: PropTypes.object,
  didSubmit: PropTypes.func
}
