.base {
  perspective: 5000px;
  width: 100%;
}

.appear .posAbs, .enter .posAbs {
  opacity: 0;
  transform: translate(0, 10px) rotateX(10deg);
}

.appearActive .posAbs, .enterActive .posAbs {
  opacity: 1;
  transform: translate(0, 0) rotateX(0);
  transition: 500ms ease-in-out;
}

.exit .posAbs {
  opacity: 1;
  transform: translate(0, 0) rotateX(0);
}

.exitActive .posAbs {
  opacity: 0;
  transform: translate(0, -10) rotateX(-10deg);
  transition: 300ms ease-in-out;
}

.posRel {
  position: relative;
  perspective: 5000px;
}
.posRel .posAbs {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  user-select: none;
}
.posRel .posAbs .posNormal {
  position: static;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdWkvdHJhbnNpdGlvbnMiLCJzb3VyY2VzIjpbInZpZXdUcmFuc2l0aW9ucy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7O0FBR0E7RUFDRTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTs7O0FBQ0o7RUFDRTtFQUNBOzs7QUFDRjtFQUNFO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuYmFzZVxuICBwZXJzcGVjdGl2ZTogNTAwMHB4XG4gIHdpZHRoOiAxMDAlXG4gIFxuLmFwcGVhciwgLmVudGVyXG4gIC5wb3NBYnNcbiAgICBvcGFjaXR5OiAwXG4gICAgdHJhbnNmb3JtOiB0cmFuc2xhdGUoMCwgMTBweCkgcm90YXRlWCgxMGRlZylcbi5hcHBlYXJBY3RpdmUsIC5lbnRlckFjdGl2ZVxuICAucG9zQWJzXG4gICAgb3BhY2l0eTogMVxuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIDApIHJvdGF0ZVgoMClcbiAgICB0cmFuc2l0aW9uOiA1MDBtcyBlYXNlLWluLW91dFxuLmV4aXQgLnBvc0Fic1xuICBvcGFjaXR5OiAxXG4gIHRyYW5zZm9ybTogdHJhbnNsYXRlKDAsIDApIHJvdGF0ZVgoMClcbi5leGl0QWN0aXZlIC5wb3NBYnNcbiAgb3BhY2l0eTogMFxuICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgwLCAtMTApIHJvdGF0ZVgoLTEwZGVnKVxuICB0cmFuc2l0aW9uOiAzMDBtcyBlYXNlLWluLW91dFxuXG4ucG9zUmVsXG4gIHBvc2l0aW9uOiByZWxhdGl2ZVxuICBwZXJzcGVjdGl2ZTogNTAwMHB4XG4gIC5wb3NBYnNcbiAgICBwb3NpdGlvbjogYWJzb2x1dGVcbiAgICBsZWZ0OiAwcHhcbiAgICB0b3A6IDBweFxuICAgIGJvdHRvbTogMHB4XG4gICAgcmlnaHQ6IDBweFxuICAgIHVzZXItc2VsZWN0OiBub25lXG4gICAgLnBvc05vcm1hbFxuICAgICAgcG9zaXRpb246IHN0YXRpY1xuIl19 */