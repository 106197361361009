.resultIngredient {
  padding: 0px 4px;
  background-color: #777;
  border-radius: 4px;
  display: inline-block;
  font-size: 10px;
  color: #000;
  margin-left: 8px;
}
.resultIngredient .icon {
  display: inline;
  color: #4f4;
  font-size: 9px;
  top: 0px;
}
.resultIngredient .amount {
  display: inline;
  color: #ddd;
}

.ingredientSelected {
  border-color: #30c030;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiQnV5YWJsZVNlYXJjaFJlc3VsdC5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDRjtFQUNFO0VBQ0E7OztBQUVKO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIucmVzdWx0SW5ncmVkaWVudFxuICBwYWRkaW5nOiAwcHggNHB4XG4gIGJhY2tncm91bmQtY29sb3I6ICM3NzdcbiAgYm9yZGVyLXJhZGl1czogNHB4XG4gIGRpc3BsYXk6IGlubGluZS1ibG9ja1xuICBmb250LXNpemU6IDEwcHhcbiAgY29sb3I6ICMwMDBcbiAgbWFyZ2luLWxlZnQ6IDhweFxuICAuaWNvblxuICAgIGRpc3BsYXk6IGlubGluZVxuICAgIGNvbG9yOiAjNGY0XG4gICAgZm9udC1zaXplOiA5cHhcbiAgICB0b3A6IDBweFxuICAuYW1vdW50XG4gICAgZGlzcGxheTogaW5saW5lXG4gICAgY29sb3I6ICNkZGRcblxuLmluZ3JlZGllbnRTZWxlY3RlZFxuICBib3JkZXItY29sb3I6ICMzMGMwMzBcblxuIl19 */