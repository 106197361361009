import { Component } from 'react';

import { tr } from 'react-hook-form-auto'

import { SearchForm } from '../../../ui/utils/SearchForm.jsx'

import MealResults from './MealResults'
import { MealForm } from '../MealForm.jsx'

export class MealSearchForm extends Component {
  reset() {
    this.searchForm.reset()
  }

  render() {
    const {
      resultsComponent = MealResults,
      ...rest
    } = this.props

    return (
      <SearchForm
        {...rest}
        resultsComponent={resultsComponent}
        createComponent={MealForm}
        placeholder={tr('models.meals.search')}
        ref={element => { this.searchForm = element }}
        alwaysResults
      />
    )
  }
}
