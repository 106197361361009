import { PureComponent } from 'react';
import { tr } from 'react-hook-form-auto'
import { useMutation } from '@apollo/client'

import { Modal, Button } from '../../ui/bootstrap/bootstrap'
import { RecipeEditor } from './RecipeEditor'
import { Meal } from '../../models/meal'

export class RecipeEditorModalBase extends PureComponent {
  handleSubmit = (recipe) => {
    this.props.mutate({
      variables: {
        id: parseInt(this.props.meal.id),
        recipe: JSON.stringify(recipe)
      }
    })
    this.props.onClose()
  }

  handleImperativeSubmit = () => {
    this.editor.submit()
  }

  renderButtons() {
    return (
      <div>
        <Button
          text={tr('modal.save')}
          type="primary"
          iconClass="check"
          onClick={this.handleImperativeSubmit}
        />
        <Button
          text={tr('modal.close')}
          iconClass="times"
          onClick={this.props.onClose}
        />
      </div>
    )
  }

  render() {
    const {
      meal,
      visible,
      onClose,
    } = this.props

    if (meal) {
      const title = tr('shop.recipes.editTitle', {
        name: meal.name
      })

      const recipe = meal.recipe && JSON.parse(meal.recipe)

      return (
        <Modal
          title={title}
          visible={visible}
          onClose={onClose}
          buttons={this.renderButtons()}
        >
          <RecipeEditor
            initial={recipe}
            meal={meal}
            onSubmit={this.handleSubmit}
            ref={e => this.editor = e}
          />
        </Modal>
      )
    } else
      return null
  }
}

export const RecipeEditorModal = (props) => {
  const [ updateRecipe ] = useMutation(Meal.updateRecipe)

  return (
    <RecipeEditorModalBase
      {...props}
      mutate={updateRecipe}
    />
  )
}
