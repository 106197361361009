.mealIngredients {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
}
.mealIngredients .mealPicIngredients {
  display: flex;
  flex-flow: row wrap;
}
.mealIngredients .mealPicIngredients .mealShow {
  flex: 1 auto;
}
.mealIngredients .mealNotes {
  font-size: 12px;
  margin-bottom: 8px;
}
.mealIngredients .mealNotes .memoLine {
  margin-bottom: 6px;
}
.mealIngredients table {
  margin-bottom: 0px;
}
.mealIngredients table td.amount {
  white-space: nowrap;
  text-align: right;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3MvbWVhbHMiLCJzb3VyY2VzIjpbIk1lYWxJbmZvLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBOztBQUNBO0VBQ0U7O0FBQ0o7RUFDRTtFQUNBOztBQUNBO0VBQ0U7O0FBQ0o7RUFDRTs7QUFDQTtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIubWVhbEluZ3JlZGllbnRzXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuICBwYWRkaW5nLWxlZnQ6IDBweFxuICAubWVhbFBpY0luZ3JlZGllbnRzXG4gICAgZGlzcGxheTogZmxleFxuICAgIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgICAubWVhbFNob3dcbiAgICAgIGZsZXg6IDEgYXV0b1xuICAubWVhbE5vdGVzXG4gICAgZm9udC1zaXplOiAxMnB4XG4gICAgbWFyZ2luLWJvdHRvbTogOHB4XG4gICAgLm1lbW9MaW5lXG4gICAgICBtYXJnaW4tYm90dG9tOiA2cHhcbiAgdGFibGVcbiAgICBtYXJnaW4tYm90dG9tOiAwcHhcbiAgICB0ZC5hbW91bnRcbiAgICAgIHdoaXRlLXNwYWNlOiBub3dyYXBcbiAgICAgIHRleHQtYWxpZ246IHJpZ2h0XG4iXX0= */