import { Component } from 'react';
import PropTypes from 'prop-types'

import { Modal } from '../../ui/bootstrap/bootstrap'
import { MealSearchForm } from './search/MealSearchForm'
import { tr } from 'react-hook-form-auto'

export class MealSelect extends Component {
  renderResults() {
    const results = this.state.searchResults

    return results.map((meal) => {
      const index = meal.id
      return (
        <ListGroupItem
          dontWrap
          index={index}
          key={index}
          onClick={this.handleMealChoose.bind(this)}
        >
          {meal.name}
        </ListGroupItem>
      )
    })
  }

  handleMealChoose = (meal) => {
    if (this.props.onSelected)
      this.props.onSelected(meal)
  }

  render() {
    return (
      <Modal
        title={tr('shop.addMeal')}
        visible={this.props.visible}
        onClose={this.props.onClose}
      >
        <MealSearchForm
          form="diet-meal-search"
          name="diet-meal-search"
          onSelect={this.handleMealChoose}
        />
      </Modal>
    )
  }
}

MealSelect.propTypes = {
  onSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}
