import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Icon.sass'

const Icon = ({
  icon,
  className,
  text = '',
  iconClass = '',
  textClass,
  noMargin
}) => {
  const classes = classnames(styles.icon, {
    [styles.hasText]: text && !noMargin
  })

  return (
    <span className={className}>
      <span className={classes}>
        <FontAwesomeIcon className={iconClass} icon={icon} />
      </span>
      { text && <span className={textClass}>{text}</span> }
    </span>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.any,
  iconClass: PropTypes.string
}

export default Icon
