import styles from './Card.sass'
import classnames from 'classnames'

import { Icon } from '../../ui/bootstrap/bootstrap'
import { DropdownMenu } from '../../ui/bootstrap/bootstrap'
import {
  ImageShow,
  renderMatch,
  getImageUrlFromDoc
} from '../../pintor'

import clientConfig from '../../clientConfig'

// import {
//   getSizes,
//   createStyles
// } from './cardStyles'

const renderAdd = ({
  classes,
  text
}) => {
  return (
    <div className={classes.plusCard}>
      <Icon icon="plus" className={classes.plusIcon} />  
      <div className={classes.plusName}>
        {text}
      </div>
    </div>
  )
}

const renderIcon = (modelIcon, className, hasImage) => {
  if (!hasImage) {
    return (
      <Icon icon={modelIcon} className={className} />
    )
  }
}

const renderCard = ({
  doc,
  children,
  search,
  buttons = [],
  infoProvider,
  modelIcon,
  onClick,
  model,
  classes,
  small,
  short
}) => {
  const renderData = ({ icon, label, data, classes, inv }) => {
    const labelComp = (
      <span className={styles.infoLabel}>
        {label}
      </span>
    )
    const dataComp = (
      <span className={styles.infoData}>
        {data}
      </span>
    )

    return (
      <span key={label} className={classes.infoEntry}>
        <Icon
          icon={icon}
          className={classes.infoIcon}
        />
        { inv ? labelComp : dataComp }
        { inv ? dataComp : labelComp }
      </span>
    )
  }

  const name = doc ? (doc.name || doc.text) : ''
  // const sizes = getSizes(small, short)
  const hasImage = getImageUrlFromDoc(doc)
  const file = doc.images && doc.images[0]

  const sizeName = small ? 'small' : 'normal'
  const side = clientConfig.crud[sizeName].side

  return (
    <div className={classes.contents}>
      <ImageShow
        file={file}
        version="normal"
        className={classes.picture}
        onClick={onClick}
        side={side}
      >
        {renderIcon(modelIcon, classes.modelIcon, hasImage)}
        <div className={classes.name} data-text={name}>
          {renderMatch(name, search, classes)}
        </div>
      </ImageShow>
      <div className={classes.info}>
        <div className={styles.infoTable}>
          {infoProvider(doc, classes, renderData)}
        </div>
      </div>
      <div className={styles.dropdown}>
        <DropdownMenu
          buttons={buttons}
          doc={doc}
        />
      </div>
      {children}
    </div>
  )
}

const renderWhatever = props => {
  if (props.isAdd)
    return renderAdd(props)
  else
    return renderCard(props)
}

export const Card = props => {
  const config = {
    color: props.isAdd ? 'plus' : props.baseColor,
    size: props.small ? 'small' : 'normal'
  }

  const classes = {
    container: classnames(
      styles.container,
      styles[config.size]
    ),
    contents: classnames(
      styles.contents,
      styles[config.size]
    ),
    info: classnames(
      styles.info,
      styles[config.size]
    ),
    picture: classnames(
      styles.picture,
      styles[config.size],
      styles[config.color]
    ),
    name: classnames(
      styles.name,
      styles[config.color],
      styles[config.size]
    ),
    plusCard: classnames(styles.plusCard, styles[config.size]),
    plusIcon: classnames(styles.plusIcon, styles[config.size]),
    modelIcon: classnames(
      styles.modelIcon,
      styles[config.size],
      styles[config.color]
    ),
    plusName: classnames(
      styles.plusName,
      styles[config.size]
    ),
    infoEntry: classnames(styles.infoEntry, styles[config.size]),
    infoIcon: classnames(styles.infoIcon, styles[config.color]),
    match: styles.match
  }

  const stylizedProps = {
    ...props,
    classes,
    config
  }

  // Making the whole add card clickable
  const onClick = props.isAdd && props.onClick

  return (
    <div className={classes.container} onClick={onClick}>
      {renderWhatever(stylizedProps)}
    </div>
  )
}
