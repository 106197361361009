import PropTypes from 'prop-types'

import {
  ModalAutoform,
} from '../../ui/bootstrap/bootstrap'
import { tr } from 'react-hook-form-auto'

import { Meal } from '../../models/meal.js'

export const MealForm = ({
  doc = {},
  onSubmit,
  onClose,
  visible
}) => {
  const handleSubmit = doc => {
    const { ingredientList = [], ...rest } = doc

    // Remove references not set
    onSubmit({
      ...rest,
      ingredientList: ingredientList.filter(({ ref }) => ref.id)
    })
  }

  if (doc) {
    return (
      <ModalAutoform
        schema={Meal.schema}
        onSubmit={handleSubmit}
        modalTitle={tr('models.meal.modal')}
        onClose={onClose}
        visible={visible}
        initialValues={doc}
      />
    )  
  } else {
    return null
  }
}

MealForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  doc: PropTypes.object
}
