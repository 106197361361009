let hooks = {}

export function setPintorHooks(newHooks) {
  for (let k in newHooks) {
    hooks[k] = newHooks[k]
  }
}

export function getHook(name) {
  return hooks[name]
}

export function getHooks() {
  return hooks
}
