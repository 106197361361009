import gql from 'graphql-tag'
import { createUpdater } from '../pintor'

import {
  useQuery,
  useMutation,
  useSubscription
} from '@apollo/client'

const postFragment = gql`
  fragment PostFragment on Post {
    id
    createdAt
    updatedAt
    parentPostId
    points
    post
    author
    username
  }
`

const threadFragment = gql`
  fragment ThreadFragment on Thread {
    id
    createdAt
    updatedAt
    author
    username
    posts {
      ...PostFragment
    }
  }

  ${postFragment}
`

const THREADS_GET = gql`
  query Thread($parentType: String, $parentId: ID) {
    threads(parentType: $parentType, parentId: $parentId) {
      ...ThreadFragment
    }
  }

  ${threadFragment}
`

const THREAD_UPDATE = gql`
  mutation ThreadUpdate($input: ThreadInput) {
    updateThread(input: $input) {
      ...ThreadFragment
    }
  }

  ${threadFragment}
`

const POST_UPDATE = gql`
  mutation PostUpdate($input: PostInput) {
    updatePost(input: $input) {
      ...PostFragment
    }
  }

  ${postFragment}
`

const UPVOTE = gql`
  mutation Upvote($id: ID) {
    upvote(id: $id) {
      ...PostFragment
    }
  }

  ${postFragment}
`

const DOWNVOTE = gql`
  mutation Downvote($id: ID) {
    downvote(id: $id) {
      ...PostFragment
    }
  }

  ${postFragment}
`

const NEW_THREAD_SUB = gql`
  subscription NewThreadSub($parentType: String, $parentId: ID) {
    threadCreated(parentType: $parentType, parentId: $parentId) {
      ...ThreadFragment
    }
  }

  ${threadFragment}
`

const NEW_POST_SUB = gql`
  subscription NewPostSub($threadId: ID) {
    postCreated(threadId: $threadId) {
      ...PostFragment
    }
  }

  ${postFragment}
`

const UPDATE_POST_SUB = gql`
  subscription UpdatePostSub($threadId: ID) {
    postUpdated(threadId: $threadId) {
      ...PostFragment
    }
  }

  ${postFragment}
`

function createPostUpdater({ subscriptionName, parentId, parentType, isAdd }) {
  return createUpdater({
    query: THREADS_GET,
    variables: { parentType, parentId },
    subscriptionName,
    collectionKey: 'threads',
    findDoc: collection => {
      return collection && collection.length > 0 && collection[0]
    },
    collectionGet: data => {
      return data && data.threads && data.threads.length > 0
        && data.threads[0].posts || []
    },
    collectionSet: (data, newPosts, newDoc) => {
      if (isAdd) {
        return {
          threads: [
            {
              ...(data && data.threads[0]),
              posts: [
                ...newPosts,
                newDoc
              ]
            }
          ]
        }
      } else {
        return {
          threads: [
            {
              ...(data && data.threads[0]),
              posts: newPosts
            }
          ]
        }
      }
    }
  })
}

function useComments({ parentType, parentId }) {
  const { data, loading } = useQuery(THREADS_GET, {
    variables: { parentType, parentId }
  })
  const [ threadUpdate ] = useMutation(THREAD_UPDATE)
  const [ postUpdate ] = useMutation(POST_UPDATE)
  const [ upvote ] = useMutation(UPVOTE)
  const [ downvote ] = useMutation(DOWNVOTE)

  useSubscription(NEW_THREAD_SUB, {
    variables: { parentType, parentId },
    onData: createUpdater({
      query: THREADS_GET,
      variables: { parentType, parentId },
      subscriptionName: 'threadCreated',
      collectionKey: 'threads',
      writeQuery: true
    })
  })

  const threadId = data && data.threads &&
    data.threads.length > 0 && data.threads[0].id || 0
  useSubscription(NEW_POST_SUB, {
    variables: { threadId },
    onData: createPostUpdater({
      subscriptionName: 'postCreated',
      parentType, parentId,
      isAdd: true
    })
  })
  useSubscription(UPDATE_POST_SUB, {
    variables: { threadId },
    onData: createPostUpdater({
      subscriptionName: 'postUpdated',
      parentType, parentId
    })
  })

  return {
    threads: data && data.threads,
    loading,
    threadUpdate: (input) =>
      threadUpdate({ variables: { input } }),
    postUpdate: (input) => {
      postUpdate({ variables: { input } })
    },
    downvote: (id) => {
      downvote({ variables: { id } })
    },
    upvote: (id) => {
      upvote({ variables: { id } })
    }
  }
}

export const Thread = {
  name: 'thread',
  useComments
}
