import { Button } from '../../ui/bootstrap/bootstrap'

import styles from './Comments.sass'

export const Votes = ({
  post,
  onUpvote,
  onDownvote
}) =>
  <div className={styles.votesLayout}>
    <div className={styles.upvote}>
      <Button
        type="dark"
        iconClass="chevron-up"
        onClick={onUpvote}
        outline
        small
      />
    </div>
    <div className={styles.points}>
      {post.points}
    </div>
    <div className={styles.downvote}>
      <Button
        type="dark"
        iconClass="chevron-down"
        onClick={onDownvote}
        outline
        small
      />
    </div>
  </div>
