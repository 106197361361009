import { usePath, navigate } from 'raviger'
import { tr } from 'react-hook-form-auto'
import {
  FlexList,
  FlexItem
} from '../../components/FlexList'
import classnames from 'classnames'

import styles from './DietsNav.sass'

export const DietsNav = ({
  diets,
  onAdd
}) => {
  const location = usePath()

  const handleNav = (id) => {
    navigate(`/diets/${id}`)
  }

  const renderButton = (doc, selectedId) => {
    const { id } = doc

    const classes = classnames(styles.nav, {
      [styles.selected]: id == selectedId
    })

    return (
      <FlexItem
        className={classes}
        key={id}
        onClick={handleNav.bind(null, id)}
      >
        {doc.name}
      </FlexItem>
    )
  }

  const renderDiets = (diets, selectedId) => {
    return diets.map(diet =>
      renderButton(diet, selectedId)
    )
  }

  return (
    <FlexList>
      {renderDiets(diets)}
      <FlexItem
        className={styles.add}
        key="add"
        onClick={onAdd}
      >
        {tr('add')}
      </FlexItem>
    </FlexList>
  )
}

