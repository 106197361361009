import { Component } from 'react';
import classnames from 'classnames'
import { tr } from 'react-hook-form-auto'

import styles from './MealInfoMicro.sass'

const renderIngredients = (entries) =>
  entries.map(entry =>
    <div key={entry.ingredient.id} className={styles.ingredient}>
      {entry.ingredient.name}
    </div>
  )

export const MealInfoMicro = ({
  doc,
  className,
  children
}) => {
  const classes = classnames(
    styles.micro,
    className
  )

  const { ingredientList } = doc
  if (ingredientList) {
    return (
      <div
        className={classes}
      >
        <div key="title" className={styles.title}>
          {doc.name}
        </div>
        <div key="memo" className={styles.memo}>
          {doc.memo}
        </div>
        <div key="ings" className={styles.ingredients}>
          {renderIngredients(ingredientList)}
        </div>
        <div key="controls" className={styles.controls}>
          {children}
        </div>
      </div>
    )
  } else
    return null
}
