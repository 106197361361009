import classnames from 'classnames'

import { Icon } from '../../ui/bootstrap/bootstrap'
import { renderMatch } from '../../pintor'
import { DropdownMenu } from '../../ui/bootstrap/DropdownMenu'

const renderButtons = (doc, buttons) =>
  <div className="crud-buttons-centerer">
    <DropdownMenu
      icon="cog"
      doc={doc}
      buttons={buttons}
    />
  </div>

export const CrudInfoTable = ({
  id,
  text,
  doc,
  isAdd,
  onClick,
  search,
  buttons,
  children
}) => {
  const iconClass = isAdd ? 'plus' : ''
  const icon = iconClass ? <Icon icon={iconClass} /> : ''
  const classes = classnames({
    success: isAdd,
    add: isAdd
  })

  return (
    <tr
      key={id}
      className={classes}
    >
      <td onClick={onClick}>
        {icon}{' '}{text || renderMatch(doc.name, search)}
      </td>
      <td className="crud-list-buttons">
        {!isAdd && renderButtons(doc, buttons)}
      </td>
    </tr>
  )
}
