import config from '../clientConfig'
import { userIsAdmin } from '../pintor'

const isOwned = (doc, user, userField = 'owner') => {
  if (doc)
    return doc[userField] == user.id
  else
    return false
}

export const canEditPersonal = (doc, user, userField) => {
  if (config.shop.business)
    return userIsAdmin(user)
  else if (doc && doc.id)
    return isOwned(doc, user, userField)
  else
    return user && user.id
}

// data is a doc or slice.
// It's a string id or has ingredientId or has ref
export const getIngredientRef = (data) => {
  if (data) {
    if (data.ref)
      return data.ref
    else {
      const isString = typeof data == 'string'
      const id = isString ? data : data.ingredientId

      return {
        id,
        type: data.ingredientType || 'ingredient'
      }
    }
  } else
    return null
}

/**
 * Organizes a collection with any key.
 */
export const colByAttr = (col, keyFn) => {
  return col.reduce((hash, doc) => {
    if (!doc) {
      console.log("NO INGREDIENT, HASH", hash)
      console.log("COL", col)
    } else {
      const key = keyFn(doc)
      hash[key] = doc
    }

    return hash
  }, {})
}

export const ingredientsById = (ingredients) => {
  return colByAttr(ingredients, ingredient => ingredient.id)
}

export const ingredientsByName = (ingredients) => {
  return colByAttr(ingredients, ingredient => ingredient.name)
}

/**
 * Extracts a field value from a collection
 * to create an array suitable to be used by
 * redux-form-auto's select and radios.
 *
 * @param {array} col Collection
 * @param {string} labelAttr Column to extract for label
 * @param {string} valueAttr Column to extract for value
 *
 * @returns {array} Array with objects like this:
 *  { label: 'David', value: 42 }
 */
export const extractForSelect = (col, labelAttr = 'name', valueAttr = 'id') =>
  col.map(row => ({
    label: row[labelAttr],
    value: row[valueAttr]
  }))
