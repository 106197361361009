import { Component } from 'react';

import { tr } from 'react-hook-form-auto'
import { Card } from '../../components/crud/Card'

const labelText = label => tr(`models.ingredients.info.${label}`)

const provideData = (doc, classes, render) => {
  // const renderTr = (icon, label, text) =>
  //   render({ icon, label: labelText(label), text, classes })

  // const numIngredients = doc ? (doc.ingredientList || []).length : 0
  // // FIXME generalize plurals
  // const numIngLabel = numIngredients > 1 ? 'ingredients' : 'ingredient'

  // const servingsLabel = doc.servings > 1 ? 'servings' : 'serving'
  // const servings = doc.servings ? renderTr('user', servingsLabel, doc.servings) : []

  // return [
  //   renderTr('tag', numIngLabel, numIngredients),
  //   ...servings
  // ]
  
  return null
}

export const IngredientCard = props =>
  <Card
    {...props}
    modelIcon="tag"
    infoProvider={provideData}
    baseColor="ingredient"
  />
