import { Component } from 'react';
import { tr } from 'react-hook-form-auto'

import { MealInfoMicro } from '../meals/MealInfoMicro'
import { Button, ModalAutoform } from '../../ui/bootstrap/bootstrap'
import { Diet } from '../../models/diet'
import { MealSelect } from '../meals/MealSelect'
import { ListTransition } from '../../ui/transitions/ListTransition'
import { inverseSlice } from '../../pintor'

import styles from './DietView.sass'

export class DietView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalEditing: null,
      modalVisible: false,
      newMealVisible: false
    }
  }

  edit = diet => {
    this.toggleEdit()
    this.setState({
      modalEditing: diet
    })
  }

  toggleEdit = () => {
    this.setState({
      modalVisible: !this.state.modalVisible
    })
  }

  handleAddPress = () => {
    this.setState({ newMealVisible: true })
  }

  handleCloseAdd = () => {
    this.setState({ newMealVisible: false })
  }

  updateMealIds(diet, mealIds) {
    this.props.onUpdate({
      id: diet.id,
      mealIds
    })

    this.handleCloseAdd()
  }

  handleAddMeal = (meal) => {
    const { diet } = this.props

    const mealIds = (diet.mealIds || []).concat(meal.id)
    this.updateMealIds(diet, mealIds)
  }

  handleRemoveMeal = (mealId) => {
    const { diet } = this.props
    const { mealIds } = diet

    const idx = mealIds.indexOf(mealId)
    const newMealIds = inverseSlice(mealIds, idx)
    this.updateMealIds(diet, newMealIds)
  }

  renderMeals(meals) {
    return meals.map(meal =>
      <MealInfoMicro key={meal.id} doc={meal} transKey={meal.id}>
        <Button
          iconClass="eraser"
          type="danger"
          outline
          text={tr('removeFromList')}
          onClick={this.handleRemoveMeal.bind(this, meal.id)}
        />
      </MealInfoMicro>
    )
  }

  renderModal() {
    if (this.state.modalVisible) {
      return (
        <ModalAutoform
          form="diet-edit"
          initial={this.modalEditing}
          schema={Diet.schema}
          visible={this.state.modalVisible}
          onClose={this.toggleModal}
          modalTitle={tr('models.diets')}
          onSubmit={this.props.onDietChange}
        />
      )
    } else
      return null
  }

  render() {
    const { diet } = this.props

    if (diet) {
      return (
        <div className={styles.diet}>
          <div className={styles.header}>
            <div className={styles.name}>
              {diet.name}
            </div>
            <div className={styles.controls}>
              <Button
                iconClass="tag"
                onClick={this.handleAddPress}
                type="success"
                text={tr('shop.addMeal')}
              />
              <Button
                iconClass="shopping-cart"
                onClick={this.props.onDietBuyAdd.bind(this, diet)}
                type="primary"
                text={tr('shop.buy')}
              />
              <Button
                iconClass="edit"
                onClick={this.edit.bind(this, diet)}
                text={tr('models.edit')}
                outline
                type="primary"
              />
              <Button
                iconClass="trash"
                type="danger"
                text={tr('models.delete')}
              />
            </div>
          </div>
          <div className={styles.memo}>
            {diet.memo}
          </div>
          <ListTransition key={diet.id} className={styles.meals}>
            {this.renderMeals(diet.meals)}
          </ListTransition>
          {this.renderModal()}
          <MealSelect
            onSelected={this.handleAddMeal}
            onClose={this.handleCloseAdd}
            visible={this.state.newMealVisible}
            meals={this.props.meals}
          />
        </div>
      )
    } else
      return this.renderModal()
  }
}
