{
  "add": "Añadir",
  "addObject": "Añadir __object__",
  "cancel": "Cancelar",
  "warning": "Aviso",
  "update": "Update",
  "removeFromList": "Quitar de la lista",
  "not-authorized": "No tiene permisos para realizar esta operación",
  "search": "Buscar...",
  "genericSuccess": "Actualizado con éxito",
  "link": "Enlace a la receta pública",
  "linkPromise": "El enlace a la receta estará disponible cuando salve los cambios.",
  "auth": {
    "login": "Entrar",
    "logoff": "Salir",
    "register": "Crear cuenta",
    "password-repeat": "Repetir contraseña"
  },
  "pagination": {
    "title": "Navegación",
    "first": "Primero",
    "last": "Último",
    "previous": "Anterior",
    "next": "Siguiente",
    "showing": "Página __page__ de __total__ (__documents__ documentos)",
    "noResults": "No se encontró ningún documento"
  },
  "threads": {
    "comments": "Comentarios",
    "new": "Crear conversación",
    "created": "Escrito el __date__",
    "updated": "Actualizado el __date__",
    "reply": "Responder",
    "quote": "Citar",
    "noComments": "Ningún comentario",
    "registerToComment": "Ingrese o regístrese para comentar"
  },
  "business": {
    "pitch": "Gestión práctica de comidas y lista de la compra",
    "meals": "Crea tus propias comidas",
    "meal": "Cada comida permite una lista de ingredientes con cantidades individuales",
    "shop": "No tires el dinero, compra sólo lo que necesitas",
    "title": {
      "meals": "Listado de comidas",
      "meal": "Comida",
      "shop": "Lista de la compra"
    }
  },
  "models": {
    "show": "Mostrar",
    "edit": "Editar",
    "delete": "Borrar",
    "diets": "Dietas",
    "organize": "Organizar",
    "history": "Historial",
    "diet": {
      "created": "Dieta __name__ creada con éxito",
      "modal": "Detalles de la dieta",
      "name": "Nombre",
      "memo": "Nota"
    },
    "meals": {
      "_": "Comidas",
      "ingredientId": "Ingrediente"
    },
    "meal": {
      "name": "Nombre",
      "memo": "Notas",
      "created": "Plato __name__ creado con éxito",
      "updated": "__name__ actualizada",
      "modal": "Detalles del plato",
      "amount": "Cantidad",
      "unit": "Unidad",
      "ingredientId": "Ingrediente",
      "search": "Buscar platos...",
      "recipe": "Preparación",
      "public": "Público",
      "publicLink": "Enlace a la comida visible por todos",
      "servings": {
        "_field": "Comensales"
      },
      "info": {
        "ingredient": "ingrediente",
        "ingredients": "ingredientes",
        "serving": "comensal",
        "servings": "comensales",
        "author": "Autor:"
      },
      "ingredientList": "Ingredientes",
      "imageIds": {
        "_field": "Fotos"
      }
    },
    "ingredients": "Ingredientes",
    "ingredient": {
      "created": "__name__ añadido",
      "modal": "Detalles del ingrediente",
      "name": {
        "_field": "Nombre"
      },
      "nutriments": "Nutrientes",
      "groupId": {
        "_field": "Grupo",
        "_default": "Seleccione grupo..."
      },
      "imageIds": "Imágenes"
    },
    "ingredient_groups": {
      "ungrouped": "Sin grupo"
    },
    "nutriment": {
      "_field": "Nutrientes",
      "type": {
        "_field": "Tipo",
        "_default": "Seleccione tipo...",
        "sugars": "Azúcar",
        "salt": "Sal",
        "proteins": "Proteína",
        "saturatedFat": "Grasas saturadas",
        "sodium": "Sodio",
        "energy": "Energía",
        "fat": "Grasa",
        "carbohidrates": "Carbohidratos"
      },
      "unit": {
        "_field": "Unidad",
        "_default": "Seleccione unidad...",
        "kcal": "kcal",
        "kJ": "kJ",
        "kg": "kg",
        "g": "g",
        "ml": "ml",
        "l": "l",
        "percentVol": "% vol."
      },
      "amount": {
        "_field": "Cantidad"
      },
      "groups": {
        "ungrouped": "Sin grupo"
      }
    },
    "ingredientEntry": {
      "amount": {
        "_field": "Cantidad"
      },
      "unit": {
        "_field": "Unidad",
        "_default": "Seleccionar unidad...",
        "g": "g",
        "kg": "kg",
        "l": "litro",
        "u": "unidad"
      },
      "ref": {
        "_field": "Ingrediente"
      }
    },
    "shop": "Comprar",
    "origins": "Orígenes",
    "groups": "Grupos",
    "buy": {
      "ingredientName": "Nombre del ingrediente",
      "ingredientSearch": "Buscar ingrediente...",
      "group": "Grupo",
      "groupId": "Grupo",
      "ungrouped": "Sin grupo",
      "rename": "Cambiar nombre",
      "renamed": "Nombre de ingrediente cambiado de __from__ a __to__",
      "bought": "__ingredient__ comprado",
      "origins": "Orígenes",
      "amounts": "Cantidades"
    },
    "buyAmount": {
      "amount": {
        "_field": "Cantidad"
      },
      "unit": {
        "_field": "Unidad",
        "g": "g",
        "kg": "kg",
        "l": "litro",
        "u": "unidad"
      }
    },
    "ingredientGroup": {
      "created": "Group __name__ creado con éxito",
      "updated": "__name__ actualizado",
      "modal": "Detalles del grupo de ingredientes",
      "name": "Nombre",
      "ungrouped": "Sin grupo"
    },
    "user": {
      "username": "Nombre de usuario",
      "email": "Correo electrónico",
      "password": "Contraseña",
      "isAdmin": "Administrador",
      "imageIds": "Imagen de perfil"
    },
    "bought": "Comprado",
    "errors": {
      "empty": "Este campo no puede estar vacío"
    },
    "login": {
      "username": "Usuario",
      "password": "Contraseña"
    },
    "register": {
      "username": "Usuario",
      "password": "Contraseña",
      "password_repeat": "Repetir contraseña",
      "email": "Correo electrónico (opcional)"
    }
  },
  "modal": {
    "close": "Cerrar",
    "save": "Salvar cambios"
  },
  "shop": {
    "admin": "Administración",
    "diets": "Dietas",
    "meals": "Platos",
    "ingredients": "Ingredientes",
    "shop": "Compra",
    "ingredientGroups": "Grupos",
    "day": "Día __number__",
    "drag": "Arrastrar",
    "list": "Lista",
    "calendar": "Calendario",
    "buy": "Comprar",
    "removeFromDiet": "Quitar",
    "loginGreet": "¡Hola!",
    "addToShop": "Añadir a la lista de la compra",
    "addMeal": "Añadir plato",
    "recipes": {
      "edit": "Editar receta",
      "editTitle": "Editando receta para __name__"
    },
    "recipe": "Receta",
    "toggleDetails": "Mostrar/ocultar detalles",
    "cooked": "Cocinado",
    "files": {
      "removed": "Adjunto borrado",
      "edit": "Editar",
      "remove": "Eliminar",
      "warnDelete": "Si cierra este diálogo se perderán las imágenes adjuntadas"
    },
    "justBought": "Recién cogido"
  },
  "admin": {
    "userMadeAdmin": "Justo cuando creía que estaba fuera me vuelven a involucrar"
  },
  "nutrition": {
    "carbohydrates": "Carbohidratos",
    "energy": "Energía",
    "fat": "Grasa",
    "fiber": "Fibra",
    "proteins": "Proteínas",
    "salt": "Sal",
    "saturated-fat": "Grasas saturadas",
    "sodium": "Sodio",
    "sugars": "Azúcar",
    "warns": {
      "ingredients": "No se puede proporcionar información nutricional precisa debido a la falta de información en los siguientes ingredientes: __ingredients__."
    }
  },
  "user": {
    "login": "Ingresar",
    "register": "Registrarse",
    "name": "Nombre",
    "password": "Contraseña",
    "passwordRepeat": "Repetir contraseña",
    "loginError": "Error al ingresar. Por favor, revise credenciales.",
    "loginSuccess": "¡Ingreso con éxito!",
    "logout": "Salir",
    "welcome": "Hola, __name__",
    "profile": "Perfil",
    "basicConfig": "Información básica"
  },
  "crud": {
    "info": "Información",
    "small": "Pequeño",
    "normal": "Normal",
    "list": "Lista",
    "seeEveryones": "Ver los de los demás"
  },
  "mutations": {
    "mealUpdate": {
      "add": "__name__ añadida con éxito",
      "update": "__name__ actualizada con éxito"
    },
    "ingredientUpdate": {
      "add": "__name__ añadido con éxito",
      "update": "__name__ actualizado con éxito"
    },
    "buyAdd": {
      "add": "Añadido a la lista de la compra",
      "update": "Añadido a la lista de la compra"
    },
    "buyUpdate": {
      "update": "Actualizada la lista de la compra"
    },
    "dietUpdate": {
      "add": "Dieta __name__ añadida con éxito",
      "update": "Dieta __name__ actualizada con éxito"
    },
    "deleteImage": {
      "update": "Fichero borrado correctamente"
    },
    "upload": {
      "update": "Fichero enviado correctamente"
    }
  },
  "error": {
    "login": "Hay que estar identificado para realizar esta operación",
    "unauthorized": "No está autorizado a realizar esta operación",
    "changeSystemIngredient": "No se pueden cambiar ingredientes base"
  }
}
