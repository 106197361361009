import { useState } from 'react';

import { DietsNav } from './DietsNav'
import { getObjectFromCollection } from '../../pintor'
import { DietView } from './DietView'
import { ModalAutoform } from '../../ui/bootstrap/ModalAutoform'
import { Diet } from '../../models/diet'
import { tr } from 'react-hook-form-auto'

import { ViewTransition } from '../../ui/transitions/ViewTransition'

export const DietsView = ({
  diets = [],
  onUpdate,
  onDietBuyAdd,
  id
}) => {
  const diet = getObjectFromCollection(diets, id)
  const [ editingDoc, setEditingDoc ] = useState({})
  const [ editing, setEditing ] = useState(false)

  const handleAdd = () => {
    setEditingDoc({})
    setEditing(true)
  }

  const handleClose = () => {
    setEditing(false)
  }

  return (
    <div>
      <DietsNav
        key="nav"
        diets={diets}
        onAdd={handleAdd}
      />
      {
        editing &&
          <ModalAutoform
            onSubmit={onUpdate}
            schema={Diet.schema}
            modalTitle={tr('models.diet.modal')}
            onClose={handleClose}
            visible={editing}
            initialValues={editingDoc}
          />
      }
      <ViewTransition viewKey={diet && diet.id}>
        <DietView
          key="view"
          diet={diet}
          id={id}
          onDietBuyAdd={onDietBuyAdd}
          onUpdate={onUpdate}
        />
      </ViewTransition>
    </div>
  )
}
