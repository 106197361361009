import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

import { IngredientGroup } from './ingredientGroup'
import { createUpdater } from '../pintor'

const historyFragment = gql`
  fragment HistoryFragment on History {
    id
    createdAt
    ingredient {
      id
      name
    }
    amounts {
      unit
      amount
    }
    origins {
      dietId
      mealIds
    }
    added    
  }
`

const HISTORIES_GET = gql`
  query HistoriesList {
    history {
      ...HistoryFragment
    }
  }
  ${historyFragment}
`

const HISTORIES_SUB = gql`
  subscription HistoryUpdate {
    historyAdded {
      ...HistoryFragment
    }
  }
  ${historyFragment}
`

const findHistory = (histories, newHistory) =>
  data.histories.reduce((found, cur, idx) => {
    if (found == -1)
      return cur.id == newHistory.id ? idx : found
    else
      return found
  }, -1)

const dataUpdater = createUpdater({
  query: HISTORIES_GET,
  subscriptionName: 'historyAdded',
  collectionKey: 'histories',
  filter: IngredientGroup.hasAmounts
})

const useHistoryUpdate = () => {
  const { data, loading } = useSubscription(History.sub, {
    onData: dataUpdater
  })

  const historyAdded = data && data.historyAdded

  return { historyAdded, loading }
}

export const History = {
  name: 'history',
  fetch: HISTORIES_GET,
  sub: HISTORIES_SUB,
  fragment: historyFragment,
  useHistoryUpdate,
}
