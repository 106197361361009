import { Component } from 'react';

import { Input } from './Input.jsx'
import config from '../../../clientConfig'

const Slider = props => {
  const { value, ...rest } = props

  const actualValue = typeof value == 'undefined'
    ? config.shop.defaultServings
    : value
  const noEmptyValue = actualValue === '' ? 0 : actualValue
  const numericValue = parseInt(noEmptyValue)

  return (
    <ReactSlider
      value={numericValue}
      {...rest}
    />
  )
}

// FIXME Aquí hay que pasarle un Slider como inputComponent,
// pero no encuentro alguno que funcione.
export class InputSlider extends Component {
  render() {
    return (
      <Input
        {...this.props}
        className="form-control"
        inputComponent="input"
      />
    )
  }
}
