import { useQuery } from '@apollo/client'

import { IngredientGroup } from '../../models/ingredientGroup'

import { GroupIngredientsView } from './GroupIngredientsView'

const GroupIngredientsContainer = ({
  groupId,
  long,
  ...rest
}) => {
  const { data } = useQuery(IngredientGroup.fetchIngredients, {
    variables: { groupId, long }
  })
  const collection = data && data.collection

  return (
    <GroupIngredientsView
      {...rest}
      collection={collection}
      groupId={groupId}
      long={long}
    />
  )
}

export default GroupIngredientsContainer
