import { tr } from 'react-hook-form-auto'

import { Pagination } from '../../../ui/utils/Pagination'

function renderDoc(Component, props) {
  const { docKey, ...rest } = props

  return (
    <Component key={docKey} {...rest} />
  )

}

const renderCollection = ({
  collection,
  onSelect,
  search,
  docComponent,
  selectedIdx,
}) => {
  if (collection && collection.length > 0) {
    return collection.map((doc, idx) => {
      const props = {
        docKey: doc.id,
        doc,
        onClick: onSelect.bind(null, doc),
        inline: true,
        selected: idx == selectedIdx,
        search
      }

      return renderDoc(docComponent, props)
    })
  } else
    return null
}

const defaultWrapper = ({ model, children }) => {
  const className = model ? `${model.getType()}-list` : ''

  return <div className={className}>{children}</div>
}

const renderPagination = ({
  paginate,
  count,
  page,
  perPage,
  onPageChange
}) => {
  if (paginate) {
    return (
      <Pagination
        page={page}
        total={count}
        onPageChange={onPageChange}
        perPage={perPage}
      />
    )
  } else
    return null
}

export const DocList = ({
  model,
  search,
  searching,
  onSelect,
  onAddSelected,
  collection,
  count = 0,
  paginate,
  page,
  perPage,
  onPageChange,
  listWrapper,
  docComponent,
  selectedIdx
}) => {
  const renderAddProps = {
    docKey: 'add',
    text: tr('add'),
    onClick: onAddSelected,
    isAdd: true
  }

  const Wrapper = listWrapper || defaultWrapper

  const renderColProps = {
    collection,
    search,
    searching,
    onSelect,
    docComponent,
    selectedIdx,
    page,
    perPage,
    total: count
  }

  return (
    <div>
      {renderPagination({ page, perPage, paginate, count, onPageChange })}
      <Wrapper model={model}>
        {!searching && renderDoc(docComponent, renderAddProps)}
        {renderCollection(renderColProps)}
        {searching && renderDoc(docComponent, renderAddProps)}
      </Wrapper>
    </div>
  )
}

