.primary {
  color: #59d;
}

.success {
  color: #6c6;
}

.info {
  color: #5bd;
}

.warning {
  color: #d93;
}

.danger {
  color: #d55;
  border-color: #d43f3a;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdWkvYm9vdHN0cmFwIiwic291cmNlcyI6WyJEcm9wZG93bk1lbnUuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOzs7QUFDRjtFQUNFOzs7QUFDRjtFQUNFOzs7QUFDRjtFQUNFOzs7QUFDRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIucHJpbWFyeVxuICBjb2xvcjogIzU5ZFxuLnN1Y2Nlc3NcbiAgY29sb3I6ICM2YzZcbi5pbmZvXG4gIGNvbG9yOiAjNWJkXG4ud2FybmluZ1xuICBjb2xvcjogI2Q5M1xuLmRhbmdlclxuICBjb2xvcjogI2Q1NVxuICBib3JkZXItY29sb3I6ICNkNDNmM2FcbiJdfQ== */