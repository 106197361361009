import { useQuery, useMutation } from '@apollo/client'

import { Order } from '../../models/order'

import { OrdersView } from './OrdersView'

export const OrdersContainer = (props) => {
  const { data, loading } = useQuery(Order.fetch)
  const orders = data && data.orders
  Order.useOrderCookUpdate()
  const [ cook ] = useMutation(Order.methods.cook)

  const handleCook = id => {
    return cook({
      variables: { id }
    })
  }

  return (
    <OrdersView
      {...props}
      orders={orders}
      onCook={handleCook}
    />
  )
}
