import { gql } from '@apollo/client'
import { getHooks } from '../hooks'
import { tokenRemove, tokenSet } from '../utils/localToken'

const userFragment = gql`
  fragment UserFragment on User {
    id
    username
    email
    created_at
    updated_at
    roles
  }
`

const userWithAuthTokenFragment = gql`
  fragment UserWithAuthTokenFragment on UserWithAuthToken {
    user {
      ...UserFragment
    }
    authToken
  }
  ${userFragment}
`

export const USER_QUERY = gql`
  query UserQuery {
    currentUser {
      ...UserFragment
    }
  }
  ${userFragment}
`

const REGISTER_MUTATION = gql`
  mutation Register($input: UserRegisterInput!) {
    createUser(input: $input) {
      ...UserWithAuthTokenFragment
    }
  }
  ${userWithAuthTokenFragment}
`

const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: Password!) {
    login(username: $username, password: $password) {
      ...UserWithAuthTokenFragment
    }
  }
  ${userWithAuthTokenFragment}
`

const LOGOFF_MUTATION = gql`
  mutation Logoff {
    logoff
  }
`

const UPDATE_MUTATION = gql`
  mutation UpdateUser($input: ChangeUserInput) {
    changeUser(input: $input) {
      ...UserFragment
    }
  }

  ${userFragment}
`

function changeUserMutationConvert(user) {
  const { username, email, password } = user

  return { username, email, password }
}

export function useChangeUser() {
  const { useMutation } = getHooks()
  const [ mutate ] = useMutation(UPDATE_MUTATION)

  return {
    changeUser: userData =>
      mutate({
        variables: {
          input: changeUserMutationConvert(userData)
        }
      })
  }
}

export function useUser() {
  const { useQuery } = getHooks()
  const result = useQuery(USER_QUERY)
  const { data, loading } = result
  const currentUser = data && data.currentUser

  return { currentUser, loading }
}

export const useRegister = () => {
  const { useMutation } = getHooks()
  const [ register ] = useMutation(REGISTER_MUTATION)

  return async params => {
    const { data } = await register(params)

    if (data) {
      const { createUser: { authToken } } = data
      console.log("REGISTER ACQUIRED TOKEN", authToken)
      tokenSet(authToken)
      location.reload(true)
    }

    return data
  }
}

export const useLogin = () => {
  const { useMutation } = getHooks()
  const [ login ] = useMutation(LOGIN_MUTATION)

  return async params => {
    const { data } = await login(params)

    if (data) {
      const { login: { authToken } } = data
      console.log("LOGIN ACQUIRED TOKEN", authToken)
      tokenSet(authToken)
      location.reload(true)
    }

    return data
  }
}

export const useLogoff = () => {
  const { useMutation, useApolloClient } = getHooks()
  const [ logoff ] = useMutation(LOGOFF_MUTATION)
  const client = useApolloClient()

  return () => {
    logoff()
    client.resetStore()
    tokenRemove()
    location.reload(true)
  }
}
