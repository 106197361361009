import { Link, navigate } from 'raviger'
import { tr } from 'react-hook-form-auto'

import { Button, DropdownMenu } from '../ui/bootstrap/bootstrap'
import styles from './HeaderLogin.sass'
import { useUser, useLogoff, ButtonConfig } from '../pintor'

export const HeaderLogin = () => {
  const { currentUser, loading } = useUser()
  const logoff = useLogoff()

  const handleEdit = () => {
    navigate('/profile')
  }

  const renderContent = () => {
    if (currentUser) {
      const buttons = [
        new ButtonConfig(() => ({
          icon: 'user-edit',
          text: tr('user.profile'),
          onClick: handleEdit
        })),
        new ButtonConfig(() => ({
          icon: 'sign-out-alt',
          text: tr('auth.logoff'),
          onClick: logoff
        }))
      ]

      return (
        <DropdownMenu
          icon="user-circle"
          buttons={buttons}
          direction="left"
          caret
        />
      )
    } else {
      return (
        <Link href="/login">
          <Button type="success" outline>{tr('auth.login')}</Button>
        </Link>
      )
    }
  }

  return (
    <div className={styles.headerMenu}>
      {renderContent()}
    </div>
  )
}
