import { useUser, useChangeUser } from '../../pintor'
import { ProfileView } from './ProfileView'

export function ProfileContainer() {
  const { currentUser, loading } = useUser()
  const { changeUser } = useChangeUser()

  // FIXME __CLIENT__ to force no ProfileView so no Autoform
  // so no strange hook-rules-week-lost
  if (loading || !currentUser || !__CLIENT__) {
    return null
    // return (
    //   <Loading />
    // )
  } else {
    return (
      <ProfileView
        currentUser={currentUser}
        onBasicSubmit={changeUser}
        onChangePasswordSubmit={changeUser}
      />
    )
  }
}
