import { TransitionGroup, CSSTransition } from 'react-transition-group'

import styles from './viewTransitions.sass'

export const ViewTransition = ({
  className,
  viewKey,
  children
}) => {
  return (
    <TransitionGroup key={viewKey} className={className}>
      <CSSTransition
        key={viewKey}
        classNames={styles}
        timeout={{
          enter: 500,
          exit: 300
        }}
      >
        <div>
          <div className={styles.posRel}>
            <div className={styles.posAbs}>
              <div className={styles.posNormal}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}
