import { useQuery } from '@apollo/client'

import { BuyablesList } from './BuyablesList'
import { Buy } from '../../models/buy'

export const BuyableResults = ({
  search,
  ...rest
}) => {
  const { data, loading } = useQuery(Buy.search, {
    variables: { search, seeEveryones: true }
  })
  const collection = data && data.collection

  if (!loading && collection) {
    return (
      <BuyablesList
        search={search}
        collection={collection}
        {...rest}
      />
    )
  } else
    return <p>...</p>
}
