import { cloneElement } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import defStyles from './transitions.sass'

const renderChildren = (styles, children) =>
  children.filter(child => child).map(child => {
    const { transKey, className, ...rest } = child.props
    if (!transKey)
      debugger
    const component = cloneElement(child, rest)

    return (
      <CSSTransition
        key={transKey}
        className={className}
        classNames={styles}
        timeout={{
          enter: 300,
          exit: 150
        }}
      >
        <div>
          {component}
        </div>
      </CSSTransition>
    )
  })

// Children must have transKey={...} with the key
export const ListTransition = ({
  className,
  styles = defStyles,
  children
}) => {
  const classes = classnames(styles.base, className)

  return (
    <TransitionGroup className={classes}>
      {renderChildren(styles, children || [])}
    </TransitionGroup>
  )
}
