.wrapper {
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap;
}
.wrapper .element {
  flex: 1 auto;
  margin: 2px;
  padding: 10px;
  height: 48px;
  min-height: 48px;
  line-height: 28px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 1px 1px 2px #888;
  text-align: center;
}
.wrapper .element .contents {
  display: flex;
  flex-flow: row wrap;
}
.wrapper .element .contents .contentItem {
  flex: 1 auto;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvY29tcG9uZW50cyIsInNvdXJjZXMiOlsiRmxleExpc3Quc2FzcyIsIi4uL3VpL3Nob3Auc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7RUFDQTtFQUNBLFFDVWU7RURUZixZQ1NlO0VEUmY7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7O0FBQ0E7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy4uL3VpL3Nob3Auc2FzcydcblxuLndyYXBwZXJcbiAgbWFyZ2luLWJvdHRvbTogMTZweFxuICBkaXNwbGF5OiBmbGV4XG4gIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgLmVsZW1lbnRcbiAgICBmbGV4OiAxIGF1dG9cbiAgICBtYXJnaW46IDJweFxuICAgIHBhZGRpbmc6IDEwcHhcbiAgICBoZWlnaHQ6ICRjbGlja2FibGUtaGVpZ2h0XG4gICAgbWluLWhlaWdodDogJGNsaWNrYWJsZS1oZWlnaHRcbiAgICBsaW5lLWhlaWdodDogMjhweFxuICAgIGN1cnNvcjogcG9pbnRlclxuICAgIGJvcmRlci1yYWRpdXM6IDRweFxuICAgIGJveC1zaGFkb3c6IDFweCAxcHggMnB4ICM4ODhcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXJcbiAgICAuY29udGVudHNcbiAgICAgIGRpc3BsYXk6IGZsZXhcbiAgICAgIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgICAgIC5jb250ZW50SXRlbVxuICAgICAgICBmbGV4OiAxIGF1dG9cbiIsIiRtZWFsLWNvbG9yOiAjODY2XG4kaW5ncmVkaWVudC1jb2xvcjogIzk5NFxuJHBsdXMtY29sb3I6ICM2ODZcbiRncm91cC1jb2xvcjogIzY4OFxuXG4kaGlnaGxpZ2h0LWJhY2tncm91bmQ6ICM0Y2FjY2NcbiRoaWdobGlnaHQtY29sb3I6ICNmOGZmZmZcblxuJG1lYWwtY29sb3ItZGFyazogZGFya2VuKCRtZWFsLWNvbG9yLCAzMCUpXG4kaW5ncmVkaWVudC1jb2xvci1kYXJrOiBkYXJrZW4oJGluZ3JlZGllbnQtY29sb3IsIDE1JSlcbiRwbHVzLWNvbG9yLWRhcms6IGRhcmtlbigkcGx1cy1jb2xvciwgMTUlKVxuJGdyb3VwLWNvbG9yLWRhcms6IGRhcmtlbigkZ3JvdXAtY29sb3IsIDMwJSlcblxuJG1lYWwtY29sb3ItbGlnaHQ6IGxpZ2h0ZW4oJG1lYWwtY29sb3IsIDMwJSlcbiRpbmdyZWRpZW50LWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRpbmdyZWRpZW50LWNvbG9yLCAzMCUpXG4kcGx1cy1jb2xvci1saWdodDogbGlnaHRlbigkcGx1cy1jb2xvciwgNDAlKVxuJGdyb3VwLWNvbG9yLWxpZ2h0OiBsaWdodGVuKCRncm91cC1jb2xvciwgMzAlKVxuXG4kdGFnLWNvbG9yLW1hdGNoOiAjMTAxMDYwXG5cbiRjbGlja2FibGUtaGVpZ2h0OiA0OHB4XG5cbj1uYXZcbiAgZGlzcGxheTogYmxvY2tcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lXG4iXX0= */