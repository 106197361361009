{
  "add": "Add",
  "addObject": "Add __object__",
  "cancel": "Cancel",
  "warning": "Warning",
  "update": "Update",
  "removeFromList": "Remove from list",
  "not-authorized": "You don't have permission to complete the operation",
  "search": "Search...",
  "genericSuccess": "Updated successfully",
  "link": "Link to public recipe",
  "linkPromise": "Link will be available after you have changes",
  "auth": {
    "login": "Login",
    "logoff": "Logout",
    "register": "Register",
    "password-repeat": "Repeat password"
  },
  "pagination": {
    "title": "Navigation",
    "first": "First",
    "last": "Last",
    "previous": "Previous",
    "next": "Next",
    "showing": "Page __page__ of __total__ (__documents__ documents)",
    "noResults": "No documents were found"
  },
  "threads": {
    "comments": "Comments",
    "new": "Create discussion",
    "created": "Written __date__",
    "updated": "Updated __date__",
    "reply": "Responder",
    "quote": "Citar",
    "noComments": "No comments",
    "registerToComment": "Login or register to comment"
  },
  "business": {
    "pitch": "Practical management of diets and shopping lists",
    "meals": "Create your own meals",
    "meal": "Meals allow a list of ingredients with individual amounts",
    "shop": "Don't throw the money, buy only what you need",
    "title": {
      "meals": "Meal list",
      "meal": "Meal",
      "shop": "Shopping list"
    }
  },
  "models": {
    "show": "Show",
    "edit": "Edit",
    "delete": "Remove",
    "diets": "Diets",
    "organize": "Organize",
    "history": "Shop history",
    "diet": {
      "created": "Success creating diet __name__",
      "modal": "Diet details",
      "name": "Name",
      "memo": "Memo"
    },
    "meals": {
      "_": "Meals",
      "ingredientId": "Ingredient"
    },
    "meal": {
      "name": "Name",
      "memo": "Memo",
      "created": "Success creating meal __name__",
      "updated": "__name__ updated",
      "modal": "Meal details",
      "amount": "Amount",
      "unit": "Unit",
      "ingredientId": "Ingredient",
      "search": "Search meals...",
      "recipe": "Recipe",
      "public": "Public",
      "publicLink": "Link to the shared meal",
      "servings": {
        "_field": "Servings"
      },
      "info": {
        "ingredient": "ingredient",
        "ingredients": "ingredients",
        "serving": "serving",
        "servings": "servings",
        "author": "Author:"
      },
      "ingredientList": "Ingredients",
      "imageIds": {
        "_field": "Fotos"
      }
    },
    "ingredients": "Ingredients",
    "ingredient": {
      "created": "__name__ added",
      "modal": "Ingredient details",
      "name": {
        "_field": "Name"
      },
      "nutriments": "Nutriments",
      "groupId": {
        "_field": "Group",
        "_default": "Select group..."
      },
      "imageIds": "Images"
    },
    "ingredient_groups": {
      "ungrouped": "Ungrouped"
    },
    "nutriment": {
      "_field": "Nutriments",
      "type": {
        "_field": "Type",
        "_default": "Select type...",
        "sugars": "Sugars",
        "salt": "Salt",
        "proteins": "Proteins",
        "saturatedFat": "Saturated fats",
        "sodium": "Sodium",
        "energy": "Energy",
        "fat": "Fats",
        "carbohidrates": "Carbohidrates"
      },
      "unit": {
        "_field": "Unidad",
        "_default": "Select unit...",
        "kcal": "kcal",
        "kJ": "kJ",
        "kg": "kg",
        "g": "g",
        "ml": "ml",
        "l": "l",
        "percentVol": "% vol."
      },
      "amount": {
        "_field": "Cantidad"
      },
      "groups": {
        "ungrouped": "Ungrouped"
      }
    },
    "ingredientEntry": {
      "amount": {
        "_field": "Amount"
      },
      "unit": {
        "_field": "Unit",
        "_default": "Select unit...",
        "g": "g",
        "kg": "kg",
        "l": "litter",
        "u": "unit"
      },
      "ref": {
        "_field": "Ingredient"
      }
    },
    "shop": "Shop",
    "origins": "Origins",
    "groups": "Groups",
    "buy": {
      "ingredientName": "Ingredient's name",
      "ingredientSearch": "Search ingredient...",
      "group": "Group",
      "groupId": "Group",
      "ungrouped": "Ungrouped",
      "rename": "Rename",
      "renamed": "Ingredient's name changed from __from__ to __to __",
      "bought": "Bought __ingredient__",
      "origins": "Origins",
      "amounts": "Amounts"
    },
    "buyAmount": {
      "amount": {
        "_field": "Amount"
      },
      "unit": {
        "_field": "Unit",
        "g": "g",
        "kg": "kg",
        "l": "litter",
        "u": "unit"
      }
    },
    "ingredientGroups": {
      "created": "Success creating group __name__",
      "updated": "__name__ updated",
      "modal": "Ingredient group details",
      "name": "Name",
      "ungrouped": "Ungrouped"
    },
    "user": {
      "username": "User's name",
      "email": "email",
      "password": "Password",
      "isAdmin": "Administrator",
      "imageIds": "Profile image"
    },
    "bought": "Bought",
    "errors": {
      "empty": "This field can't be empty"
    },
    "login": {
      "username": "Username",
      "password": "Password"
    },
    "register": {
      "username": "Username",
      "password": "Password",
      "password_repeat": "Password repeat",
      "email": "email (optional)"
    }
  },
  "modal": {
    "close": "Close",
    "save": "Save changes"
  },
  "shop": {
    "admin": "Admin",
    "diets": "Diets",
    "meals": "Meals",
    "ingredients": "Ingredients",
    "shop": "Shop",
    "ingredientGroups": "Groups",
    "day": "Day __number__",
    "drag": "Drag",
    "list": "List",
    "calendar": "Calendar",
    "buy": "Buy",
    "removeFromDiet": "Remove",
    "loginGreet": "Hello!",
    "addToShop": "Add to shopping list",
    "addMeal": "Add meal",
    "recipes": {
      "edit": "Edit recipe",
      "editTitle": "Editing recipe for __name__"
    },
    "toggleDetails": "Show/hide details",
    "cooked": "Cooked",
    "files": {
      "removed": "Atachment deleted",
      "edit": "Edit",
      "remove": "Remove",
      "warnDelete": "If you close this dialog images will be lost"
    },
    "justBought": "Recently picked up"
  },
  "admin": {
    "userMadeAdmin": "Just when I thought I was out, they pull me back in!"
  },
  "nutrition": {
    "carbohydrates": "Carbohydrates",
    "energy": "Energy",
    "fat": "Fat",
    "fiber": "Fiber",
    "proteins": "Proteins",
    "salt": "Salt",
    "saturated-fat": "Saturated fat",
    "sodium": "Sodium",
    "sugars": "Sugars",
    "warns": {
      "ingredients": "Nutriments information can't be provided because there's information missing in the ingredients __ingredients__."
    }
  },
  "user": {
    "login": "Login",
    "register": "Register",
    "name": "Name",
    "password": "Password",
    "passwordRepeat": "Repeat password",
    "loginError": "Error while login. Please, review name and password.",
    "loginSuccess": "Success login!",
    "logout": "Log out",
    "welcome": "Hello, __name__",
    "profile": "Profile",
    "basicConfig": "Basic information"
  },
  "crud": {
    "info": "Information",
    "small": "Small",
    "normal": "Normal",
    "list": "List",
    "seeEveryones": "See everyone's"
  },
  "mutations": {
    "mealUpdate": {
      "add": "__name__ added successfully",
      "update": "__name__ updated successfully"
    },
    "ingredientUpdate": {
      "add": "__name__ added successfully",
      "update": "__name__ updated successfully"
    },
    "buyAdd": {
      "add": "Added to shopping list"
    },
    "buyUpdate": {
      "update": "Shopping list updated"
    },
    "dietUpdate": {
      "add": "Diet __name__ added successfully",
      "update": "Diet __name__ updated successfully"
    }
  },
  "error": {
    "login": "You must be logged in to do this operation",
    "unauthorized": "You are not authorized to do this operation",
    "changeSystemIngredient": "Base ingredients can't be edited"
  }
}
